<template>
  <v-row>
    <v-col cols="5">
      <v-card elevation="12" width="100%">
        <v-navigation-drawer
            floating
            permanent
            width="100%"
        >
          <v-list>
            <v-list-item>
              <v-file-input
                  class="width_full mt-5"
                  accept=".ged"
                  :label="$t('gedcom.import')"
                  filled
                  v-model="file"
                  prepend-icon="publish"
              ></v-file-input>
            </v-list-item>
            <v-list-item>
              <v-btn color="primary" width="100%"
                     @click="startImport">
                {{ $t('gedcom.startImport') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </v-col>
    <v-col cols="7">
      <template v-if="importInProgress">
        <v-card elevation="12">
          <v-card-text>
            <h2 class="text-center">{{ $t('gedcom.doNotClose') }}</h2>
            <div class="loading-state-wrapper">
              <v-progress-circular :size="60"
                                   color="primary"
                                   indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template v-else-if="importDone">
        <v-card elevation="12">
          <v-card-text>
            <h2 class="text-center mb-4">{{ $t('gedcom.checkTheDataToImport') }}</h2>
            <div>
              <v-expansion-panels
                  v-model="panel"
                  multiple
              >
                <v-expansion-panel
                    v-for="(item,i) in items"
                    :key="i"
                    :disabled="i !== 0"
                >
                  <v-expansion-panel-header>
                   <span>
                      <v-badge
                          inline
                          color="green"
                          :content="getBadge(item.header)"
                      >
                      {{ $t(`${ item.header }.label`) }}
                    </v-badge>
                   </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template v-if="item.header === 'facts'">
                    </template>
                    <template v-if="item.header === 'stories'">
                    </template>
                    <template v-if="item.header === 'people'">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('person.fullName') }}
                            </th>
                            <th class="text-left">
                              {{ $t('person.birthDate') }}
                            </th>
                            <th class="text-left">
                              {{ $t('person.deathDate') }}
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                              v-for="item in peopleToImport"
                              :key="item.id"
                          >
                            <td>{{ item.fullName }}</td>
                            <td>{{ item.birthDate?.date || '-' }}</td>
                            <td>{{ item.deathDate?.date || '-' }}</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-spacer></v-spacer>
          </v-card-text>
          <v-card-actions>
            <v-dialog
                v-model="dialog"
                persistent
                width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary"
                       v-bind="attrs"
                       v-on="on">
                  {{ $t('forms.confirm') }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="red  darkn-2 text--white">
                  {{ $t('gedcom.chooseYourself') }}
                </v-card-title>

                <v-card-text class="pa-4">
                  <v-autocomplete v-model="you"
                                  :disabled="!peopleToImport.length"
                                  :items="peopleToImport"
                                  :label="$t('gedcom.chooseYourself')"
                                  item-text="fullName"
                                  item-value="id"
                  ></v-autocomplete>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <tree-form-dialog :trees="trees" @save="saveTree">
                    <template v-slot:toggle-button="{on, attrs}">
                      <v-btn
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          :disabled="!you"
                          @click="selectYourself"
                      >
                        {{ $t('forms.continue') }}
                      </v-btn>
                    </template>
                  </tree-form-dialog>
                  <v-btn text @click="dialog=false">
                    {{ $t('forms.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import PersonModel from '@/models/person.model';
  import TreeFormDialog from '@/modules/tree/tree-form-dialog.vue';
  import TreeService from '@/services/tree.service';

  const parse = require('./gedcom-import.js');

  export default {
    name: 'gedcomImport',
    components: { TreeFormDialog },
    data() {
      return {
        panel: [],
        trees: [],
        dialog: false,
        facts: [],
        file: null,
        importInProgress: false,
        treeService: null,
        peopleToImport: [],
        stories: [],
        you: null,
        importDone: false,
      };
    },
    computed: {
      items() {
        return [
          {
            header: 'people',
          },
          {
            header: 'stories',
          },
          {
            header: 'facts',
          },
        ];
      },
    },
    async mounted() {
      // this.panel = [ ...Array(this.items)
      //     .keys() ].map((k, i) => i);
      const userId = this.$store.getters['userId'];
      this.treeService = new TreeService(userId);
      this.trees = await this.treeService.getTrees();
    },
    methods: {
      async startImport() {
        this.importInProgress = true;
        try {
          const data = await parse(this.file);
          this.peopleToImport = data.map(_ => new PersonModel(_.personModel));
          this.stories = data.reduce((acc, _) => {
            acc = [ ...acc, ..._.stories ];
            return acc;
          }, []);
          this.facts = data.reduce((acc, _) => {
            acc = [ ...acc, ..._.facts ];
            return acc;
          }, []);
        } catch ( e ) {
          console.log(e);
        } finally {
          this.importInProgress = false;
          this.importDone = true;
        }
      },
      getBadge(header) {
        switch ( header ) {
          case 'people':
            return this.peopleToImport.length;
          case 'stories':
            return this.stories.length;
          case 'facts':
            return this.facts.length;
        }
      },
      selectYourself() {
        this.peopleToImport.find(_ => _.id === this.you).isFirstFocusOnInit = true;
      },
      async saveTree(tree) {
        this.importInProgress = true;
        try {
          await this.treeService.updateFullfilledTree({
            treeId: tree.id,
            people: this.peopleToImport,
            stories: this.stories,
            facts: this.facts,
          });
        } catch ( e ) {
          console.log(e);
        } finally {
          await this.$router.push({
            name: 'tree',
            params: { id: tree.id },
          });
        }
      },
    },
  };
</script>
