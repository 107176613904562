<template>
  <v-stepper v-model="firstPersonCreationStep">
    <v-stepper-header>
      <v-stepper-step
          :complete="firstPersonCreationStep > 1"
          step="1"
      >
        {{ $t('tree.firstPersonCreationForm.step1') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          :complete="firstPersonCreationStep > 2"
          step="2"
      >
        {{ $t('tree.firstPersonCreationForm.step2') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        {{ $t('tree.firstPersonCreationForm.step3') }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <person-form v-model="you" :is-first-person-creation="true">
          <template v-slot:title>
            <span></span>
          </template>
          <template v-slot:actions>
            <v-btn
                :disabled="$v.you.$invalid"
                color="primary"
                @click="goToNextStep"
            >
              {{ $t('tree.firstPersonCreationForm.continue') }}
            </v-btn>

            <v-btn text @click="cancel">
              {{ $t('forms.cancel') }}
            </v-btn>
          </template>
        </person-form>
      </v-stepper-content>

      <v-stepper-content step="2">
        <person-form v-model="mother"
                     :connection-type="ConnectionTypes.mother"
                     :disabled-connections="true"
                     :relative-person="you.id">
          <template v-slot:title>
            <span></span>
          </template>
          <template v-slot:actions>
            <v-btn
                :disabled="$v.mother.$invalid"
                color="primary"
                @click="goToNextStep"
            >
              {{ $t('tree.firstPersonCreationForm.continue') }}
            </v-btn>

            <v-btn text @click="cancel">
              {{ $t('forms.cancel') }}
            </v-btn>
          </template>
        </person-form>
      </v-stepper-content>

      <v-stepper-content step="3">
        <person-form v-model="father"
                     :connection-type="ConnectionTypes.father"
                     :disabled-connections="true"
                     :relative-person="you.id">
          <template v-slot:title>
            <span></span>
          </template>
          <template v-slot:actions>
            <v-btn
                :disabled="$v.father.$invalid"
                color="primary"
                @click="save"
            >
              {{ $t('forms.save') }}
            </v-btn>

            <v-btn text @click="cancel">
              {{ $t('forms.cancel') }}
            </v-btn>
          </template>
        </person-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
  import { ConnectionTypes, Sex } from '@/models/helpers';
  import PersonModel from '@/models/person.model';
  import PersonForm from '@/modules/person/person-form.vue';
  import toast from '@/services/helpers/toast';
  import PersonService from '@/services/person.service';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name: 'first-person-creation-form',
    components: { PersonForm },

    data() {
      return {
        personService: null,
        you: new PersonModel(),
        mother: new PersonModel({ gender: Sex.female }),
        father: new PersonModel({ gender: Sex.male }),
        firstPersonCreationStep: 1,
        ConnectionTypes,
      };
    },
    mounted() {
      const treeId = this.$route.params.treeId;
      this.personService = new PersonService({ treeId });
    },
    validations: {
      you: {
        firstName: { required },
        lastName: { required },
        birthCountry: { required },
        birthDate: { required },
        birthPlace: { required },
        gender: { required },
      },
      mother: {},
      father: {},
    },

    methods: {
      goToNextStep() {
        this.firstPersonCreationStep++;
      },
      async save() {
        this.isLoading = true;
        try {
          this.you.isFirstFocusOnInit = true;
          this.you.motherId = this.mother.id;
          this.you.fatherId = this.father.id;
          this.you.email = this.$store.getters['user'].email;
          this.mother.partnerIds.push(this.father.id);
          this.father.partnerIds.push(this.mother.id);
          this.mother.childrenIds.push(this.you.id);
          this.father.childrenIds.push(this.you.id);
          await Promise.all([
            this.personService.createPerson(this.you.getPayload()),
            this.personService.createPerson(this.mother.getPayload()),
            this.personService.createPerson(this.father.getPayload()),
          ]);
          this.$emit('success');
        } catch ( error ) {
                      toast('error', `alerts.errors.${error.code}`);

          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      cancel() {
        this.$emit('cancel');
      },
    },
  };
</script>
