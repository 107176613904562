<template>
  <v-container>
    <h1 class="mb-2">{{ $t('contacts.title') }}</h1>
    <p><b>ИП</b> Бакланова Дарья Алексеевна</p>
    <p><b>ИНН</b> 701751901240 </p>
    <p><v-icon color="black">phone</v-icon> <a href="https://wa.me/message/QGVNUK5PZYXTK1">+7 (995) 965 08 01</a></p>
    <p><v-icon color="black">mail</v-icon> <a href="mailto:support@7gens.me">support@7gens.me</a></p>

 todo:
    ● Полное описание потребительских характеристик продаваемых Товаров;
    ● Цена Товара;
    ● Способ оплаты Товара;
    ● Порядок проведения оплаты по банковским картам;
    ● Порядок возврата Товара;
    ● Условия доставки;
    ● О действиях Держателя при возникновении проблем с оплатой;
    ● О порядке обработки персональных данных при оплате заказа на страницах Ресурса.
    ● Информация о мерах по обеспечению безопасности Операций с использованием Карт.
    ● Контактная информация службы поддержки клиентов, включая адрес электронной почты и номер
    телефона;
    ● Изображения с логотипами ПС, карты которых принимаются Клиентом, и иных способов
    оплаты (только для Интернет-магазинов);
    ● Изображение с логотипом Банка и URL ссылкой на ресурсы Банка: https://modulbank.ru/
    6. Перечень Товаров, представленный Клиентом Банку, должен соответствовать перечню Товаров
  </v-container>
</template>
<script>
  export default {
    name: 'contacts-page',
  };
</script>
