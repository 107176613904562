import { getCurrentUser } from '@/firebase';
import GuestLayout from '@/layouts/guest.vue';
import NotFound from '@/layouts/not-found.vue';
import Explore from '@/modules/explore/explore.vue';
import Marathon from '@/modules/explore/marathon.vue';
import Metrics from '@/modules/explore/metric-books.vue';
import OpenDatabases from '@/modules/explore/open-databases.vue';
import SearchPage from '@/modules/explore/search.vue';
import Login from '@/modules/login/login.vue';
import RestorePassword from '@/modules/login/restore-password.vue';
import PersonDetails from '@/modules/person/person-details.vue';
import PersonFormDialog from '@/modules/person/person-form-dialog.vue';
import PhotoList from '@/modules/photos/photo-list.vue';
import PhotoPage from '@/modules/photos/photo-page.vue';
import Plans from '@/modules/profile/plans.vue';
import KeepDataPage from '@/modules/profile/keeping_pd.vue';
import Profile from '@/modules/profile/profile.vue';
import PrivacyPolicyPage from '@/modules/profile/policy-page.vue';
import ContactsPage from '@/modules/profile/contacts.vue';
import TermsPage from '@/modules/profile/terms.vue';
import Links from '@/modules/explore/links.vue';
import StoryForm from '@/modules/stories/story-form.vue';
import StoryList from '@/modules/stories/story-list.vue';
import StoryPage from '@/modules/stories/story-page.vue';
import Tree from '@/modules/tree/tree.vue';
import GedcomImportForm from '@/modules/gedcom/gedcom.vue';
import toast from '@/services/helpers/toast';
import Vue from 'vue';
import VueRouter from 'vue-router';

const MainLayout = () => import('@/layouts/main.vue');

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '/',
                redirect: { name: 'tree' },
                meta: {
                    requiresAuth: true,
                },
            }, {
                path: 'explore',
                name: 'explore',
                component: Explore,
                redirect: { name: 'search' },
                children: [ {
                    path: 'search',
                    name: 'search',
                    component: SearchPage,
                }, {
                    path: 'metrics',
                    name: 'metrics',
                    component: Metrics,
                }, {
                    path: 'links',
                    name: 'links',
                    component: Links,
                }, {
                    path: 'marathon',
                    name: 'marathon',
                    component: Marathon,
                }, {
                    path: 'dbs',
                    name: 'dbs',
                    component: OpenDatabases,
                }],
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: PrivacyPolicyPage
            },
            {
                path: 'terms',
                name: 'terms',
                component: TermsPage
            },
            {
                path: 'contacts',
                name: 'contacts',
                component: ContactsPage
            },
            {
                path: 'plans',
                name: 'plans',
                component: Plans
            },
            {
                path: 'payment-terms',
                name: 'payment-terms',
                component: KeepDataPage,
            },
            {
                name: 'gedcom',
                path: 'gedcom',
                component: GedcomImportForm,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/login',
        component: GuestLayout,
        children: [ {
            path: '/',
            name: 'login',
            component: Login,
        }, {
            path: '/restore',
            name: 'restore-password',
            component: RestorePassword,
        } ],
    },
    {
        path: '/:globalUserId',
        component: MainLayout,
        children: [ {
            path: 'profile',
            name: 'profile',
            component: Profile,
            meta: {
                requiresAuth: true,
            },
        }, {
            path: 'tree/:treeId?',
            name: 'tree',
            component: Tree,
            meta: {
                requiresAuth: true,
                requiresUserId: true,
            },
            children: [ {
                path: 'person/:personId',
                name: 'person-details',
                component: PersonDetails,
                meta: {
                    requiresAuth: true,
                },
            }, {
                path: 'person/:personId/edit',
                name: 'person-edit',
                component: PersonFormDialog,
                meta: {
                    requiresAuth: true,
                    // onlyOwner: true, // todo set who is authorized to edit
                },
            } ],
        }, {
            name: 'photo',
            path: 'photo',
            component: PhotoList,
            meta: {
                requiresAuth: true,
            },
        }, {
            name: 'photo-page',
            path: 'photo/:photoId',
            component: PhotoPage,
            meta: {
                requiresAuth: true,
            },
        }, {
            name: 'stories',
            path: 'stories',
            component: StoryList,
            meta: {
                requiresAuth: true,
            },
        }, {
            name: 'story-page',
            path: ':treeId/stories/:storyId',
            component: StoryPage,
            meta: {
                requiresAuth: true,
            },
        }, {
            name: 'story-new',
            path: ':treeId/stories/new/create',
            component: StoryForm,
            meta: {
                requiresAuth: true,
            },
        }, {
            name: 'story-form',
            path: ':treeId/stories/:storyId/edit',
            component: StoryForm,
            meta: {
                requiresAuth: true,
            },
        } ],
    },
    {
        path: '*',
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});


router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresUserId = to.matched.some(record => record.meta.requiresUserId);

    try {
        const currentUser = await getCurrentUser();
        if ( to.name === 'login' && !!currentUser ) {
            next({ name: 'tree' });
            return;
        }

        if ( requiresAuth && !currentUser ) {
            next({ name: 'login' });
        } else if ( currentUser && (requiresAuth || requiresUserId) && !to.params.globalUserId || to.params.globalUserId === 'tree' ) {
            next({
                ...to,
                params: {
                    ...to.params,
                    globalUserId: currentUser.uid,
                },
            });
        }
        next();
    } catch ( e ) {
        toast('error', `alerts.errors.${ e.code }`);


    }
});

export default router;
