import { FirebaseAuth } from '@/firebase';
import PhotoModel from '@/models/photo.model';
import EntityService from '@/services/entity.service';
import toast from '@/services/helpers/toast';
import PersonService from '@/services/person.service';
import StoryService from '@/services/story.service';

export default class PhotoService extends EntityService {
    _entity = 'photos';
    _entityModel = PhotoModel;

    constructor() {
        const userId = FirebaseAuth.currentUser && FirebaseAuth.currentUser.uid;
        super(userId);
    }

    async getPhotos() {
        try {
            const photos = await this.getAll();
            return photos;
        } catch ( e ) {
            toast('error', `alerts.errors.${e.code}`);
            console.log(e);
        }
    }

    async getPhoto(id) {
        const photo = await this.getDoc(id);
        return photo;
    }

    async setPhoto(data) {
        try {
            const initialPhoto = await this.getPhoto(data.id);
            await this.setDoc(data);
            const photo = await this.getPhoto(data.id);
            await this.synchronizePhotoBoxes(initialPhoto, photo);
            toast('success', initialPhoto ? 'alerts.photo.updated' : 'alerts.photo.created');
            return photo;
        } catch ( e ) {
            toast('error', `alerts.errors.${e.code}`);
            console.log(e);
        }
    }

    async removePhoto(id) {
        try {
            const initialPhoto = await this.getPhoto(id);
            const photo = await this.removeDoc(id);
            await this.synchronizePhotoBoxes(initialPhoto);
            toast('success', 'alerts.photo.removed');
            return photo;
        } catch ( e ) {
                        toast('error', `alerts.errors.${e.code}`);

            console.log(e);
        }
    }

    async synchronizeWithStories(initialPhoto, photo) {
        //if story was added
        if ( (!initialPhoto.story || !initialPhoto.story.storyId) && photo.story && photo.story.storyId ) {
            const storyService = new StoryService({ treeId: photo.story.treeId });
            const story = await storyService.getStory(photo.story.storyId);
            await storyService.updateStory(story.id, { photoIds: [ ...story.photoIds, photo.id ] });
        }

        if ( initialPhoto.story && initialPhoto.story.storyId ) {
            // if story was removed from photo
            if ( !photo.story || !photo.story.storyId ) {
                const storyService = new StoryService({ treeId: photo.story.treeId });
                const story = await storyService.getStory(photo.story.storyId);
                await storyService.updateStory(story.id, { photoIds: story.photoIds.filter((id) => id !== photo.id) });
            }

            //if story was changed
            if ( initialPhoto.story.storyId !== photo.story.storyId ) {
                const storyService = new StoryService({ treeId: photo.story.treeId });
                const story = await storyService.getStory(photo.story.storyId);
                await storyService.updateStory(story.id, { photoIds: [ ...story.photoIds, photo.id ] });

                const initialStoryService = new StoryService({ treeId: initialPhoto.story.treeId });
                const initialStory = await initialStoryService.getStory(initialPhoto.story.storyId);
                await initialStoryService.updateStory(initialStory.id, { photoIds: initialStory.photoIds.filter((id) => id !== initialPhoto.id) });
            }
        }
    }

    async synchronizePhotoBoxes(initialPhoto, photo) {
        if ( (photo && photo.boxes.length) || (initialPhoto && initialPhoto.boxes.length) ) {
            const personsToUnmark = initialPhoto ? initialPhoto.boxes
                .filter(({
                    personId,
                    treeId,
                }) => {
                    return !photo || !photo.boxes.find(({
                        personId: newPersonId,
                        treeId: newTreeId,
                    }) => {
                        return newPersonId === personId && newTreeId === treeId;
                    });
                }) : [];

            for ( const box of personsToUnmark ) {
                const {
                    treeId,
                    personId,
                } = box;
                const personService = new PersonService({ treeId });
                const person = await personService.getPerson(personId);
                if ( !person.photoIds.length || (photo && !person.photoIds.includes(photo.id)) ) {
                    return;
                }
                await personService.updatePerson(person.id, { photoIds: person.photoIds.filter((id) => (photo && id !== photo.id) || (initialPhoto && id !== initialPhoto.id)) }, false);
            }

            if ( photo ) {
                for ( const box of photo.boxes ) {
                    const {
                        treeId,
                        personId,
                    } = box;
                    const personService = new PersonService({ treeId });
                    const person = await personService.getPerson(personId);

                    if ( person.photoIds && person.photoIds.includes(photo.id) ) {
                        return;
                    }

                    await personService.updatePerson(person.id, { photoIds: [ ...person.photoIds, photo.id ] }, false   );
                }
            }
        }
    }
}
