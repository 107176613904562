<template>
  <div class="text-justify">
    <p>
      <!--      {{ $t('marathon.description') }}-->
      Привет! Меня зовут <b>Даша</b>. Вообще, я - фронтенд-разработчик, но больше 3 лет я занимаюсь генеалогией. На
      стыке этих
      своих увлечений родился <b>7gens</b>.
    </p>
    <p>
      Я создала этот <b>марафон</b>, чтобы помочь вам начать исследование своего рода.
      В течение 2 месяцев, а может быть и больше, я буду снимать и выкладывать видео-ролики, которые помогут вам собрать
      информацию о своей семье. В конце марафона вы получите готовое дерево, которое можно будет поделиться с
      родственниками.
      А платформа 7gens поможет вам сохранить историю вашей семьи.
    </p>

    <p>Исследование родословной - небыстрая задача, а для кого-то вообще может стать делом на всю жизнь. Поэтому ролики
      разнесены между собой, чтобы успевать применять ту
      информацию на практике.</p>
    <p>Марафон останется в открытом доступе, поэтому вы всегда можете вернуться к нему и посмотреть нужный ролик.</p>

    <!--    <h5>Оглавление</h5>-->
    <!--    <v-list-item v-for="item in videos" :key="'list-' + item.title">-->
    <!--      <v-list-item-content>-->
    <!--        <v-list-item-title>-->
    <!--          <a :href="`#${ item.title }`">{{ $t('marathon.' + item.title) }}</a>-->
    <!--        </v-list-item-title>-->
    <!--      </v-list-item-content>-->
    <!--    </v-list-item>-->

    <!--    <v-divider class="mt-14 mb-14"></v-divider>-->

    <!--  1 Introduction-->

    <v-timeline>
      <v-timeline-item v-for="item in videos" :key="item.title" :name="item.title"><h3>{{
          $t('marathon.' + item.title)
        }}</h3>
        <iframe width="560" height="315" :src="item.url" :title="item.title"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
        <v-divider class="mt-14 mb-14"></v-divider>
      </v-timeline-item>
    </v-timeline>

    <div class="text-center">{{ $t('marathon.toBeContinued') }}</div>
  </div>
</template>
<script>
  export default {
    name: 'marathon-list',
    data() {
      return {
        videos: [ {
          title: 'introduction',
          url: 'https://www.youtube.com/embed/jn9L6UBfrV8',
        }, {
          title: 'open-dbs',
          url: 'https://www.youtube.com/embed/kCe7xvWktdY',
        }, {
          title: 'jewish',
          url: 'https://www.youtube.com/embed/zmHGSl1JZZs',
        }, {
          title: 'metrics',
          url: 'https://www.youtube.com/embed/bYghd_y1GUM',
        } ],
      };
    },
  };
</script>
