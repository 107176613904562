<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('terms') }}
      </v-card-title>
      <v-card-text v-html="terms.ru">
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'terms-page',
    data() {
      return {
        terms: require('./terms.json'),
      };
    },
  };
</script>
