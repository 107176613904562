<template>
  <v-autocomplete
      :value="value"
      :disabled="!people.length"
      :items="people"
      chips
      :label="label"
      item-text="fullName"
      item-value="id"
      @input="$emit('input', $event)"
      :multiple="multiple"
  >
    <template v-slot:selection="data">
      <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="removePerson(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar"></v-img>
        </v-avatar>
        {{ data.item.fullName }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.avatar">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import PersonService from '@/services/person.service';

  export default {
    name: 'persons-select',
    props: {
      label: {
        type: String,
        default: '',
      },
      treeId: {
        type: String,
        default: null,
      },
      value: {
        type: [Array, String],
        default: () => [],
      },
      multiple: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        people: [],
      };
    },
    created() {
      this.fetchPeople();
    },
    watch: {
      treeId() {
        this.fetchPeople();
      },
    },
    methods: {
      async fetchPeople() {
        const treeId = this.treeId || this.$route.params.treeId;
        const userId = this.$route.params.globalUserId;

        this.personService = new PersonService({
          userId,
          treeId,
        });
        this.people = await this.personService.getPeople();
      },
      removePerson(item) {
        const index = this.value.indexOf(item.id);
        if ( index >= 0 ) {
          const input = [ ...this.value ];
          input.splice(index, 1);
          this.$emit('input', input);
        }
      },
    },
  };
</script>
