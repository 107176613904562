<template>
  <div class="not-found">
    <v-row>
      <v-col cols="6" class="no-trees-wrapper">
        <h1>404</h1>
        <p>
          NOT FOUND
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'not-found',
  };
</script>
