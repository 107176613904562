<template>
  <v-dialog
      v-if="fact"
      :value="dialog"
      persistent
      @input="$emit('close')"
      max-width="800"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ $t(`facts.${ value ? 'edit' : 'add' }`) }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
                :label="$t('facts.type')"
                v-model="fact.type"
                :items="factTypeList"></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
                :label="$t('facts.title')"
                v-model="fact.title"
                counter
                maxlength="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
                :label="$t('facts.description')"
                counter
                maxlength="450"
                v-model="fact.description"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <!--todo: add auto complete-->
            <v-text-field
                :label="$t('facts.place')"
                counter
                maxlength="150"
                v-model="fact.place"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-select
                v-model="fact.country"
                :items="countriesList"
                :label="$t('facts.country')"
            ></v-select>
          </v-col>
          <v-col
              cols="12"
          >
            <date-picker
                :label="$t('facts.date')"
                v-model="fact.period"></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="fact.memberIds"
                :disabled="!people.length"
                :items="people"
                chips
                :label="$t('facts.members')"
                item-text="fullName"
                item-value="id"
                multiple
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeMember(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.avatar"></v-img>
                  </v-avatar>
                  {{ data.item.fullName }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <img :src="data.item.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="$emit('close')"
        >
          {{ $t('forms.cancel') }}
        </v-btn>

        <v-btn
            color="primary"
            @click="save"
        >
          {{ $t('forms.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import FactModel from '@/models/fact.model';
  import { countries, FactTypeOptions } from '@/models/helpers';
  import DatePicker from '@/modules/person/components/date-picker';
  import FactService from '@/services/fact.service';
  import PersonService from '@/services/person.service';

  export default {
    name: 'fact-form-dialog',
    components: {
      DatePicker,
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      value: {
        type: Object,
      },
      person: {
        type: Object,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      factTypeList() {
        return FactTypeOptions
            .map((value) => ({
              text: this.$t(`facts.types.${ value }`),
              value,
            }));
      },
      countriesList() {
        return Object.entries(countries)
            .map(([ key, value ]) => ({
              text: value,
              value: key,
            }));
      },
    },
    data() {
      return {
        fact: null,
        countries,
        people: [],
        personService: null,
        factService: null,
      };
    },
    mounted() {
      this.init();
    },
    methods: {
      async init() {
        this.fact = new FactModel(this.value || {
          memberIds: [ this.person.id ],
        });

        const treeId = this.$route.params.treeId;
        const userId = this.$route.params.globalUserId;

        this.factService = new FactService({
          userId,
          treeId,
        });
        this.personService = new PersonService({
          userId,
          treeId,
        });
        this.people = await this.personService.getPeople();
      },
      removeMember(item) {
        const index = this.fact.memberIds.indexOf(item.id);
        if ( index >= 0 ) this.fact.memberIds.splice(index, 1);
      },
      async save() {
        if ( this.editMode ) {
          await this.factService.updateFact(this.fact.getPayload());
          this.$emit('fact-updated');
        } else {
          await this.factService.createFact(this.fact.getPayload());
          this.$emit('fact-created');
        }
      },
    },
  };
</script>
