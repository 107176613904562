<template>
  <v-app-bar id="navigation-bar"
             class="main-layout_menu justify-md-space-between" app
             color="#ff9200" dark>
    <div class="d-flex align-center mr-4">
      <router-link :to="{name: 'login'}">
        <div class="logo-7gens"></div>
      </router-link>
    </div>
    <v-spacer></v-spacer>
    <template v-if="isMobile">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
              v-bind="attrs"
              v-on="on">
            <v-icon>menu</v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item v-if="isLoggedIn">
            <v-list-item-title>
              <tree-list></tree-list>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
              v-for="(item, index) in menuItems"
              :key="index"
              :disabled="item.soon || (item.isAuth && !isLoggedIn)"
          >
            <v-list-item-title>
              <router-link :to="{name: item.to}">
                {{ $t(`menu.${ item.label }`) }}
                <v-icon v-if="item.soon">schedule</v-icon>
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLoggedIn">
            <v-list-item-title>
              <router-link :to="{name: 'profile'}">
                {{ $t('profile') }}
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLoggedIn">
            <v-list-item-title @click="logout">
              {{ $t('logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <!--      <v-list-item>-->
      <!--        <v-list-item-title>-->
      <tree-list :disabled="!isLoggedIn"></tree-list>
      <!--        </v-list-item-title>-->
      <!--      </v-list-item>-->
      <router-link
          v-for="(item, index) in menuItems"
          :class="{'mr-7': index < menuItems.length - 1}"
          :key="index"
          :to="{name: item.to}"
          :disabled="item.soon">
        <v-btn text :disabled="item.soon || (item.isAuth && !isLoggedIn)" :tooltip="$t('soon')">
          {{ $t(`menu.${ item.label }`) }}
          <v-icon v-if="item.soon">schedule</v-icon>
        </v-btn>
      </router-link>
      <template v-if="isLoggedIn">
        <v-menu bottom>
          <template v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs">
              <v-avatar v-if="user.photoURL">
                <img :src="user.photoURL" :alt="user.fullName"
                     v-bind="attrs"
                     v-on="on">
              </v-avatar>
              <v-btn icon v-else
                     v-bind="attrs"
                     v-on="on">
                <v-icon>account_circle</v-icon>
              </v-btn>
            </slot>
          </template>
          <v-card width="205">
            <v-list>
              <template>
                <v-list-item>
                  <v-list-item-title>
                    <router-link :to="{name: 'profile'}">{{ $t('profile') }}</router-link>
                  </v-list-item-title>
                </v-list-item>
              </template>

              <v-divider></v-divider>

              <v-card-actions class="mt-1 justify-center">
                <v-btn text v-if="isLoggedIn" block @click="logout">
                  {{ $t('logout') }}
                </v-btn>
              </v-card-actions>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </template>
  </v-app-bar>
</template>

<script>
  import TreeList from '@/modules/tree/tree-list';
  import { mapGetters } from 'vuex';

  export default {
    name: 'main-menu',
    components: { TreeList },
    data() {
      return {
        title: '7gens',
        menuItems: [ {
          label: 'photos',
          to: 'photo',
          isAuth: true,
        }, {
          label: 'stories',
          to: 'stories',
          isAuth: true,
        }, {
          label: 'explore',
          to: 'explore',
          isAuth: true,
        }, {
          label: 'books',
          soon: true,
        } ],
      };
    },
    computed: {
      ...mapGetters({
        user: 'user',
        isLoggedIn: 'isLoggedIn',
      }),
      isMobile() {
        return window.innerWidth < 800;
      },
    },
    methods: {
      logout() {
        this.$authService.logout();
      },
    },
  };
</script>

<style>
.main-layout_menu,
.main-layout_menu .v-toolbar__content {
  height: 70px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  /*padding: 0 40px;*/
  box-shadow: unset !important;
}


.main-layout_menu_name {
  font-size: 36px;
  font-weight: 600;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

.logo-7gens {
  width: 200px;
  background-size: contain;
  height: 70px;
  background-color: transparent;
  background-position-y: center;
  background-image: url('../assets/images/logo.png');
}

</style>
