<template>
  <v-app data-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;800&display=swap');

* {
  --primary-color: #ff9200;
  --female-color: var(--primary-color);
  --male-color: #00baf0;
  --light-color: #94928e;

  /* ckeditor config variables */
  --ck-border-radius: 10px;
  --ck-color-focus-border: var(--primary-color) !important;
  --ck-color-base-foreground: var(--primary-color) !important;
  --ck-color-toolbar-background: var(--primary-color) !important;
  --ck-color-toolbar-border: transparent !important;
  --ck-color-text: #FFFFFF !important;
  --ck-color-button-on-color: black !important;
  --ck-color-button-on-hover-color: black !important;
  --ck-color-button-action-text: black !important;
  --ck-color-button-focus-text: black !important;
  --ck-color-panel-border: transparent !important;
  --ck-balloon-border-width: 0px;

  font-family: 'Montserrat', sans-serif;
}

#app .text-h1,
#app .text-h2,
#app .text-h3,
#app .text-h4,
#app .text-h5,
#app .text-h6,
#app p {
  font-family: 'Montserrat', sans-serif !important;
}

#app .primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

#app .primary--text {
  color: var(--primary-color) !important;
  caret-color: var(--primary-color) !important;
}

#app .text--white {
  color: white !important;
}

</style>
