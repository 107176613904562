import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
// locales
import ru from '/src/assets/locales/ru';
import en from '/src/assets/locales/en';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.amber.darken1, // #E53935
                secondary: colors.amber.lighten4, // #FFCDD2
                accent: colors.amber.base, // #3F51B5
            },
        },
    },
    icons: {
        iconfont: 'md',
    },
    lang: {
        locales: { ru, en },
        current: 'ru',
    },
});
