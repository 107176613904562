<template>
  <v-card v-if="treeValue">
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <template v-if="isLoading">
        <div class="loading-state-wrapper">
          <v-progress-circular :size="60"
                               color="amber"
                               indeterminate
          ></v-progress-circular>
        </div>
      </template>
      <template v-else>
        <v-container>
          <v-row>
            <v-col cols="12">
<!--              todo find solution to change treeId-->
              <v-text-field
                  :label="$t('forms.treeId')"
                  :hint="`https://7gens.ru/${$route.params.globalUserId}/tree/${treeValue.id || 'example'}`"
                  v-model="treeValue.id"
                  :error-messages="errorMessages.id"
                  :disabled="editMode"
                  @change="$v.treeValue.id.$touch()"
                  counter
                  maxlength="20"
                  persistent-hint
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  :label="$t('forms.name')"
                  v-model="treeValue.name"
                  counter
                  maxlength="50"
                  :error-messages="errorMessages.name"
                  @change="$v.treeValue.name.$touch()"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  :label="$t('forms.description')"
                  counter
                  maxlength="450"
                  v-model="treeValue.description"
              ></v-text-field>
            </v-col>
            <!-- todo add public option-->
            <!--          <v-col cols="12">-->
            <!--            <v-checkbox-->
            <!--                color="black"-->
            <!--                :input-value="true"-->
            <!--                v-model="treeValue.isPublic"-->
            <!--                :label="$t('forms.isPublic')"-->
            <!--            >-->
            <!--            </v-checkbox>-->
            <!--          </v-col>-->
          </v-row>
        </v-container>
      </template>
      <!--      <small>*indicates required field</small>-->
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <!--      <v-btn-->
      <!--          color="dark"-->
      <!--          text-->
      <!--          @click="$emit('cancel')"-->
      <!--      >-->
      <!--        {{ $t('forms.cancel') }}-->
      <!--      </v-btn>-->
      <v-btn
          :disabled="$v.$invalid"
          color="primary"
          @click="$emit('save', treeValue)"
      >
        {{ $t('forms.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import TreeModel from '@/models/tree.model';
  import { required, helpers } from 'vuelidate/lib/validators';

  const isId = helpers.regex('isId', /^[a-zA-Z0-9_-]+$/i);
  const isUnique = (vm) => {
    return value => {
      if ( value === '' ) return true;
      if ( vm ) return vm.findIndex(({ id }) => id === value) === -1;
      return false;
    };
  };

  export default {
    name: 'tree-form',
    props: {
      title: {
        type: String,
        default: '',
      },
      tree: {
        type: Object,
        default: () => new TreeModel(),
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      treeList: {
        type: Array,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        treeValue: null,
      };
    },
    validations() {
      return {
        treeValue: {
          id: {
            required,
            isId,
            isUnique: isUnique(this.treeList),
          },
          name: {
            required,
          },
        },
      };
    },
    computed: {
      errorMessages() {
        const allErrors = {};
        if ( this.$v.treeValue.id.$dirty && this.$v.treeValue.id ) {
          let errors = [];
          if ( !this.$v.treeValue.id.isUnique ) {
            errors.push('This ID is already taken');
          }
          if ( !this.$v.treeValue.id.isId ) {
            errors.push('Invalid ID');
          }
          if ( !this.$v.treeValue.id.required ) {
            errors.push('This field is required');
          }
          allErrors.id = errors;
        }
        if ( this.$v.treeValue.name.$dirty && !this.$v.treeValue.name.required ) {
          allErrors.name = [ 'This field is required' ];
        }

        return allErrors;
      },
    },
    mounted() {
      this.treeValue = new TreeModel(this.tree);
    },
  };
</script>
