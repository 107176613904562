import { FirebaseDB } from '@/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Subscriptions = 'subscriptions';

export default class SubscriptionsService {
    constructor() {
    }

    async setDoc(data) {
        const docRef = doc(FirebaseDB, Subscriptions, data.id);
        const res = await setDoc(docRef, data);
        return res;
    }

    async getDoc(id) {
        const response = await getDoc(doc(FirebaseDB, Subscriptions, id));
        if ( response.exists() ) {
            return response.data();
        }
    }
}
