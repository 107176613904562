<template>
  <v-dialog
      :value="dialog"
      persistent
      width="600px"
      min-width="600px"
  >
    <v-card>
      <v-card-title>
        <v-btn class="close-button" text icon @click="$emit('close')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <plans-page>
          <template v-slot:actions>
            <div class="text-center">
              <v-dialog
                  v-model="paymentConfirmationDialog"
                  persistent
                  width="600px"
                  min-width="600px"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-btn small text v-on="on" v-bind="attrs" class="mt-3 mb-3"
                         @click="makeSubscription('месяц')">Подписка на месяц
                  </v-btn>

                  <v-btn color="primary" v-on="on" v-bind="attrs" class="mt-3"
                         @click="makeSubscription('год')">Подписка на год
                  </v-btn>
                </template>
                <v-card class="pa-2">
                  <v-card-title>
                    <v-btn class="close-button" text icon @click="paymentConfirmationDialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                    Подписка на {{ newSubscription?.subscriptionType }}
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <tbody>
                      <tr>
                        <td class="text-left">Регулярность списаний</td>
                        <td class="text-left">каждый {{ newSubscription?.subscriptionType }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">Сумма списания</td>
                        <td class="text-left">{{ newSubscription?.price }}.00 рублей</td>
                      </tr>
                      </tbody>
                      <div>
                        <v-checkbox
                            class="pl-3"
                            v-model="paymentTermsChecked"
                            :input-value="true"
                            color="primary"
                        >
                          <template v-slot:label>
                            <span><small>Я согласен(-на) <a class="text-decoration-underline" :href="'/payment-terms'"
                                                       target="_blank"> с условиями оферты рекуррентных платежей</a></small></span>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmSubscription" :disabled="!paymentTermsChecked">
                      Перейти к оплате
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </plans-page>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import pay from '@/modules/profile/payment.js';
  import PlansPage from '@/modules/profile/plans.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'plans-dialog-stopper',
    components: { PlansPage },
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters([ 'user', 'info' ]),
    },
    data() {
      return {
        newSubscription: null,
        paymentTermsChecked: false,
        paymentConfirmationDialog: false,
      };
    },
    methods: {
      makeSubscription(subscriptionType) {
        const subscriptions = (this.info && this.info.subscriptions || []);

        this.newSubscription = {
          id: `${ this.user.uid }-${ subscriptions.length + 1 }`,
          userId: this.user.uid,
          subscriptionType,
          price: subscriptionType === 'год' ? 1799 : 299,
          dateIssue: Math.floor(Date.now() / 1000),
        };
      },
      confirmSubscription() {
        pay(this.newSubscription, this.user);
      },
    },
  };
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
