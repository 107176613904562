import { FirebaseAuth } from '@/firebase';
import axios from 'axios';
import { signInWithEmailAndPassword } from 'firebase/auth';

const API_DOMAIN = 'https://api.7gens.me'
// const API_DOMAIN = 'http://127.0.0.1:5001/gens-7-project/us-central1/api';

class ApiService {
    constructor() {
        this.api = axios.create({
            baseURL: API_DOMAIN,
            // Другие настройки Axios, если необходимо
        });
    }

    async search({
        fullName,
        firstName,
        lastName,
        middleName,
    }) {
        const { data } = await this.api.get(`/search?query=${ fullName }&lastName=${ lastName }&firstName=${ firstName }&middleName=${ middleName }`);
        return data;
    }

    async signUp({
        uid,
        email,
        password,
        firstName,
        lastName,
        privacyPolicyChecked,
    }) {
        await this.api.post(`/sign-up`, {
            uid,
            email,
            password,
            firstName,
            lastName,
            customFields: {
                privacyPolicyChecked,
            },
        });

        await signInWithEmailAndPassword(FirebaseAuth, email, password);
    }

    async removeTree(treeId) {
        const userId = FirebaseAuth.currentUser.uid;
        await this.api.delete('/delete-tree', {
            params: {
                id: treeId,
                userId,
            },
        });
    }
}

export default new ApiService();
