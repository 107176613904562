<template>
  <v-dialog
      v-model="dialog"
      width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <template v-if="link">
        <a href="#" v-bind="attrs" v-on="on" class="text-decoration-underline">
          <slot name="labelText">
            <small>{{ $t('terms') }}</small>
          </slot>
        </a>
      </template>
      <template v-else>
        <v-btn text color="white" v-bind="attrs" v-on="on">
          <slot name="labelText">
            <small>{{ $t('terms') }}</small>
          </slot>
        </v-btn>
      </template>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t('terms') }}</span>
      </v-card-title>
      <v-card-text v-html="terms['ru']"></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  const terms = require('./terms.json');
  export default {
    name: 'terms-dialog',
    props: {
      link: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        terms,
        dialog: false,
      };
    },
  };
</script>
