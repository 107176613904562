import { FirebaseStorage } from '@/firebase';
import toast from '@/services/helpers/toast';
import PersonService from '@/services/person.service';
import PhotoService from '@/services/photo.service';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export default class PhotoStorageService {
    _downloadUrl = null;

    constructor() {
        this.PhotoService = new PhotoService();
    }

    async addImageToFirebaseStorage({
        id,
        name,
        date,
        description,
        boxes,
        story,
        file,
    }, isAvatar, personId, treeId) {
        const imageId = self.crypto.randomUUID();

        const storageRef = ref(FirebaseStorage, 'images/' + imageId);
        try {
            const result = await uploadBytesResumable(storageRef, file, { ...file });
            await getDownloadURL(result.ref)
                .then(async (downloadUrl) => {
                    await this.PhotoService.setPhoto({
                        id: id || self.crypto.randomUUID(),
                        name: name || '',
                        date: date || '',
                        description: description || '',
                        downloadUrl,
                        boxes: boxes || [],
                        story,
                    });

                    if ( isAvatar ) {
                        try {
                            const personService = new PersonService({ treeId });
                            await personService.updatePerson(personId, { avatar: downloadUrl });
                        } catch ( error ) {
                            toast('error', `alerts.errors.${ error.code }`);
                        }
                    }
                });

        } catch ( error ) {
            toast('error', `alerts.errors.${ error.code }`);
        }
    }

    // async getImageFromFirestore(data) {
    // }
}
