import Vue from 'vue';

const toast = (type, message, configs) => {
    const params = [];
    if ( configs && configs.name ) {
        params.push(configs.name);
    }
    Vue.prototype.$toast(Vue.prototype.$t(message, params), { type, timeout: 3000 });
};

export default toast;
