<template>
  <v-container>
    <h1>{{ $t('plans.title') }}</h1>
    <v-simple-table class="plans-table">
      <template v-slot:default>
        <thead>
        <tr>
          <th></th>
          <th v-for="plan of planList" :key="'header-' + plan">
            {{ $t(`plans.${ plan }`) }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="feature of Features"
            :key="feature"
        >
          <td>{{ $t('plans.' + feature) }}</td>
          <td v-for="plan of planList" :key="feature + '-' + plan">{{
              (plans[plan] && plans[plan][feature]) ? `${ plans[plan][feature] } ${ featureDescription[feature] }` : '∞'
            }}
          </td>
        </tr>
        <tr>
          <td><b>{{ $t('plans.pricePerMonth') }}</b></td>
          <td v-for="plan of planList" :key="'price-month-' + plan">{{ plans[plan].pricePerMonth || '0' }}₽</td>
        </tr>
        <tr>
          <td><b>{{ $t('plans.pricePerYear') }}</b></td>
          <td>0₽</td>
          <td class="plan-per-year">
            <b class="mr-1">{{ plans.paid.pricePerYear }}₽ </b>
            <span style="text-decoration: line-through;" class="mr-1"> 3599₽</span>
            <v-chip color="red" small class="text--white mr-1"><small>-50%</small></v-chip>
          </td>
        </tr>
        <tr class="plan-actions">
          <td></td>
          <td></td>
          <td>
            <slot name="actions">
              <router-link :to="{name: 'profile'}">
                <v-btn color="primary">Подключить</v-btn>
              </router-link>
            </slot>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="mt-2 mb-4 align-center">
      <v-col cols="2">
        <img width="100%" src="@/assets/images/pay-tinkoff.svg" alt="tinkoff"/>
      </v-col>
      <v-col class="payment-cards d-flex align-center">
        <small class="mr-1">Мы принимаем оплаты через Тинькофф Банк (<a class="text-decoration-underline"
                                                                     href="https://tinkoff.ru/">tinkoff.ru</a>) картами:</small>
        <img class="mr-1" src="@/assets/images/pay-visa.svg" alt="visa"/>
        <img class="mr-1" src="@/assets/images/pay-mc.svg" alt="mastercard"/>
        <img class="mr-1" src="@/assets/images/pay-mir.png" alt="mir"/>
      </v-col>
    </v-row>

    <p class="">
      <small class="text--secondary">
        <router-link :to="{name: 'payment-terms'}">Условия оферты реккурентных платежей</router-link>
      </small>
    </p>
  </v-container>
</template>

<script>
  import Plans from '@/modules/profile/plans';
  import { mapGetters } from 'vuex';

  export default {
    name: 'plans-page',
    data() {
      return {
        Plans,
        Features: [ 'treesQuantity', 'peopleQuantity', 'photoQuantity' ],
        featureDescription: {
          treesQuantity: 'дер.',
          peopleQuantity: 'перс.',
          photoQuantity: 'шт.',
        },
        planList: [ Plans.free, Plans.paid ],
        plans: {
          free: {
            treesQuantity: 1,
            peopleQuantity: 200,
            photoQuantity: 200,
          },
          paid: {
            photoQuantity: 1000,
            pricePerMonth: 299,
            pricePerYear: 1799,
          },
        },
      };
    },
    computed: {
      ...mapGetters([ 'user', 'info' ]),
    },
  };
</script>

<style>
.plans-table tr {
  height: 70px;
}

.plans-table .plan-per-year {
  display: flex;
  height: 70px !important;
  align-items: center;
}

.payment-cards img {
  max-width: 50px;
}

.plan-actions {
  height: 120px !important;
}
</style>
