<template>
  <v-menu
      v-model="showCalendar"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="value"
          :label="label"
          readonly
          @input="onDateInput"
          prepend-icon="calendar_month"
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        :label="label"
        :value="value"
        color="lighten-1"
        :max="maxDateValue"
        min="01-01-1500"
        :range="isRange"
        @input="onDateInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'date-component',
    data() {
      return {
        showCalendar: false,
      };
    },
    props: {
      value: {
        type: [ String, Date, Object ],
      },
      label: {
        type: String,
      },
      isRange: {
        type: Boolean,
      },
      maxDateValue: {
        type: [ String, Date, Object ],
        default: moment()
            .format('YYYY-MM-DD'),
      },
    },
    methods: {
      onDateInput(value) {
        this.$emit('input', value);
      },
    },
  };
</script>

<style>
.v-picker__title {
  background-color: var(--primary-color);
}
</style>
