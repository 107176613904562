<template>
  <v-card width="100%" class="pa-7 mx-auto mt-6" elevation="10">
    <template v-if="signUpInProgress || loginInProgress">
      <div class="loading-state-wrapper">
        <v-progress-circular :size="60"
                             color="primary"
                             indeterminate
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <v-tabs v-model="tab" color="primary" fixed-tabs>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="tab">{{ $t(tab) }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tab in tabs" :key="`${tab}-content`">
          <v-card>
            <v-container>
              <v-card-text>
                <v-alert dense outlined type="error" v-if="error">{{ $t(`alerts.errors.${ error }`) }}</v-alert>
                <form>
                  <template v-if="tab === 'signUp'">
                    <v-text-field
                        v-model="signUpForm.uid"
                        :error-messages="uidErrors"
                        :label="$t('uid')"
                        required
                        @input="$v.signUpForm.uid.$touch()"
                        @blur="$v.signUpForm.uid.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="signUpForm.firstName"
                        :error-messages="firstNameErrors"
                        :label="$t('firstName')"
                        required
                        @input="$v.signUpForm.firstName.$touch()"
                        @blur="$v.signUpForm.firstName.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="signUpForm.lastName"
                        :error-messages="lastNameErrors"
                        :label="$t('lastName')"
                        required
                        @input="$v.signUpForm.lastName.$touch()"
                        @blur="$v.signUpForm.lastName.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="signUpForm.email"
                        :error-messages="emailErrors"
                        :label="$t('email')"
                        required
                        @input="$v.signUpForm.email.$touch()"
                        @blur="$v.signUpForm.email.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="signUpForm.password"
                        :error-messages="passwordErrors"
                        :label="$t('password')"
                        type="password"
                        required
                        @input="$v.signUpForm.password.$touch()"
                        @blur="$v.signUpForm.password.$touch()"
                    ></v-text-field>
                    <v-checkbox
                        v-if="tab === 'signUp'"
                        v-model="signUpForm.privacyPolicyChecked"
                        :error-messages="privacyPolicyCheckedErrors"
                        @change="$v.signUpForm.privacyPolicyChecked.$touch()"
                        :input-value="true"
                        color="primary"
                    >
                      <template v-slot:label>
                      <span><small>{{ $t('privacyPolicyCheck') }}</small> <policy-text
                          :link="true"></policy-text> и <terms-dialog :link="true"></terms-dialog></span>
                      </template>
                    </v-checkbox>
                  </template>
                  <template v-else>
                    <!--                                      <a href="http://127.0.0.1:5001/gens-7-project/us-central1/api/auth/google">-->
                    <a href="https://api.7gens.me/auth/google">
                      <v-btn class="google-btn mb-4" depressed block color="white">
                        <img class="google-icon"
                             icon-left
                             src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                        <span>{{ $t('signInWithGoogle') }}</span>
                      </v-btn>
                    </a>
                    <v-text-field
                        v-model="loginForm.email"
                        :label="$t('email')"
                        required
                        @input="$v.loginForm.email.$touch()"
                        @blur="$v.loginForm.email.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="loginForm.password"
                        :label="$t('password')"
                        type="password"
                        required
                        @input="$v.loginForm.password.$touch()"
                        @blur="$v.loginForm.password.$touch()"
                    ></v-text-field>
                  </template>
                </form>
              </v-card-text>
              <v-card-actions class="home_form_actions">
                <v-btn block color="primary" class="" @click="toLogin" v-if="tab==='login'">{{ $t(tab) }}</v-btn>
                <v-btn block color="primary" class="" @click="toLogin" v-else :disabled="$v.signUpForm.$invalid">{{
                    $t(tab)
                  }}
                </v-btn>
                <div class="text-center mt-2" v-if="tab === 'login'">
                  <router-link class="text-uppercase" :to="{name: 'restore-password'}">
                    <small>{{ $t('restorePassword') }}</small>
                  </router-link>
                </div>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>

<script>
  import { FirebaseAuth } from '@/firebase';
  import PolicyText from '@/modules/profile/policy.vue';
  import TermsDialog from '@/modules/profile/terms-dialog.vue';
  import apiService from '@/services/api.service';
  import { minLength, required, email, helpers, sameAs } from 'vuelidate/lib/validators';
  import { mapActions, mapGetters } from 'vuex';
  import { signInWithEmailAndPassword, signInWithCustomToken } from 'firebase/auth';

  const isId = helpers.regex('isId', /^[a-zA-Z0-9_-]+$/i);

  export default {
    name: 'login-page',
    components: {
      TermsDialog,
      PolicyText,
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        isLoading: 'isLoading',
      }),
      firstNameErrors() {
        const errors = [];
        if ( !this.$v.signUpForm.firstName.$dirty ) {
          return errors;
        }
        !this.$v.signUpForm.firstName.required && errors.push(this.$t('alerts.errors.firstName.required'));
        return errors;
      },
      lastNameErrors() {
        const errors = [];
        if ( !this.$v.signUpForm.lastName.$dirty ) {
          return errors;
        }
        !this.$v.signUpForm.lastName.required && errors.push(this.$t('alerts.errors.lastName.required'));
        return errors;
      },
      tabName() {
        return this.tabs[this.tab];
      },
      passwordErrors() {
        const errors = [];
        if ( !this.$v[`${ this.tabName }Form`] || !this.$v[`${ this.tabName }Form`].password.$dirty ) {
          return errors;
        }
        !this.$v[`${ this.tabName }Form`].password.minLength && errors.push(this.$t('alerts.errors.passwordMinLength'));
        !this.$v[`${ this.tabName }Form`].password.required && errors.push(this.$t('alerts.errors.passwordRequired'));
        return errors;
      },
      privacyPolicyCheckedErrors() {
        const errors = [];
        if ( !this.$v.signUpForm.privacyPolicyChecked.$dirty ) {
          return errors;
        }
        !this.$v.signUpForm.privacyPolicyChecked.checked && errors.push(this.$t('alerts.errors.privacyPolicyIsNotChecked'));
        return errors;
      },
      emailErrors() {
        const errors = [];

        if ( !this.$v[`${ this.tabName }Form`] || !this.$v[`${ this.tabName }Form`].email.$dirty ) {
          return errors;
        }
        !this.$v[`${ this.tabName }Form`].email.email && errors.push(this.$t('alerts.errors.emailWrongFormat'));
        !this.$v[`${ this.tabName }Form`].email.required && errors.push(this.$t('alerts.errors.emailRequired'));
        return errors;
      },
      uidErrors() {
        const errors = [];
        if ( !this.$v.signUpForm.uid.$dirty ) {
          return errors;
        }
        !this.$v.signUpForm.uid.required && errors.push(this.$t('alerts.errors.uidRequired'));
        !this.$v.signUpForm.uid.isId && errors.push(this.$t('alerts.errors.uidWrongFormat'));
        return errors;
      },
    },
    validations: {
      signUpForm: {
        uid: {
          required,
          isId,
        },
        firstName: { required },
        lastName: { required },
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(6),
        },
        privacyPolicyChecked: {
          checked: sameAs(() => true),
        },
      },
      loginForm: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    },
    data() {
      return {
        loginInProgress: false,
        signUpInProgress: false,
        signUpForm: {
          uid: '',
          privacyPolicyChecked: false,
          firstName: '',
          lastName: '',
          email: '',
          password: '',
        },
        loginForm: {
          email: '',
          password: '',
        },
        error: null,
        tab: 0,
        tabs: [ 'signUp', 'login' ],
      };
    },
    watch: {
      isLoggedIn(val) {
        if ( val ) {
          this.$router.push({ name: 'tree' });
        }
      },
    },
    methods: {
      ...mapActions({
        setUser: 'setUser',
      }),
      toLogin() {
        this[this.tabs[this.tab]]();
      },
      login() {
        this.$v.loginForm.$touch();
        signInWithEmailAndPassword(FirebaseAuth, this.loginForm.email, this.loginForm.password)
            .then(() => {
              this.$router.push({ name: 'tree' });
            })
            .catch(err => {
              this.error = err.code;
            });
      },
      async signUp() {
        try {
          this.signUpInProgress = true;
          await apiService.signUp({
            uid: this.signUpForm.uid,
            firstName: this.signUpForm.firstName,
            lastName: this.signUpForm.lastName,
            email: this.signUpForm.email,
            password: this.signUpForm.password,
            privacyPolicyChecked: this.signUpForm.privacyPolicyChecked,
          });
          await this.$router.push({ name: 'tree' });
        } catch ( error ) {
          if ( error && error.response && error.response.data ) {
            const { code } = error?.response?.data;
            this.error = code;
          } else {
            console.log(error);
          }
        } finally {
          this.signUpInProgress = false;
        }
      },
    },
    mounted() {
      if ( this.$route.query ) {
        if ( this.$route.query.error && this.$route.query.error === 'unauthorized' ) {
          this.error = 'unauthorized';
        }
        if ( this.$route.query.token ) {
          this.loginInProgress = true;
          signInWithCustomToken(FirebaseAuth, this.$route.query.token)
              .then((result) => {
                this.setUser(result.user);
                this.$router.push({ name: 'tree' });
              })
              .catch(err => {
                this.error = err.code;
              })
              .finally(() => {
                this.loginInProgress = false;
              });
        }
      }
    },
  };
</script>

<style>
* {
  --white: #ffffff;
  --google-blue: #4285f4;
  --button-active-blue: #1669F2;
}

.google-btn {
  display: flex;
  width: 184px;
  height: 42px;
  color: black !important;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
}

.google-icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 768px) {
  .v-parallax__content {
    padding-top: 100px;
    display: block;
    overflow-y: scroll;
    padding-bottom: 40px;
  }
}
</style>
