<template>
  <v-container>
    <h1>{{ $t('profile') }}</h1>

    <h3 class="mt-5 mb-2">{{ $t('personalInformation') }}</h3>
    <p>{{ user.displayName }}</p>
    <p>{{ user.email }}</p>

    <!--    <h3 class="mt-5 mb-2">{{ $t('uiLanguage') }}</h3>-->
    <!--    <v-btn-toggle class=" mb-2"-->
    <!--                  :value="uiLanguagePreference"-->
    <!--                  @change="onLanguageChange"-->
    <!--                  shaped-->
    <!--                  mandatory-->
    <!--    >-->
    <!--      <v-btn v-for="lang of languages" :key="lang.value" :value="lang.value">-->
    <!--        {{ lang.key }}-->
    <!--      </v-btn>-->
    <!--    </v-btn-toggle>-->

    <v-divider></v-divider>

    <h3 class="mt-5 mb-5">{{ $t('menu.myTrees') }}
      <tree-form-dialog @save="fetchData" :icon-button="true"></tree-form-dialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <router-link :to="{name:'gedcom'}">
            <v-btn icon v-bind="attrs"
                   :disabled="freePlan && trees.length >= 1"
                   v-on="on">
              <v-icon>publish</v-icon>
            </v-btn>
          </router-link>
        </template>
        <span>{{ $t('menu.importTree') }}</span>
      </v-tooltip>
    </h3>

    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">{{ $t('forms.id') }}</th>
          <th class="text-left">{{ $t('forms.name') }}</th>
          <th class="text-left">{{ $t('forms.description') }}</th>
          <th class="text-left">{{ $t('forms.actions') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="tree in trees" :key="tree.id">
          <td>{{ tree.id }}</td>
          <td>{{ tree.name }}</td>
          <td>{{ tree.description }}</td>
          <td>
            <router-link :to="{name: 'tree', params: {treeId: tree.id}}">
              <v-btn text icon>
                <v-icon>account_tree</v-icon>
              </v-btn>
            </router-link>
            <tree-form-dialog :tree="tree" :icon-button="true" icon="edit" :edit-mode="true"
                              @save="fetchData"></tree-form-dialog>
            <remove-confirmation-dialog entity="tree" :name="tree.name" :important="true"
                                        :is-loading="deleteIsInProgress"
                                        @confirmed="deleteTree(tree.id)"></remove-confirmation-dialog>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card class="mt-5 mb-5">
      <v-card-title>{{ $t('access.myPlan') }}</v-card-title>
      <v-card-text>
        <v-chip class="text--white" :color="freePlan ? 'red' : 'green'">
          {{ $t(`access.${ myPlan }`) }}
        </v-chip>
        <plans-dialog v-if="freePlan"></plans-dialog>
        <!-- todo сделать "продлится" и "завершится" -->
        <small class="ml-3" v-if="!freePlan">Подписка продлится {{ subscriptionEnds }}</small>
        <v-alert v-if="limitIsOff" class="mt-3 mb-3" color="red" type="warning">Лимит бесплатной версии исчерпан. Чтобы
          снова добавлять людей в свое древо и сохранять фотографии, продлите подписку.
        </v-alert>

        <p class="mb-1 mt-5">{{ $t('access.trees.quantity') }} <small class="text-description">{{
            treesTotal
          }}/{{ freePlan ? 1 : '∞' }}</small></p>
        <v-progress-linear
            :value="treesPercentage"
            color="primary"
            height="25"
            :striped="!freePlan"
        ></v-progress-linear>

        <p class="mb-1 mt-5">{{ $t('access.people.quantity') }} <small class="text-description">{{
            peopleTotal
          }}/{{ freePlan ? 200 : '∞' }}</small></p>
        <v-progress-linear
            :value="peoplePercentage"
            color="primary"
            height="25"
            :striped="!freePlan"
        ></v-progress-linear>

        <p class="mb-1 mt-5">{{ $t('access.photo.quantity') }} <small class="text-description">{{
            photosTotal
          }}/{{ freePlan ? 200 : '∞' }}</small></p>
        <v-progress-linear
            :value="photosPercentage"
            color="primary"
            height="25"
            :striped="!freePlan"
        ></v-progress-linear>

        <v-dialog
            v-if="!freePlan"
            v-model="dialog"
            width="500"
        >
          <template v-slot:activator="{ on, attrs }">
                <v-btn small class="mt-6" text v-on="on" v-bind="attrs"><small>Отключить продление</small></v-btn>
          </template>

          <v-card>
            <v-card-title class="grey lighten-2">
              <small>Подтвердить отключение продления подписки</small>
            </v-card-title>

            <v-card-text>
              <v-textarea
                  class="mt-4"
                  outlined
                  name="reason"
                  label="Почему вы хотите отключить продление подписки?"
                  v-model="reasonToCancel"
              ></v-textarea>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!reasonToCancel"
                  color="primary"
                  text
                  @click="cancelSubscription"
              >
                Подтвердить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  import Languages from '@/assets/languages';
  import Plans from '@/modules/profile/plans';
  import PlansDialog from '@/modules/profile/plans-dialog.vue';
  import PhotoService from '@/services/photo.service';
  import TreeService from '@/services/tree.service';
  import TreeFormDialog from '@/modules/tree/tree-form-dialog';
  import UserService from '@/services/user.service';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import RemoveConfirmationDialog from '@/components/remove-confirmation-dialog';

  export default {
    name: 'ProfilePage',
    components: {
      TreeFormDialog,
      RemoveConfirmationDialog,
      PlansDialog,
    },
    data() {
      return {
        Plans,
        trees: [],
        dialog: false,
        reasonToCancel: '',
        userInfo: null,
        treeService: new TreeService(),
        photoService: new PhotoService(),
        userService: new UserService(),
        deleteIsInProgress: false,
        languages: [ {
          key: 'Русский',
          value: Languages.Russian,
        }, {
          key: 'English',
          value: Languages.English,
        } ],
      };
    },
    computed: {
      ...mapGetters([ 'user', 'uiLanguagePreference', 'info', 'treesTotal', 'photosTotal', 'peopleTotal', 'freePlan', 'limitIsOff' ]),
      myPlan() {
        return this.freePlan ? Plans.free : Plans.paid;
      },
      treesPercentage() {
        return this.freePlan ? this.treesTotal * 100 / 1 : 100;
      },
      peoplePercentage() {
        return this.freePlan ? this.peopleTotal * 100 / 200 : 100;
      },
      photosPercentage() {
        return this.freePlan ? this.photosTotal * 100 / 200 : 100;
      },
      subscriptionEnds() {
        return this.info && moment(this.info.subscriptionEnd)
            .format('ll');
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        this.trees = await this.treeService.getTrees();
      },
      async deleteTree(id) {
        this.deleteIsInProgress = true;
        try {
          await this.treeService.deleteTree(id);
          await this.fetchData();
        } catch ( e ) {
          console.error(e);
        } finally {
          this.deleteIsInProgress = false;
        }
      },
      onLanguageChange(value) {
        this.$store.dispatch('setUiLanguagePreference', value);
      },
      async cancelSubscription() {
        await this.userService.setUserInfo({
          subscriptionCancelledReason: this.reasonToCancel,
        });
      },
    },
  }; </script>
<style>
</style>
