<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      @change="closeModal"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on" name="toggle-button">
        <v-btn :icon="iconButton"
               v-bind="attrs"
               :disabled="disabled"
               variant="block"
               v-on="on">
          <v-icon v-if="iconButton">{{ icon }}</v-icon>
          <span v-else>
            {{ editMode ? $t('editTree') : $t(`createTree`) }}
          </span>
        </v-btn>
      </slot>
    </template>
    <tree-form v-if="dialog"
               :title="editMode ? $t('editTree') : $t(`createTree`)"
               :tree="tree"
               :is-loading="isLoading"
               :tree-list="treeList"
               @cancel="closeModal"
               @save="createTree"></tree-form>
  </v-dialog>
</template>

<script>
  import TreeModel from '@/models/tree.model';
  import toast from '@/services/helpers/toast';
  import TreeService from '@/services/tree.service';
  import TreeForm from '@/modules/tree/tree-form';

  export default {
    name: 'create-tree-dialog',
    components: {
      TreeForm,
    },
    props: {
      tree: {
        type: Object,
        default: () => {
          return new TreeModel();
        },
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      iconButton: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: 'add_circle',
      },
      treeList: {
        type: Array,
      },
    },
    data() {
      return {
        isLoading: false,
        dialog: false,
        service: new TreeService(),
      };
    },
    methods: {
      async createTree(tree) {
        try {
          this.isLoading = true;
          await this.service.createTree(tree.getPayload(), this.editMode);
        } catch ( e ) {
          toast('error', `alerts.errors.${ e.code }`);

          console.log(e);
        } finally {
          this.$emit('save', tree.getPayload());
          this.closeModal();
          // await this.$router.push({
          //   name: 'tree',
          //   params: { treeId: tree.id },
          // });
          this.isLoading = false;
        }
      },
      closeModal() {
        if ( !this.editMode ) {
          this.$forceUpdate();
        }
        this.toggleDialog(false);
        this.$emit('close');
      },
      toggleDialog(mode) {
        this.dialog = mode;

      },
    },
  };
</script>
