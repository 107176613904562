<template>
  <div>
    <!--    <p>{{ $t('explore.metricBooks.description') }}</p>-->
    <v-expansion-panels v-if="metricBooks" class="mt-2" v-model="panel" multiple>
      <v-expansion-panel v-for="metrics in metricBooks" :key="metrics.region.en">
        <v-expansion-panel-header>{{ metrics.region.ru }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="metrics.description">
            {{ metrics.description.ru }}
          </p>
          <div v-if="metrics.links" class="metrics-links">
            <div v-for="({title, links}, index) in metrics.links.ru" :key="`link-${metrics.region.en}-${index}`">
              <div>
                <h5 v-if="title">{{ title }}</h5>
                <div v-for="(linkItem, linkIndex) in links" :key="`link-${metrics.region.en}-${index}-${linkIndex}`">
                  <a v-if="linkItem.link" :href="linkItem.link">{{ linkItem.name || linkItem.link }}</a>
                  <span v-else v-html="linkItem.name"> </span>
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="mt-8">
      <p><small>Вы можете добавить информацию в этот раздел. Напишите мне на электронную почту <a href="mailto:dasha@7gens.ru">dasha@7gens.ru</a>.</small></p>
      <p><small>Большая часть информации взята с сайта <a href="http://metrics.tilda.ws/">metrics.tilda.ws</a></small></p>
    </div>
  </div>
</template>

<script>
  const { data } = require('@/assets/metrics-ru.json');

  export default {
    name: 'metricBooks',
    data() {
      return {
        panel: [],
        metricBooks: data,
      };
    },
    mounted() {
    },
  };
</script>

<style>
.v-expansion-panel-header {
  font-weight: bold;
}

.metrics-links a {
  color: var(--male-color) !important;
  text-decoration: 1px dashed underline !important;
  text-underline-offset: 3px;
}
</style>
