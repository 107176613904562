<template>
  <div>
    <router-view></router-view>
    <div v-if="isLoading" class="margin-top-xl text-align-center">
      <v-progress-circular :size="40"
                           color="#ff9200"
                           indeterminate
      ></v-progress-circular>
    </div>

    <template v-if="isOwner">
      <!-- if no trees -->
      <v-row v-if="!isLoading && !trees.length">
        <v-col class="no-trees-wrapper" cols="6">
          <h1>{{ $t('noTrees') }}</h1>
          <p>
            <small>
              Создайте свое первое дерево, чтобы сохранить историю вашей семьи.
              <!-- todo add link to tutorial -->
              <!--Вы можете посмотреть урок, в котором я рассказываю, как пользоваться этим веб-приложением.-->
            </small>
          </p>
          <create-tree-dialog :dialog="isCreateTreeDialogOpen" @close="isCreateTreeDialogOpen = false"
                              @save="getPeople">
            <template v-slot:toggle-button="{on, attrs}">
              <v-btn class="mt-3" v-bind="attrs"
                     variant="block"
                     v-on="on">
                {{ $t(`createTree`) }}
              </v-btn>
            </template>
          </create-tree-dialog>
        </v-col>
      </v-row>

      <!-- if user has a tree but not people -->
      <div v-if="trees.length && !hasPeopleInTheTree && !isLoading">
        <div class="container">
          <v-banner class="banner">
            <v-avatar
                slot="icon"
                color="primary"
                size="80"
            >
              <v-icon
                  color="white"
                  icon="mdi-lock"
              >
                groups
              </v-icon>
            </v-avatar>
            <p>
              {{ $t('noPeopleInTheTree') }}
            </p>
            <small>{{ $t('noPeopleInTheTreeDescription') }}</small>
          </v-banner>
          <v-divider></v-divider>
          <v-dialog
              v-model="dialog"
              persistent
              max-width="800"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  block
                  elevation="2"
                  outlined
                  v-bind="attrs"
                  v-on="on"
              >{{ $t('addFirstNode') }}
              </v-btn>
            </template>
            <first-person-creation-form @success="firstPersonFormCompleted"
                                        @cancel="dialog=false"></first-person-creation-form>
          </v-dialog>
        </div>
      </div>
      <!-- on tree node menu "create person" is clicked - the dialog is shown -->
      <create-person-dialog :dialog="createPersonDialogMode"
                            :person="personToAdd"
                            @close="showCreatePersonDialog(false)"
                            @save="savePerson"
                            @update-creating-person="personToAdd=$event"></create-person-dialog>

      <connection-form v-if="isConnectionFormOpen && connectionPersonId"
                       :dialog="isConnectionFormOpen"
                       :person-id="connectionPersonId"
                       @close="toggleConnectionForm(false)"
                       @save="reloadTree"></connection-form>
    </template>

    <template v-if="isOwner || !isLoading && persons">
      <small v-if="!isLoading && persons.length" v-html="$t('tree.quantity', [ persons.length ])"></small>
      <!-- first people are created -->
      <family-tree-component
          :key="persons.length"
          :is-owner="isOwner"
          :nodes="persons"
          @connection="toggleConnectionForm(true, $event)"
          @tree-updated="reloadTree"
          @add-person="addPerson"
          @update-person="addPersonToUpdate"></family-tree-component>
    </template>
  </div>
</template>
<script>
  import FirstPersonCreationForm from '@/modules/tree/first-person-creation-form';
  import FamilyTreeComponent from '@/modules/tree/family-tree';
  import CreatePersonDialog from '@/modules/tree/create-person-dialog';
  import ConnectionForm from '@/modules/person/components/connection-form-dialog';
  import CreateTreeDialog from '@/modules/tree/tree-form-dialog';
  import toast from '@/services/helpers/toast';
  import PersonService from '@/services/person.service';
  import TreeService from '@/services/tree.service';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import PersonModel from '@/models/person.model';

  export default {
    name: 'family-tree-wrapper',
    components: {
      FirstPersonCreationForm,
      FamilyTreeComponent,
      CreatePersonDialog,
      CreateTreeDialog,
      ConnectionForm,
    },
    props: {
      rootPerson: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        service: null,
        personService: null,
        isLoading: false,
        persons: [],
        tree: null,
        trees: [],
        dialog: false,
        selectedPerson: null,
        showDetails: false,
        firstPersonCreationStep: 1,
        createPersonDialogMode: false,
        personToAdd: null,
        isCreateTreeDialogOpen: false,
        isConnectionFormOpen: false,
        connectionPersonId: null,
        peopleToUpdate: [],
      };
    },
    computed: {
      ...mapGetters({ user: 'user' }),
      hasPeopleInTheTree() {
        return this.persons.length;
      },
      isOwner() {
        return this.user && this.user.uid === this.$route.params.globalUserId;
      },
    },
    created() {
      moment.locale(this.$vuetify.lang.current);
    },
    mounted() {
      this.getPeople();
    },
    methods: {
      async reloadTree() {
        await this.getPeople();
      },
      async firstPersonFormCompleted() {
        await this.reloadTree();
        this.dialog = false;
      },
      async getPeople() {
        this.isLoading = true;

        try {
          const userId = this.$route.params.globalUserId;
          const id = this.$route.params.treeId;
          this.service = new TreeService(userId);
          this.personService = new PersonService({ treeId: id });

          if ( userId === this.user.uid ) {
            await this.service.getTrees()
                .then(async trees => {
                  this.trees = trees;
                  if ( trees.length ) {
                    const treeExists = trees.find(tree => tree.id === id);
                    if ( !id || !treeExists ) {
                      await this.$router.push({
                        name: 'tree',
                        params: { treeId: trees[0].id },
                      })
                          .catch(() => {
                          });
                    }
                  } else if ( !trees.length ) {
                    this.$router.push({ name: 'tree' })
                        .catch(() => {
                        });
                  }
                });
          }

          if ( id ) {
            // todo replace with snapshot ?
            // await this.service.getSnapshot(id,async (res) => {
            //   this.tree = res;
            //   await this.personService.getAllSnapshot((list) => {
            //     this.persons = list.map(person => new PersonModel(person));
            //   });
            // });

            const { people } = await this.service.getTreeWithPeople(id);
            this.persons = people.map(person => new PersonModel(person));
          }
        } catch ( e ) {
          toast('error', `alerts.errors.${ e.code }`);

          console.log(e);

        } finally {
          this.isLoading = false;
        }

        // todo add for dead people frame
      },
      onDeselect() {
        this.selectedPerson = null;
        this.showDetails = false;
        this.$router.push({ name: 'tree' });
      },
      showCreatePersonDialog(value) {
        this.createPersonDialogMode = value;
        if ( !value ) {
          this.personToAdd = null;
        }
      },
      addPerson(person) {
        this.showCreatePersonDialog(true);
        this.personToAdd = person;
      },
      async savePerson() {
        try {
          await this.personService.createPerson(this.personToAdd.getPayload());
          await Promise.all(this.peopleToUpdate.map(async ({
            personId,
            fieldToUpdate,
            value,
          }) => {
            await this.updatePerson(personId, fieldToUpdate, value, false);
          }));
        } catch ( e ) {
          toast('error', `alerts.errors.${ e.code }`);

          console.log(e);
        } finally {
          this.showCreatePersonDialog(false);
          await this.reloadTree();
        }
      },
      addPersonToUpdate(personId, fieldToUpdate, value) {
        this.peopleToUpdate.push({
          personId,
          fieldToUpdate,
          value,
        });
      },
      async updatePerson(id, fieldToUpdate, value, notify) {
        try {
          let data = {};
          if ( [ 'childrenIds', 'partnerIds', 'factIds' ].includes(fieldToUpdate) ) {
            const person = this.persons.find(person => person.id === id);
            if ( !person[fieldToUpdate].includes(value) ) {
              data[fieldToUpdate] = [ ...(person ? person[fieldToUpdate] : []), value ];
            }
          } else {
            data[fieldToUpdate] = value;
          }
          await this.personService.updatePerson(id, data, notify);
        } catch ( e ) {
          toast('error', `alerts.errors.${ e.code }`);
        } finally {
          await this.reloadTree();
        }
      },
      toggleConnectionForm(mode, personId) {
        this.isConnectionFormOpen = mode;
        this.connectionPersonId = personId;
      },
    },
    beforeRouteUpdate(to, from, next) {
      next();
      if ( to.params.treeId !== from.params.treeId ) {
        this.reloadTree();
      }
    },
  };
</script>
<style>
.margin-top-xl {
  margin-top: 32px;
}

.text-align-center {
  text-align: center;
}

.person-card {
  width: 180px;
  font-size: 12px;
  text-align: center;
  min-height: 50px;
}

.person-card_avatar:hover,
.person-card__selected {
  filter: drop-shadow(0px 10px 20px #399EE9);
}

.bp-cursor-frame {
  width: 100px !important;
  height: 100px !important;
  margin-left: auto;
  margin-right: auto;
  filter: drop-shadow(0px 10px 20px #399EE9);
  background: #ffffff url(../../components/images/ui-bg_glass_65_ffffff_1x400.png) 50% 50% repeat-x;
}

.person-card_avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.person-card_dates {
  font-size: 10px;
}

.no-trees-wrapper {
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}

/*.person-card_avatar_ribbon {*/
/*  width: 100px;*/
/*  height: 100px;*/
/*  overflow: hidden;*/
/*  position: absolute;*/
/*  bottom: 50px;*/
/*  right: 40px;*/
/*}*/

/*.person-card_avatar_ribbon span {*/
/*  position: absolute;*/
/*  display: block;*/
/*  width: 225px;*/
/*  background-color: black;*/
/*  box-shadow: 0 5px 10px rgba(0,0,0,.1);*/
/*  color: black;*/
/*  font: 700 10px/1 'Lato', sans-serif;*/
/*  text-shadow: 0 1px 1px rgba(0,0,0,.2);*/
/*  text-transform: uppercase;*/
/*  text-align: center;*/
/*}*/

/*.person-card_avatar_ribbon span {*/
/*  left: -25px;*/
/*  bottom: 30px;*/
/*  transform: rotate(-225deg);*/
/*}*/

.bft-light {
  width: 100% !important;
}

</style>
