<template>
  <div>
    <h2>{{ $t('explore.title') }}</h2>
    <p class="text-justify">{{ $t('explore.description') }}</p>
    <v-tabs class="tab" v-model="tabValue" fixed-tabs>
      <v-tab v-for="tab in tabs" :key="tab" :to="{name: tab}">
        {{ $t(`explore.${ tab }`) }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabValue">
      <v-tab-item class="tab-container text-justify" v-for="tab in tabs" :key="`${tab}-content`"
                  :value="'/explore/' + tab">
        <router-view/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  export default {
    name: 'explore-component',
    data() {
      return {
        tabValue: null,
        tabs: [ 'links', 'search', 'metrics', 'dbs', 'marathon' ], // name it as route
      };
    },
    mounted() {
      this.tabValue = this.tabs.indexOf(this.$route.name);
    },
  };
</script>

<style>
.tab {
  margin-top: 20px;
}

.tab-container {
  padding: 20px 0px;
}
</style>
