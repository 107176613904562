import toast from '@/services/helpers/toast';
import SubscriptionsService from '@/services/subscriptions.service';
import axios from 'axios';
import sha256 from 'js-sha256';

// tinkoff
// const terminal = '1683890590058DEMO'; // test
const terminal = '1683890590058'; // prod
// const password = 'dmicbezeanlf6m3w'; // test
const password = 'pqwil16blh8ggqdk'; // prod

const getToken = data => {
    const arrayValues = [];
    Object.entries(data)
        .filter(([ key ]) => ![ 'Shops', 'Receipt', 'DATA' ].includes(key))
        .forEach(([ key, value ]) => {
            arrayValues.push({ [key]: value });
        });

    arrayValues.push({ Password: password });
    const sortedArray = arrayValues.sort((a, b) => Object.keys(a)[0].localeCompare(Object.keys(b)[0]));
    const string = sortedArray.reduce((acc, item) => {
        acc.push(...Object.values(item));
        return acc;
    }, [])
        .join('');
    return sha256.sha256(string);
};

const prepareData = (newSubscription, user) => {
    const data = {
        TerminalKey: terminal,
        Amount: newSubscription.price * 100,
        OrderId: newSubscription.id,
        Recurrent: 'Y',
        CustomerKey: user.uid,
        Description: `Оплата подписки на ${ newSubscription.subscriptionType } №${ newSubscription.id }`,
        Receipt: {
            Email: user.email,
            Taxation: 'usn_income',
            Items: [ {
                Name: `Подписка на ${ newSubscription.subscriptionType }`,
                Price: newSubscription.price * 100,
                Quantity: 1.00,
                Amount: newSubscription.price * 100,
                Tax: 'vat20',
                PaymentObject: 'service'
            } ],
        },
        DATA: {
            Id: user.uid,
            Email: user.email,
        },
        NotificationURL: 'https://api.7gens.me/payment-success',
    }

    const Token = getToken(data);
    return { ...data, Token };
};

const pay = async (newSubscription, user) => {
    try {
        const subsService = new SubscriptionsService();
        await subsService.setDoc(newSubscription);
        const params = prepareData(newSubscription, user);
        // postForm('https://pay.modulbank.ru/pay', {
        const { data } = await axios.post('https://securepay.tinkoff.ru/v2/Init', {
            ...params,
        });
        if ( data.Success ) {
            window.open(data.PaymentURL, '_blank');
        }
    } catch ( e ) {
        console.log(e);
        toast('error', e.code);
    }
};

export default pay;
