import { FirebaseAuth, FirebaseDB } from '@/firebase';
import toast from '@/services/helpers/toast';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export default class UserService {
    constructor() {
        this._doc = doc(FirebaseDB, 'users', FirebaseAuth.currentUser && FirebaseAuth.currentUser.uid);
    }

    async getUserInfo() {
        try {
            const response = await getDoc(this._doc);
            const data = response.data();
            return data;
        } catch ( e ) {
            toast('error', `alerts.errors.${e.code}`);
        }
    }

    async setUserInfo(data) {
        try {
            const response = await getDoc(this._doc);
            console.log(response.data());
            await updateDoc(this._doc, { ...data});
        } catch ( e ) {
            toast('error', `alerts.errors.${e.code}`);
        }
    }
}
