<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('policy') }}
      </v-card-title>
      <v-card-text v-html="policies.ru">
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'policy-page',
    data() {
      return {
        policies: require('./policy.json'),
      };
    },
  };
</script>
