export default class PhotoModel {
    constructor({
        id = self.crypto.randomUUID(),
        name = null,
        description = null,
        date = null,
        downloadUrl = null,
        boxes = [],
        story = {
            treeId: null,
            storyId: null,
        },
    } = {}) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.date = date;
        this.downloadUrl = downloadUrl;
        this.boxes = boxes;
        this.story = story;
    }

    getPayload() {
        return {
            id: this.id,
            name: this.name,
            date: this.date,
            downloadUrl: this.downloadUrl,
            boxes: this.boxes,
            story: this.story,
        };
    }
}

