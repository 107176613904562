import { FirebaseAuth } from '@/firebase';
import FactModel from '@/models/fact.model';
import EntityService from '@/services/entity.service';
import toast from '@/services/helpers/toast';
import PersonService from '@/services/person.service';

export default class FactService extends EntityService {
    _entityModel = FactModel;

    constructor({
        userId = FirebaseAuth.currentUser && FirebaseAuth.currentUser.uid,
        treeId,
    }) {
        super(userId);
        this._entity = `trees/${ treeId }/facts`;
        this._personService = new PersonService({
            userId,
            treeId,
        });
    }

    async getFacts() {
        const facts = await this.getAll();
        return facts;
    }

    async getFact(id) {
        const fact = await this.getDoc(id);
        return fact;
    }

    async createFact(data, notification = true) {
        try {
            await this.setDoc(data);
            const createdFact = await this.getFact(data.id);
            await this.synchronizeWithPerson(undefined, createdFact);
            if ( notification ) {
                toast('success', 'alerts.fact.created');
            }
            return createdFact;
        } catch ( e ) {
            console.log(e);
            toast('error', `alerts.errors.${ e.code }`);

        }
    }

    async removeFact(id) {
        try {
            const fact = await this.getFact(id);
            await this.removeDoc(id);
            await this.synchronizeWithPerson(fact, undefined);
            toast('success', 'alerts.fact.removed');
            return fact;
        } catch ( e ) {
            console.log(e);
            toast('error', `alerts.errors.${ e.code }`);

        }
    }

    async updateFact(data) {
        try {
            const initialFact = await this.getFact(data.id);
            await this.updateDoc(data.id, data);
            const fact = await this.getFact(data.id);
            await this.synchronizeWithPerson(initialFact, fact);
            toast('success', 'alerts.fact.updated');
            return fact;
        } catch ( e ) {
            toast('error', `alerts.errors.${ e.code }`);

        }
    }

    async synchronizeWithPerson(initialFact, fact) {
        // if the person was removed from the fact members list or fact was removed
        const removeFrom = initialFact ? initialFact.memberIds.filter(id => !fact || !fact.memberIds.includes(id)) : [];

        for ( const personId of removeFrom ) {
            const person = await this._personService.getPerson(personId);
            if ( !person.factIds.length || !person.factIds.includes(initialFact.id) ) {
                return;
            }
            await this._personService.updatePerson(person.id, { factIds: person.factIds.filter((id) => id !== initialFact.id) }, false);
        }

        // if the person was added to the fact members list
        if ( fact ) {
            for ( const personId of fact.memberIds ) {
                if ( initialFact && initialFact.memberIds.includes(personId) ) {
                    return;
                }
                const person = await this._personService.getPerson(personId);
                await this._personService.updatePerson(person.id, { factIds: [ ...person.factIds, fact.id ] }, false);
            }
        }
    }
}
