import { FirebaseAuth } from '@/firebase';
import toast from '@/services/helpers/toast';

export default class AuthService {
    constructor({
        store,
        router,
    }) {
        this.store = store;
        this.router = router;
    }

    get user() {
        return FirebaseAuth.currentUser;
    }

    async updateCurrentUser(fields) {
        await FirebaseAuth.updateCurrentUser({ ...this.user, ...fields });
    }

    async logout() {
        await FirebaseAuth.signOut()
            .then(() => {
                this.router.push({ name: 'login' });
                // Sign-out successful.
                this.store.dispatch('logout');
            })
            .catch((error) => {
                // An error happened.
                    toast('error', `alerts.errors.${error.code}`);

console.log(error);
            });
    }
}
