<template>
  <v-container>
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-text v-html="policies.ru">
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'keep-data-page',
    data() {
      return {
        policies: require('./keepPD.json'),
      };
    },
  };
</script>
