<template>
  <div class="box-wrapper">
    <v-btn class="box-delete"
           @click="remove"
           v-if="active"
           elevation="2"
           icon
           :style="{
               top: (top - 40) +'px',
               left: (left + width) + 'px',
               'z-index': 7
               }">
      <v-icon>delete</v-icon>
    </v-btn>
    <div class="box"
         :style="{top: top + 'px', left: left + 'px', width: width + 'px', height: height + 'px'}"
         :class="{'active': active || awaitingLabel, 'highlighted': highlighted}"
         @mousedown="select">
    </div>
    <div class="label"
         v-if="persons && persons[treeId]"
         :style="{
                 top: (top + height) + 'px',
                 left: left + 'px',
                 width: (width) + 'px'}">
      <box-label :person-id="personId" :tree-id="treeId" :persons="persons"></box-label>
    </div>
  </div>
</template>

<script>
  import BoxLabel from '@/modules/photos/components/box-label';

  export default {
    name: 'detection-box',
    components: { BoxLabel },
    props: {
      top: [ String, Number ],
      left: [ String, Number ],
      width: [ String, Number ],
      height: [ String, Number ],
      active: Boolean,
      awaitingLabel: Boolean,
      highlighted: Boolean,
      treeId: {
        type: String,
      },
      personId: {
        type: String,
      },
      persons: {
        type: Object,
      },
    },
    methods: {
      select() {
        this.$emit('select');
      },
      remove() {
        this.$emit('remove');
      },
    },
  };
</script>

<style>
.box {
  position: absolute;
  border: 2px #ff9200 solid;
  z-index: 3;
}

.box:hover,
.box.active,
.box.highlighted {
  background-color: rgba(255, 123, 121, .2);
}

.box-delete {
  position: absolute;
  cursor: pointer;
  z-index: 6;
}

.label {
  position: absolute;
  font-size: 11px;
  color: white;
  background-color: #ff9200;
  z-index: 4;
  text-align: center;
}
</style>
