import EntityService from '@/services/entity.service';

export default class PlaceService extends EntityService {
    _entity = 'places';

    constructor() {
        super();
    }

    async getPlaces() {
        const places = await this.getAll();
        return places;
    }

    async getPlace(id) {
        const place = await this.getDoc(id);
        return place;
    }

    async createPlace(data) {
        const place = await this.addDoc(data);
        return place;
    }
}
