<template>
  <v-container>
    <div v-if="!isLoading && story">
      <div class="d-flex align-center justify-space-between">
        <h2>{{ story.title }}</h2>
        <div>
          <router-link :to="{name: 'story-form', params: {treeId: story.treeId, storyId: story.id}}">
            <v-btn icon text>
              <v-icon>edit</v-icon>
            </v-btn>
          </router-link>
          <remove-confirmation-dialog :important="true" :name="story.title" entity="story"
                                      @confirmed="deleteStory(story.id)"></remove-confirmation-dialog>
        </div>
      </div>
      <div class="d-flex align-center mt-4 mb-4 pa-2" v-if="story.country || story.place">
        <v-icon color="primary" class="mr-2">location_on</v-icon>
        <span>{{ countries[story.country] }}{{ story.place && `, ${ story.place }` }}</span>
      </div>
      <div class="d-flex align-center mt-4 mb-4 pa-2" v-if="story.period">
        <v-icon color="primary" class="mr-2">calendar_month</v-icon>
        <span>{{ period }}</span>
      </div>
      <div class="story_members">
        <h5 class="mb-2">{{ $t('stories.memberIds') }}</h5>
        <template v-if="members.length">
          <div v-for="member in members" :key="member.id">
            <router-link :to="{name: 'person-page', params: {globalUserId: member.userId, personId: member.id}}">
              {{ member.name }}
            </router-link>
          </div>
        </template>
        <template v-else>
          <p>{{ $t('stories.noMembers') }}</p>
        </template>
      </div>
      <div class="story_photos">
        <h5 class="mb-2">{{ $t('stories.photoIds') }}</h5>
        <template v-if="photos.length">
          <div v-for="image in photos" :key="image.id">
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <img :src="image?.downloadUrl"
                     :alt="image?.name"
                     v-bind="attrs"
                     v-on="on"/>
              </template>

              <v-card>
                <v-card-title>
                  {{ image?.name }}
                </v-card-title>

                <v-card-text class="pa-3">
                  <img :style="{
                          width: '100%',
                          height: 'auto',
                  }"
                       :src="image?.downloadUrl"
                       :alt="`image-${image.id}`">
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                  >
                    <router-link :to="{name: 'photo-page', params: {photoId: image?.id}}">
                      {{ $t('photos.goToPhotoPage') }}
                    </router-link>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog><!--              {{ photo.title }}-->
          </div>
        </template>
        <template v-else>
          <p>{{ $t('stories.noPhotos') }}</p>
        </template>
      </div>
      <div class="mt-8 mb-8" v-html="story.text"></div>
    </div>
    <div v-if="isLoading" class="ml-auto mr-auto text-align-center mt-3" width="100px">
      <v-progress-circular :size="40"
                           color="#ff9200"
                           indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
  import { countries } from '@/models/helpers';
  import toast from '@/services/helpers/toast';
  import PersonService from '@/services/person.service';
  import PhotoService from '@/services/photo.service';
  import StoryService from '@/services/story.service';
  import RemoveConfirmationDialog from '@/components/remove-confirmation-dialog.vue';
  import moment from 'moment';

  export default {
    name: 'story-page',
    components: { RemoveConfirmationDialog },
    data() {
      return {
        storyService: null,
        photoService: new PhotoService(),
        personService: null,
        story: null,
        members: [],
        photos: [],
        isLoading: false,
        countries,
      };
    },
    computed: {
      period() {
        return this.story && this.story.period && moment(this.story.period).format('ll');
      }
    },
    created() {
      this.personService = new PersonService({
        userId: this.$route.params.globalUserId,
        treeId: this.$route.params.treeId,
      });
      this.storyService = new StoryService({
        userId: this.$route.params.globalUserId,
        treeId: this.$route.params.treeId,
      });
      this.fetchData();
    },
    methods: {
      async fetchData() {
        this.isLoading = true;
        try {
          await this.fetchStory();
          await this.fetchPeople();
          await this.fetchPhotos();
        } catch ( e ) {
                      toast('error', `alerts.errors.${e.code}`);

          console.log(e);

        } finally {
          this.isLoading = false;
        }
      },
      async fetchPeople() {
        this.members = await Promise.all(this.story.memberIds.map(id => this.personService.getPerson(id)));
      },
      async fetchPhotos() {
        this.photos = await Promise.all(this.story.photoIds.map(id => this.photoService.getPhoto(id)));
      },
      async fetchStory() {
        this.story = await this.storyService.getStory(this.$route.params.storyId);
      },
      deleteStory(id) {
        this.storyService.removeStory(id)
            .then(() => {
              this.$router.push({ name: 'stories' });
            });
      },
    },
  };
</script>

<style>
.story_members,
.story_photos {
  background-color: #EAEAEA;
  padding: 16px;
  width: 100%;
  margin-top: 16px;
}

.story_photos img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

</style>
