import { onlyUnique, PeriodTypes, prepareDate, Sex } from './helpers';

const AvatarPlaceholders = {
    female: `https://i.imgur.com/1WC9fHj.png`,
    male: `https://i.imgur.com/fHigYFy.png`,
};

export default class PersonModel {
    constructor({
        id = self.crypto.randomUUID(),
        email = null,
        firstName = null,
        lastName = null,
        middleName = null,
        maidenName = null,
        birthDate = {
            type: PeriodTypes.exactly,
            date: null,
        },
        birthPlace = null,
        birthCountry = null,
        deathDate = {
            type: PeriodTypes.exactly,
            date: null,
        },
        deathPlace = null,
        deathCountry = null,
        isAlive = true,
        gender = Sex.male,
        motherId = null,
        fatherId = null,
        partnerIds = [],
        childrenIds = [],
        avatar = null,
        factIds = [],
        storiesIds = [],
        bio = null,
        photoIds = [],
        isFirstFocusOnInit = false,
        sources = [],
    } = {}) {
        this.id = id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.maidenName = maidenName;
        this.birthDate = birthDate;
        this.birthPlace = birthPlace;
        this.birthCountry = birthCountry;
        this.deathDate = deathDate;
        this.deathPlace = deathPlace;
        this.deathCountry = deathCountry;
        this.isAlive = isAlive;
        this.gender = gender;
        this.motherId = motherId;
        this.fatherId = fatherId;
        this.partnerIds = partnerIds;
        this.childrenIds = childrenIds;
        this.avatar = avatar;
        this.factIds = factIds;
        this.storiesIds = storiesIds;
        this.bio = bio;
        this.photoIds = photoIds;
        this.isFirstFocusOnInit = isFirstFocusOnInit;
        this.sources = sources;
    }

    get avatarUrl() {
        return this.avatar || AvatarPlaceholders[this.gender];
    }

    get name() {
        return (this.firstName || this.lastName) ? `${ this.firstName || '' } ${ this.lastName || '' }` : 'Без имени';
    }

    get fullName() {
        let name = [];
        if ( this.firstName ) {
            name.push(this.firstName);
        }
        if ( this.middleName ) {
            name.push(this.middleName);
        }

        if ( this.lastName ) {
            name.push(this.lastName);
            if ( this.maidenName ) {
                name.push(`(${ this.maidenName })`);
            }
        } else {
            if ( this.maidenName ) {
                name.push(this.maidenName);
            }
        }

        return name.join(' ');
    }

    get isToplevel() {
        return !this.motherId && !this.fatherId;
    }

    get isFemale() {
        return this.gender === Sex.female;
    }

    get image() {
        return this.avatar;
    }

    get tags() {
        const tags = {
            'is-dead': this.isDead,
        };

        return Object.entries(tags)
            .filter(([ , value ]) => value)
            .map(([ key ]) => {
                return `${ this.gender }-${ key }`;
            });
    }

    get dates() {
        let dates = [];

        if ( this.birthDate ) {
            dates.push(prepareDate(this.birthDate));
        }
        if ( !this.isAlive && this.deathDate && this.deathDate.date ) {
            dates.push(prepareDate(this.deathDate));
            return dates.join(' - ');
        }
        //
        return this.birthDate && dates[0];
    }

    // get parents() {
    //     return [ this.motherId, this.fatherId ];
    // }

    get mid() {
        return this.motherId;
    }

    get fid() {
        return this.fatherId;
    }

    get pids() {
        return this.partnerIds.filter(onlyUnique);
    }

    get isDead() {
        return !this.isAlive && this.isAlive === false;
    }

    get uniqueFactIds() {
        return this.factIds.filter(onlyUnique);
    }

    getPayload() {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            middleName: this.middleName,
            maidenName: this.maidenName,
            gender: this.gender,
            isAlive: this.isAlive,
            motherId: this.motherId,
            fatherId: this.fatherId,
            partnerIds: this.partnerIds.filter(onlyUnique),
            birthDate: this.birthDate && this.birthDate.date ? this.birthDate : null,
            birthPlace: this.birthPlace,
            birthCountry: this.birthCountry,
            deathDate: this.deathDate && this.deathDate.date ? this.deathDate : null,
            deathPlace: this.deathPlace,
            deathCountry: this.deathCountry,
            avatar: this.avatar,
            bio: this.bio,
            storiesIds: this.storiesIds.filter(onlyUnique),
            factIds: this.factIds.filter(onlyUnique),
            sources: this.sources,
            photoIds: this.photoIds,
            childrenIds: this.childrenIds.filter(onlyUnique),
            isFirstFocusOnInit: this.isFirstFocusOnInit,
        };
    }
}
