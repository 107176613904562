export default class StoryModel {
    constructor({
        id = self.crypto.randomUUID(),
        title = '',
        description = '',
        text = '',
        link = null,
        country = '',
        place = '',
        treeId= null,
        memberIds = [],
        photoIds = [],
        period = null,
    } = {}) {
        this.id = id;
        this.title = title;
        this.text = text;
        this.link = link;
        this.description = description;
        this.memberIds = memberIds;
        this.period = period;
        this.place = place;
        this.treeId = treeId;
        this.photoIds = photoIds;
        this.country = country;
    }

    getPayload() {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            memberIds: this.memberIds,
            period: this.period,
            place: this.place,
            country: this.country,
            text: this.text,
            photoIds: this.photoIds,
            treeId: this.treeId,
        };
    }

    get isStory() {
        return true;
    }
}
