<template>
  <div v-if="!isLoading" class="home">
    <main-menu></main-menu>
    <v-parallax class="home_parallax"
                dark
                src="../assets/images/main_image2.jpg"
    >
      <v-container>
        <v-row class="align-center">
          <v-col class="home_slogan-column"
                 cols="12"
                 sm="5">
            <h1>{{ $t('slogan') }}</h1>
            <h2>{{ $t('subSlogan') }}</h2>
          </v-col>

          <v-col cols="12"
                 sm="7">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
  </div>
</template>

<script>
  import MainMenu from '@/components/main-menu';
  import { mapGetters } from 'vuex';

  export default {
    name: 'login-layout',
    components: { MainMenu },
    computed: {
      ...mapGetters({
        isLoading: 'isLoading',
      }),
    },
  };
</script>

<style>
.home_parallax {
  height: 100vh !important;
}

.home_slogan-column {
  padding-right: 24px;
}

.home_slogan-column h1 {
  font-size: 48px;
}

.home_slogan-column h2 {
  margin-top: 24px;
  font-weight: normal;
}

.home_form_actions {
  display: block;
}
</style>
