<template>
  <v-container class="">
    <div v-if="isLoading" class="ml-auto mr-auto text-align-center mt-3" width="100px">
      <v-progress-circular :size="40"
                           color="#ff9200"
                           indeterminate
      ></v-progress-circular>
    </div>
    <div class="list_wrapper" v-else>
      <template v-if="hasPhotos">
        <v-row max-width="800px">
          <v-col
              v-for="(photo, i) in list"
              :key="photo.id"
              class="d-flex child-flex"
              cols="4"
          >
            <v-img
                :src="photo.downloadUrl"
                :lazy-src="`${photo.downloadUrl}?image=${i * 5 + 10}`"
                aspect-ratio="1"
                class="grey lighten-2"
                :disabled="!photo.id"
                @click="navigateToPhotoPage(photo.id)"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-banner class="banner">
          <v-avatar
              slot="icon"
              color="primary"
              size="80"
          >
            <v-icon
                icon="mdi-lock"
                color="white"
            >
              art_track
            </v-icon>
          </v-avatar>
          <p>
            {{ $t('noPhotos') }}
          </p>
          <small>{{ $t('noPhotosDescription') }}</small>
        </v-banner>
      </template>
    </div>
    <photo-add-modal></photo-add-modal>
  </v-container>
</template>

<script>
  import PhotoModel from '@/models/photo.model';
  import PhotoAddModal from '@/modules/photos/components/photo-add-modal';
  import PhotoStorageService from '@/services/photo-storage.service';
  import PhotoService from '@/services/photo.service';

  export default {
    name: 'PhotoList',
    components: {
      PhotoAddModal,
    },
    data() {
      return {
        dialog: false,
        list: [],
        service: new PhotoService(),
        storageService: new PhotoStorageService(),
        photo: new PhotoModel(),
        file: null,
        isLoading: false,
      };
    },
    computed: {
      hasPhotos() {
        return this.list.length !== 0;
      },
    },
    created() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        this.isLoading = true;
        await this.service.getAllSnapshot((data) => {
          this.list = data;
          this.isLoading = false;
        });
      },
      navigateToPhotoPage(id) {
        this.$router.push({
          name: 'photo-page',
          params: { photoId: id },
        });
      },

    },
  };
</script>

<style>
.v-btn--example {
  bottom: calc(122px + 30px);
  position: absolute !important;
}

.list_wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.banner, .v-banner__wrapper {
  width: 50%;
  min-width: 500px;
  margin-left: auto;
  margin-right: auto;
  /*margin-top: 20vh;*/
  border-bottom: none !important;
  box-shadow: none;
}
</style>
