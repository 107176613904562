import { FactTypes, PeriodTypes } from '@/models/helpers';

export default class FactModel {
    constructor({
        id = self.crypto.randomUUID(),
        type = FactTypes.custom,
        title = '',
        description = '',
        link = null,
        country = '',
        place = '',
        memberIds = [],
        period = {
            type: PeriodTypes.exactly
        },
        isLifeFact = false,
    } = {}) {
        this.id = id;
        this.type = type;
        this.title = title;
        this.link = link;
        this.description = description;
        this.memberIds = memberIds;
        this.period = period;
        this.place = place;
        this.country = country;
        this.isLifeFact = isLifeFact;
    }

    getPayload() {
        return {
            id: this.id,
            type: this.type,
            title: this.title,
            description: this.description,
            memberIds: this.memberIds,
            period: this.period,
            place: this.place,
            country: this.country,
            isLifeFact: this.isLifeFact,
        };
    }
}
