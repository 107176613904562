<template>
  <div v-if="isLoggedIn">
    <template v-if="!isLoading && !trees.length">
      <tree-form-dialog :disabled="disabled" :tree-list="trees" @save="routeToTree">
        <template v-slot:toggle-button="{on, attrs}">
          <v-btn v-bind="attrs"
                 :disabled="disabled"
                 variant="block"
                 text
                 v-on="on">
            {{ $t(`createTree`) }}
          </v-btn>
        </template>
      </tree-form-dialog>
    </template>
    <template v-else-if="!isLoading">
      <v-menu bottom>
        <template v-slot:activator="{ on, attrs }">
          <slot :attrs="attrs" :on="on" name="activator">
            <v-btn
                :disabled="disabled"
                class="mr-7"
                elevation="0"
                text
                outlined
                v-bind="attrs"
                v-on="on"
            >
              {{ selectedTree ? selectedTree.name : $t(`tree.selectTree`) }}
              <v-icon
                  right
              >
                arrow_drop_down
              </v-icon>
            </v-btn>
          </slot>
        </template>
        <v-card>
          <v-list v-if="!disabled && trees.length">
            <template v-if="hasTrees">
              <v-list-item
                  v-for="(item, index) in trees"
                  :key="item.id"
                  :value="index"
              >
                <v-list-item-title>
                  <router-link :to="{name: 'tree', params: {treeId: item.id}}">
                    {{ item.name }}
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item>
                {{ $t(`noTrees`) }}
              </v-list-item>
            </template>

            <v-divider></v-divider>

            <v-card-actions class="mt-1 justify-center">
              <tree-form-dialog v-if="isLoggedIn" :tree-list="trees || []" @save="routeToTree"></tree-form-dialog>
            </v-card-actions>
          </v-list>
        </v-card>
      </v-menu>
    </template>
    <!--    <template v-else>-->
    <!--      <v-skeleton-loader type="button" :loading="isLoading"></v-skeleton-loader>-->
    <!--    </template>-->
  </div>
</template>

<script>
  import toast from '@/services/helpers/toast';
  import TreeService from '@/services/tree.service';
  import TreeFormDialog from '@/modules/tree/tree-form-dialog';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        isLoading: false,
        isCreateTreeDialogOpen: false,
        trees: [],
        service: null,
      };
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    components: { TreeFormDialog },
    computed: {
      ...mapGetters([ 'isLoggedIn' ]),
      quantity() {
        return this.trees && this.trees.length;
      },
      hasTrees() {
        return this.quantity > 0;
      },
      selectedTree() {
        return this.trees.find((tree) => tree.id === this.$route.params.treeId);
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        this.isLoading = true;
        if ( this.isLoggedIn ) {
          try {
            this.service = new TreeService();
            await this.service.getAllSnapshot((list) => {
              this.trees = list;
            });
          } catch ( e ) {
                        toast('error', `alerts.errors.${e.code}`);

            console.log(e);
          } finally {
            this.isLoading = false;
          }
        }
      },
      routeToTree(treeId) {
        this.isCreateTreeDialogOpen = false;
        this.$router.push({
          name: 'tree',
          params: { treeId },
        });
      },
    },
  };
</script>
