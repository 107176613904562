var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-wrapper"},[(_vm.active)?_c('v-btn',{staticClass:"box-delete",style:({
             top: (_vm.top - 40) +'px',
             left: (_vm.left + _vm.width) + 'px',
             'z-index': 7
             }),attrs:{"elevation":"2","icon":""},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e(),_c('div',{staticClass:"box",class:{'active': _vm.active || _vm.awaitingLabel, 'highlighted': _vm.highlighted},style:({top: _vm.top + 'px', left: _vm.left + 'px', width: _vm.width + 'px', height: _vm.height + 'px'}),on:{"mousedown":_vm.select}}),(_vm.persons && _vm.persons[_vm.treeId])?_c('div',{staticClass:"label",style:({
               top: (_vm.top + _vm.height) + 'px',
               left: _vm.left + 'px',
               width: (_vm.width) + 'px'})},[_c('box-label',{attrs:{"person-id":_vm.personId,"tree-id":_vm.treeId,"persons":_vm.persons}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }