<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text
             icon
             v-bind="attrs"
             v-on="on">
        <v-icon>delete</v-icon>
      </v-btn>
    </template>

    <v-card>
      <template v-if="isLoading">
        <v-card-title class="justify-center">
          <v-progress-circular
              indeterminate
              color="red"
          ></v-progress-circular>
        </v-card-title>
      </template>
      <template v-else>
        <v-card-title class="red darkn-2 text--white">
          {{ $t('forms.removeConfirmation', [ $t('forms.removeEntities.' + entity) ]) }}
        </v-card-title>

        <v-card-text class="pa-4">
          <p v-html="$t('forms.removeConfirmationText',[name])"></p>
          <p v-html="$t('forms.confirmationType', [name])"></p>
          <v-text-field
              v-if="important"
              v-model="typeValue"
              color="red"
              prepend-icon="warning"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="text--white"
              :disabled="important && !checked"
              color="red"
              @click="$emit('confirmed')"
          >
            {{ $t('forms.confirm') }}
          </v-btn>
          <v-btn text @click="dialog=false">
            {{ $t('forms.cancel') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      entity: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      important: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        typeValue: '',
        dialog: false,
      };
    },
    computed: {
      checked() {
        return this.typeValue === this.name;
      },
    },
  };
</script>
