export default {
    OpenWikiRu: 'Открытый список',
    OpenWikiUa: 'Відкритий список',
    OpenWikiGe: 'ღია სია',
    OpenWikiBy: 'Адкрыты спіс',

    PN: 'Память народа',
    BB: 'Бессмертный Барак',
};

export const langs = {
    ru: 'OpenWikiRu',
    ua: 'OpenWikiUa',
    ge: 'OpenWikiGe',
    by: 'OpenWikiBy',
}
