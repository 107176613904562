import Languages from '@/assets/languages';
import UserModel from '@/models/user.model';
import vuetify from '@/plugins/vuetify';
import PhotoService from '@/services/photo.service';
import TreeService from '@/services/tree.service';
import UserService from '@/services/user.service';
import moment from 'moment';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-plugin-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state() {
        return {
            user: null,
            isLoggedIn: false,
            isLoading: false,
            info: {},
            peopleTotal: 0,
            photos: [],
            trees: [],
        };
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        user: state => state.user,
        userId: state => state.user && state.user.uid,
        info: state => state.info,
        uiLanguagePreference: state => state.info && state.info.uiLanguage || Languages.Russian,
        isLoading: state => state.isLoading,
        freePlan: state => !state.info || !state.info.subscriptionEnd || moment(state.info.subscriptionEnd)
            .isBefore(moment.now),
        trees: state => state.trees,
        treesTotal: state => state.trees && state.trees.length,
        peopleTotal: state => state.peopleTotal,
        photos: state => state.photos,
        photosTotal: state => state.photos && state.photos.length,
        limitIsOff: (state, getters) => getters.freePlan && (getters.treesTotal > 1 || getters.peopleTotal > 200 || getters.photosTotal > 200),
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.isLoggedIn = value;
        },
        SET_LOADING(state, value) {
            state.isLoading = value;
        },
        SET_USER(state, data) {
            state.user = data;
        },
        SET_INFO(state, data) {
            state.info = data;
        },
        SET_PEOPLE_QUANTITY(state, data) {
            state.peopleTotal = (data || []).length;
        },
        SET_TREES(state, data) {
            state.trees = data;
        },
        SET_PHOTOS(state, data) {
            state.photos = data;
        },
    },
    actions: {
        async setUser({
            commit,
            dispatch,
        }, payload) {
            commit('SET_LOGGED_IN', !!payload);
            commit('SET_USER', payload && new UserModel(payload));

            dispatch('fetchInfo');
        },
        toggleLoading({ commit }, value) {
            commit('SET_LOADING', value);
        },
        logout({ commit }) {
            commit('SET_LOGGED_IN', false);
            commit('SET_USER', null);
        },
        async fetchInfo({
            commit,
            getters,
        }) {
            if ( !getters.isLoggedIn ) {
                return;
            }
            const userService = new UserService();
            userService.getUserInfo()
                .then(data => {
                    commit('SET_INFO', data);
                    vuetify.framework.lang.current = getters.uiLanguagePreference;
                });

            const treeService = new TreeService(getters.user.uid);
            const trees = await treeService.getTrees();
            commit('SET_TREES', trees);
            if ( getters.freePlan && trees.length ) {
                const { people } = await treeService.getTreeWithPeople(trees[0].id);
                commit('SET_PEOPLE_QUANTITY', people);
            } else if ( trees.length ) {
                let allPeople = [];
                const treesWithPeople = await Promise.all(trees.map(({ id }) => treeService.getTreeWithPeople(id)));
                treesWithPeople.forEach(({ people }) => {
                    people.forEach(person => allPeople.push(person));
                });
                commit('SET_PEOPLE_QUANTITY', allPeople);
            }

            const photoService = new PhotoService();
            const photos = await photoService.getPhotos();
            commit('SET_PHOTOS', photos);
        },

        setUiLanguagePreference({ dispatch }, uiLanguage) {
            const userService = new UserService();
            userService.setUserInfo({ uiLanguage })
                .then(() => {
                    dispatch('fetchInfo');
                });
        },
    },
    plugins: [
        createPersistedState({
            key: 'user',
            paths: [ 'user' ],
        }),
    ],
});
