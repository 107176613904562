<template>
  <div>
    <div v-show="nodes.length" style="width:100%; height:calc(100vh - 100px);" ref="tree" id="tree"/>
    <plans-dialog-stopper :dialog="showPaymentDialog" @close="showPaymentDialog = false"></plans-dialog-stopper>
    <v-dialog v-if="removeDialog"
              v-model="removeDialog"
              max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t(`tree.removePerson`) }}
        </v-card-title>

        <v-card-text>{{ $t(`tree.removePersonDescription`, { name: personToRemove.fullName }) }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green darken-1"
              text
              @click="closeRemoveDialog"
          >
            {{ $t(`forms.cancel`) }}
          </v-btn>
          <v-btn
              color="red darken"
              @click="removePerson"
          >
            {{ $t(`tree.remove`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { Sex } from '@/models/helpers';
  import PersonModel from '@/models/person.model';
  import PlansDialogStopper from '@/modules/profile/plans-dialog-2';
  import PersonService from '@/services/person.service';
  import FamilyTree from '@/modules/tree/BALKAN_FamilyTreeJS_PROFESSIONAL_1.07.29-min/familytree.js';

  import Vue from 'vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'family-tree-component',
    components: { PlansDialogStopper },
    props: {
      nodes: {
        type: Array,
        default: () => [],
        required: true,
      },
      isOwner: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tree: null,
        removeDialog: false,
        personToRemove: null,
        personService: null,
        person: null,
        showPaymentDialog: false,
      };
    },
    computed: {
      ...mapGetters([ 'limitIsOff' ]),
    },
    mounted() {
      const treeId = this.$route.params.treeId;
      this.personService = new PersonService({ treeId });
      this.setupTree();
    },
    watch: {
      // nodes() {
      //   this.loadTree();
      // },
    },
    methods: {
      loadTree() {
        if ( this.tree && this.nodes.length ) {
          this.tree.load(this.nodes);
        }
      },
      setupTree() {
        FamilyTree.templates.john = Object.assign({}, FamilyTree.templates.base);
        FamilyTree.templates.john.defs = `<style>
                                        .{randId} .bft-edit-form-header, .{randId} .bft-img-button{
                                            background-color: #aeaeae;
                                        }
                                        .{randId}.male .bft-edit-form-header, .{randId}.male .bft-img-button{
                                            background-color: var(--male-color)  ;
                                        }
                                        .{randId}.male div.bft-img-button:hover{
                                            background-color: var(--female-color)  ;
                                        }
                                        .{randId}.female .bft-edit-form-header, .{randId}.female .bft-img-button{
                                            background-color: var(--female-color)  ;
                                        }
                                        .{randId}.female div.bft-img-button:hover{
                                            background-color: var(--male-color)  ;
                                        }
                                    </style>
                                    <clipPath id="john_img_0"><rect x="6" y="6" rx="54" ry="54" width="108" height="108"></rect></clipPath>
                                    ${ FamilyTree.gradientCircleForDefs('circle', '#aeaeae', 60, 5) }
                                    ${ FamilyTree.gradientCircleForDefs('male_circle', '#00baf0', 60, 5) }
                                    ${ FamilyTree.gradientCircleForDefs('female_circle', '#ff9200', 60, 5) }
                                    ${ FamilyTree.gradientCircleForDefs('male_is_dead_circle', '#00baf0', 60, 5) }
                                    ${ FamilyTree.gradientCircleForDefs('female_is_dead_circle', '#ff9200', 60, 5) }
                                    ${ FamilyTree.gradientCircleForDefs('gradient', 'rgba(0,0,0,0.2)', 60, 5) }`;
        FamilyTree.templates.john.field_0 =
            '<text ' + FamilyTree.attr.width + ' ="230" style="font-size: 16px; font-weight:bold;" background-color="#ffffff" fill="#000000" x="60" y="140" text-anchor="middle">{val}</text>';
        FamilyTree.templates.john.field_1 =
            '<text ' + FamilyTree.attr.width + ' ="200" style="font-size: 13px;" fill="#000000" x="60" y="155" text-anchor="middle">{val}</text>';
        FamilyTree.templates.john.node = '<use x="0" y="0" xlink:href="#circle"/>';
        FamilyTree.templates.john.img_0 =
            '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#john_img_0)" xlink:href="{val}" x="6" y="6" width="108" height="108"></image>';

        FamilyTree.templates.john.size = [ 120, 120 ];
        FamilyTree.templates.john_male = Object.assign({}, FamilyTree.templates.john);
        FamilyTree.templates.john_male.node += '<use x="0" y="0" xlink:href="#male_circle" />';
        FamilyTree.templates.john_male.ripple = {
          radius: 60,
          color: '#00baf0',
          rect: null,
        };
        FamilyTree.templates.john_female = Object.assign({}, FamilyTree.templates.john);
        FamilyTree.templates.john_female.node += '<use x="0" y="0" xlink:href="#female_circle" />';
        FamilyTree.templates.john_female.ripple = {
          radius: 60,
          color: '#ff9200',
          rect: null,
        };

        FamilyTree.templates.john_male_is_dead = Object.assign({}, FamilyTree.templates.john);
        FamilyTree.templates.john_male_is_dead.node += '<use x="0" y="0" xlink:href="#male_circle" filter="url(#inset-shadow)" />';
        FamilyTree.templates.john_male_is_dead.ripple = FamilyTree.templates.john_male.ripple;

        FamilyTree.templates.john_female_is_dead = Object.assign({}, FamilyTree.templates.john);
        FamilyTree.templates.john_female_is_dead.node += '<use x="0" y="0" xlink:href="#female_circle" filter="url(#inset-shadow)" />';

        FamilyTree.templates.john.menuButton = `
            <div style="position:absolute;right:{p}px;top:{p}px; width:40px;height:50px;cursor:pointer;" ${ FamilyTree.attr.control_export_menu }="">
                <hr style="background-color: #7A7A7A; height: 9px; border: 3px solid white;">
                <hr style="background-color: #7A7A7A; height: 9px; border: 3px solid white;">
                <hr style="background-color: #7A7A7A; height: 9px; border: 3px solid white;">
            </div>`;

        FamilyTree.templates.john.nodeCircleMenuButton = this.isOwner && {
          radius: 20,
          x: 120,
          y: 60,
          color: 'rgba(210,103,38,0.20)',
          stroke: 'rgba(243,243,243,0.75)',
        };

        FamilyTree.templates.john_female.nodeCircleMenuButton = FamilyTree.templates.john.nodeCircleMenuButton;
        FamilyTree.templates.john_male.nodeCircleMenuButton = FamilyTree.templates.john.nodeCircleMenuButton;
        FamilyTree.templates.john_male_is_dead.nodeCircleMenuButton = FamilyTree.templates.john.nodeCircleMenuButton;
        FamilyTree.templates.john_female_is_dead.nodeCircleMenuButton = FamilyTree.templates.john.nodeCircleMenuButton;

        FamilyTree.SEARCH_PLACEHOLDER = this.$t('tree.searchPlaceholder');
        const configs = {
          menu: {
            pdf: { text: this.$t('tree.export.pdf') },
            png: { text: this.$t('tree.export.png') },
            svg: { text: this.$t('tree.export.svg') },
            // csv: { text: this.$t('tree.export.csv') },
            json: { text: this.$t('tree.export.json') },
          },
          mouseScrool: FamilyTree.action.ctrlZoom,
          nodeBinding: {
            field_0: 'name',
            field_1: 'dates',
            img_0: 'avatarUrl',
          },
          template: 'john',
          levelSeparation: 80,
          minPartnerSeparation: 120,
          siblingSeparation: 120,
          searchFieldsAbbreviation: {
            n: 'name',
            d: 'dates',
          },
          searchDisplayField: 'fullName',
          nodeMouseClick: FamilyTree.action.none,
          // scaleMin: 0.6,
          // scaleMax: 0.8,
          // miniMap: true,
          tags: {
            'female-is-dead': {
              template: 'john_female_is_dead',
            },
            'male-is-dead': {
              template: 'john_male_is_dead',
            },
          },
        };

        if ( this.isOwner ) {
          configs.nodeCircleMenu = {
            details: {
              icon: FamilyTree.icon.details(30, 30, '#9b9b9b'),
              text: Vue.prototype.$t('tree.details'),
              color: 'white',
            },
            edit: {
              icon: FamilyTree.icon.edit(30, 30, '#9b9b9b'),
              text: Vue.prototype.$t('tree.edit'),
              color: 'white',
            },
            connection: {
              icon: FamilyTree.icon.link(30, 30, '#9b9b9b'),
              text: Vue.prototype.$t('tree.connection'),
              color: 'white',
            },
            son: {
              icon: FamilyTree.icon.son(30, 30, '#00baf0'),
              text: Vue.prototype.$t('tree.addSon'),
              color: 'white',
            },
            daughter: {
              icon: FamilyTree.icon.daughter(30, 30, '#ff9200'),
              text: Vue.prototype.$t('tree.addDaughter'),
              color: 'white',
            },
          };
        }

        const self = this;
        const family = new FamilyTree(this.$refs.tree, configs);

        family.nodeCircleMenuUI.on('show', function (sender, args) {
          if ( args.menu ) {
            const node = family.getNode(args.nodeId);

            if ( FamilyTree.isNEU(node.mid) ) {
              args.menu.mother = {
                icon: FamilyTree.icon.mother(30, 30, '#ff9200'),
                text: Vue.prototype.$t('tree.addMother'),
                color: 'white',
              };
            } else {
              delete args.menu.mother;
            }
            if ( FamilyTree.isNEU(node.fid) ) {
              args.menu.father = {
                icon: FamilyTree.icon.father(30, 30, '#00baf0'),
                text: Vue.prototype.$t('tree.addFather'),
                color: 'white',
              };
            } else {
              delete args.menu.father;
            }
            if ( node.gender === Sex.male ) {
              args.menu.wife = {
                icon: FamilyTree.icon.wife(30, 30, '#ff9200'),
                text: Vue.prototype.$t('tree.addWife'),
                color: 'white',
              };
            } else {
              delete args.menu.wife;
            }

            if ( node.gender === Sex.female ) {
              args.menu.husband = {
                icon: FamilyTree.icon.husband(30, 30, '#ff9200'),
                text: Vue.prototype.$t('tree.addHusband'),
                color: 'white',
              };
            } else {
              delete args.menu.husband;
            }
            if ( (FamilyTree.isNEU(node.mid) && FamilyTree.isNEU(node.fid)) || !node.ftChildrenIds.length ) {
              args.menu.remove = {
                icon: FamilyTree.icon.remove(30, 30, '#9b9b9b'),
                text: Vue.prototype.$t('tree.remove'),
                color: 'white',
              };
            } else {
              delete args.menu.remove;
            }
          }
        });

        family.nodeCircleMenuUI.on('drop', function (sender, args) {
          family.addClink(args.from, args.to)
              .draw(FamilyTree.action.update);
        });

        family.nodeCircleMenuUI.on('mouseenter', function (sender, args) {
          if ( args.menuItem.text == 'Remove node' ) {
            var node = document.querySelector('[data-n-id="' + args.from + '"]');
            node.style.opacity = 0.5;
          }
        });

        family.nodeCircleMenuUI.on('mouseout', function (sender, args) {
          const node = document.querySelector('[data-n-id="' + args.from + '"]');
          node.style.opacity = 1;
        });

        family.nodeCircleMenuUI.on('click', function (sender, args) {
          const allowed = [ 'details', 'edit', 'remove', 'connection' ].includes(args.menuItemName);
          if ( !allowed && self.limitIsOff ) {
            self.showPaymentDialog = true;
          } else {
            const node = family.getNode(args.nodeId);
            switch ( args.menuItemName ) {
              case 'husband':
                // eslint-disable-next-line no-case-declarations
                const husband = new PersonModel({
                  gender: Sex.male,
                  partnerIds: [ node.id ],
                });
                self.$emit('add-person', husband);
                self.$emit('update-person', node.id, 'partnerIds', husband.id);
                break;
              case 'wife':
                // eslint-disable-next-line no-case-declarations
                const wife = new PersonModel({
                  gender: Sex.female,
                  partnerIds: [ node.id ],
                });
                self.$emit('add-person', wife);
                self.$emit('update-person', node.id, 'partnerIds', wife.id);
                break;
              case 'son':
                // eslint-disable-next-line no-case-declarations
                const son = new PersonModel({
                  gender: 'male',
                  [node.gender === Sex.male ? 'fatherId' : 'motherId']: node.id,
                });
                if ( node.pids && node.pids.length > 0 ) {
                  son[node.gender === Sex.male ? 'motherId' : 'fatherId'] = node.pids[0];
                  self.$emit('update-person', node.pids[0].id, 'childrenIds', son.id);
                }
                self.$emit('add-person', son);
                self.$emit('update-person', node.id, 'childrenIds', son.id);

                break;
              case 'daughter':
                // eslint-disable-next-line no-case-declarations
                const daughter = new PersonModel({
                  gender: 'female',
                  [node.gender === Sex.male ? 'fatherId' : 'motherId']: node.id,
                });
                if ( node.pids && node.pids.length > 0 ) {
                  daughter[node.gender === Sex.male ? 'motherId' : 'fatherId'] = node.pids[0];
                  self.$emit('update-person', node.pids[0].id, 'childrenIds', daughter.id);
                }
                self.$emit('add-person', daughter);
                self.$emit('update-person', node.id, 'childrenIds', daughter.id);

                break;
              case 'mother':
                // eslint-disable-next-line no-case-declarations
                const mother = new PersonModel({
                  gender: 'female',
                  childrenIds: [ node.id ],
                });
                if ( !FamilyTree.isNEU(node.fid) ) {
                  mother.partnerIds.push(node.fid);
                  self.$emit('update-person', node.fid, 'partnerIds', mother.id);
                }
                self.$emit('add-person', mother);
                self.$emit('update-person', node.id, 'motherId', mother.id);
                break;
              case 'father':
                // eslint-disable-next-line no-case-declarations
                const father = new PersonModel({
                  gender: 'male',
                  childrenIds: [ node.id ],
                });
                if ( !FamilyTree.isNEU(node.mid) ) {
                  father.partnerIds.push(node.mid);
                  self.$emit('update-person', node.mid, 'partnerIds', father.id);
                }
                self.$emit('add-person', father);
                self.$emit('update-person', node.id, 'fatherId', father.id);
                break;
              case 'details':
                if ( self.isOwner ) {
                  self.toggleDetails(node.id);
                }
                break;
              case 'edit':
                if ( self.isOwner ) {
                  self.openEditForm(node.id);
                }
                break;
              case 'remove':
                if ( self.isOwner ) {
                  self.openRemoveDialog(node);
                }
                break;
              case 'connection':
                self.$emit('connection', node.id);
                break;
              default:
                break;
            }
          }
        });


        family.on('redraw', function (tree) {
          if ( self.nodes.length !== 0 && tree.visibleNodeIds.length === 0 ) {
            self.loadTree();
          }
        });

        family.onNodeClick(({ node }) => {
          self.toggleDetails(node.id);
        });

        this.tree = family;
        this.loadTree();
      },
      openRemoveDialog(personId) {
        this.personToRemove = personId;
        this.removeDialog = true;
      },
      closeRemoveDialog() {
        this.personToRemove = null;
        this.removeDialog = false;
      },
      // findInTree(personId) {
      //   return this.tree.getNode(personId);
      // },
      async removePerson() {
        const personService = new PersonService({ treeId: this.$route.params.treeId });
        await personService.removePerson(this.personToRemove);
        this.$emit('tree-updated');
        this.closeRemoveDialog();
      },
      openEditForm(personId) {
        if ( this.isOwner ) {
          this.$router.push({
            name: 'person-edit',
            params: {
              treeId: this.$route.params.treeId,
              personId,
            },
          });
        }
      },
      toggleDetails(personId) {
        if ( this.isOwner ) {
          this.$router.push({
            name: 'person-details',
            params: {
              treeId: this.$route.params.treeId,
              personId,
            },
          });
        }
      },
    },
  };
</script>

<style>
.female .bft-edit-form-header,
.female .bft-img-button {
  background-color: var(--female-color) !important;
}

.male .bft-edit-form-header,
.male .bft-img-button {
  background-color: var(--male-color) !important;
}

[data-l-id] path {
  stroke: var(--light-color) !important;
}

.b-sidebar {
  top: 70px !important;
}

.Прямоугольник_3 {
  border-width: 1px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  background-color: rgb(255, 146, 0);
  box-shadow: inset 0px 34px 45.5px 24.5px rgba(0, 0, 0, 0.004), inset 0px 12px 29px 0px rgba(0, 0, 0, 0.35);
  position: absolute;
  left: 215px;
  top: 370px;
  width: 1208px;
  height: 937px;
  z-index: 3;
}


</style>
