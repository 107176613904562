<template>
  <!--      <v-col-->
  <!--          cols="12"-->
  <!--          sm="6"-->
  <!--      >-->
  <!--        <v-select-->
  <!--            :items="PeriodTypesOptions"-->
  <!--            :value="value.type"-->
  <!--            @input="onTypeInput"-->
  <!--            :label="label"-->
  <!--        ></v-select>-->
  <!--      </v-col>-->
  <date :label="label"
        :is-range="isRange"
        :value="value[key]"
        @input="onDateInput"></date>
</template>

<script>
  import { PeriodTypes } from '@/models/helpers';
  import Date from '../../../components/date';

  export default {
    name: 'DatePicker',
    components: { Date },
    data() {
      return {
        PeriodTypes,
        showCalendar: false,
      };
    },
    props: {
      value: {
        type: Object,
        default: () => ({
          type: PeriodTypes.exactly,
          date: null,
        }),
      },
      label: {
        type: String,
      },
    },
    computed: {
      isRange() {
        return [ PeriodTypes.between, PeriodTypes.period ].includes(this.value.type);
      },
      key() {
        return this.isRange ? 'dateRange' : 'date';
      },
      PeriodTypesOptions() {
        return Object.values(PeriodTypes)
            .map(value => ({ text: this.$t(`periodType.${ value }`, value) }));
      },
    },
    methods: {
      onTypeInput(type) {
        this.$emit('input', {
          ...this.value,
          type,
        });
      },
      onDateInput(value) {
        this.$emit('input', {
          ...this.value,
          [this.key]: value,
        });
      },
    },
  };
</script>

<style>
.v-picker__title {
  background-color: var(--primary-color);
}
</style>
