<template>
  <v-dialog
      class="min-height-500"
      v-model="dialog"
      max-width="800"
      persistent
      @input="onToggle"
  >
    <template v-if="!person">
      <v-card class="loading-state-wrapper">
        <v-progress-circular :size="60"
                             color="amber"
                             indeterminate
        ></v-progress-circular>
      </v-card>
    </template>
    <person-form v-else v-model="person" :disabled-connections="true">
      <template v-slot:title>
        <v-card-title>
          <span>{{ $t('forms.edit') }} {{ person?.name }}</span>
        </v-card-title>
      </template>
      <template v-slot:actions>
        <div class="pa-3">
          <v-spacer></v-spacer>

          <v-btn
              color="darken-1"
              text
              class="mr-3"
              @click="cancel"
          >
            {{ $t('forms.cancel') }}
          </v-btn>

          <v-btn
              color="primary"
              @click="save"
          >
            {{ $t('forms.save') }}
          </v-btn>
        </div>
      </template>
    </person-form>
  </v-dialog>
</template>

<script>
  import PersonModel from '@/models/person.model';
  import PersonForm from '@/modules/person/person-form';
  import PersonService from '@/services/person.service';

  export default {
    name: 'person-form-dialog',
    components: { PersonForm },
    data() {
      return {
        person: null,
        personService: null,
        dialog: true,
      };
    },
    methods: {
      onToggle() {
        if ( !this.dialog ) {
          this.$router.push({
            name: 'person-details',
            params: {
              treeId: this.$route.params.treeId,
              personId: this.$route.params.personId,
            },
          });
        }
      },
      cancel() {
        this.dialog = false;
        this.onToggle();
      },
      save() {
        this.personService.setPerson(this.person.getPayload())
            .then(() => {
              this.dialog = false;
              this.onToggle();
            })
            .catch(console.log);
      },
    },
    mounted() {
      this.personService = new PersonService({
        userId: this.$route.params.globalUserId,
        treeId: this.$route.params.treeId,
      });
      this.personService.getPerson(this.$route.params.personId)
          .then((person) => {
            this.person = new PersonModel(person);
          });
    },
  };
</script>

<style>
.loading-state-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}
</style>
