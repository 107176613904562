<template>
  <div>
    <plans-dialog-stopper :dialog="showPaymentDialog" @close="showPaymentDialog = false;"></plans-dialog-stopper>

    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot :attrs="attrs" :on="on" name="toggle-button">
          <v-fab-transition>
            <v-btn
                bottom
                class="v-btn--example"
                color="primary"
                dark
                fab
                large
                left
                v-bind="attrs"
                v-on="on"
                @click="check"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-fab-transition>
        </slot>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('addPhoto') }}
        </v-card-title>

        <v-card-text v-if="isLoading">
          <div class="loading-state-wrapper">
            <v-progress-circular :size="60"
                                 color="amber"
                                 indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  counter
                  maxlength="50"
                  v-model="photo.name"
                  :label="$t('forms.name')"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <date v-model="photo.date"
                    :label="$t('forms.photoDate')"></date>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="photo.description"
                  counter
                  maxlength="450"
                  :label="$t('forms.description')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                  v-model="file"
                  show-size
                  accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp"
                  truncate-length="20"
              ></v-file-input>
            </v-col>
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="!file || limitIsOff"
              color="primary"
              text
              @click="upload"
          >
            {{ $t(`forms.upload`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import PhotoModel from '@/models/photo.model';
  import Date from '@/components/date.vue';
  import PlansDialogStopper from '@/modules/profile/plans-dialog-2';
  import toast from '@/services/helpers/toast';
  import PhotoStorageService from '@/services/photo-storage.service';
  import PhotoService from '@/services/photo.service';
  import { mapGetters } from 'vuex';

  export default {
    name: 'photo-add-modal',
    components: {
      PlansDialogStopper,
      Date,
    },
    props: {
      presetPhoto: {
        type: Object,
        default: () => ({}),
      },
      isAvatar: {
        type: Boolean,
        default: false,
      },
      personId: {
        type: String,
        default: null,
      },
    },
    mounted() {
      this.photo = new PhotoModel(this.presetPhoto);
    },
    computed: {
      ...mapGetters([ 'limitIsOff' ]),
    },
    data() {
      return {
        dialog: false,
        file: null,
        photo: new PhotoModel(),
        storageService: new PhotoStorageService(),
        photoService: new PhotoService(),
        isLoading: false,
        showPaymentDialog: false,
      };
    },
    methods: {
      async upload() {
        this.isLoading = true;
        try {
          const data = {
            id: this.presetPhoto.id || this.photo.id,
            name: this.presetPhoto.name || this.photo.name,
            date: this.presetPhoto.date || this.photo.date,
            description: this.presetPhoto.description || this.photo.description,
            boxes: this.presetPhoto.boxes?.length && this.presetPhoto.boxes || this.photo.boxes,
            story: this.presetPhoto.story || this.photo.story,
          };
          await this.storageService.addImageToFirebaseStorage({
            ...data,
            file: this.file,
          }, this.isAvatar, this.personId, this.$route.params.treeId);

          this.closeDialog();
          this.$emit('upload-finished', data.id);
        } catch ( e ) {
          console.log(e);
          toast('error', `alerts.errors.${ e.code }`);

          console.log(e);
        } finally {
          this.isLoading = false;
        }
      },
      closeDialog() {
        this.dialog = false;
        this.photo = new PhotoModel();
        this.file = null;
      },
      check() {
        if ( this.limitIsOff ) {
          this.showPaymentDialog = true;

          setTimeout(() => {
            this.dialog = false;
          }, 0);
        }
      },
    },
  };
</script>
