import App from '@/App.vue';
import AuthService from '@/auth.service';
import { FirebaseApp } from '@/firebase';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import toast from '@/services/helpers/toast';
import store from '@/store';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { getAnalytics } from 'firebase/analytics';
import 'firebaseui/dist/firebaseui.css';
import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Vuelidate from 'vuelidate';
import config from '../config';


Vue.use(Toast, {});
Vue.use(Vuelidate);
Vue.use(CKEditor);

const {
    APPLICATION_MODE,
} = config;

Vue.config.productionTip = APPLICATION_MODE === 'production';

const app = FirebaseApp;

const authService = new AuthService({
    store,
    router,
});

Vue.prototype.$store = store;
Vue.prototype.$authService = authService;
store.dispatch('toggleLoading', true)
    .then(() => {
    });
/* END AUTHORISATION */

const analytics = getAnalytics(app);
console.log(analytics);
Vue.prototype.$t = (key, params) => vuetify.framework.lang.translator(`$vuetify.${ key }`, params);
try {
    new Vue({
        vuetify,
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
} catch ( e ) {
    toast('error', `alerts.errors.${ e.code }`);
}
