<template>
  <div>
    <v-autocomplete
        :chips="chips"
        :disabled="!people.length"
        :items="people"
        :label="label"
        :loading="isLoading"
        :multiple="multiple"
        :value="value"
        item-text="fullName"
        item-value="id"
        @input="$emit('input', $event)"
    >
      <template v-slot:selection="data">
        <v-chip
            :input-value="data.selected"
            close
            v-bind="data.attrs"
            @click="data.select"
            @click:close="removeItem(data.item)"
        >
          <v-avatar left>
            <v-img :src="data.item.avatar"></v-img>
          </v-avatar>
          {{ data.item.fullName }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar>
            <img :src="data.item.avatar">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
  import toast from '@/services/helpers/toast';
  import PersonService from '@/services/person.service';

  export default {
    name: 'people-autocomplete-input',
    props: {
      value: {
        type: [ Array, String ],
        default: () => [],
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      chips: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
        default: '',
      },
      filter: {
        type: Function,
        default: () => true,
      },
    },
    data() {
      return {
        people: [],
        PersonService: null,
        isLoading: false,
      };
    },
    mounted() {
      this.PersonService = new PersonService({
        userId: this.$route.params.globalUserId,
        treeId: this.$route.params.treeId,
      });
      this.fetchPeople();
    },
    methods: {
      async fetchPeople() {
        this.isLoading = true;
        try {
          const people = await this.PersonService.getPeople();
          this.people = people.filter(this.filter);
        } catch ( e ) {
              toast('error', `alerts.errors.${e.code}`);

console.log(e);
        } finally {
          this.isLoading = false;
        }
      },
      removeItem(item) {
        if ( !this.multiple ) {
          this.$emit('input', null);
          return;
        }
        const index = this.value.indexOf(item.id);
        if ( index >= 0 ) {
          const value = this.value;
          value.splice(index, 1);
          this.$emit('input', value);
        }
      },
    },
  };
</script>
