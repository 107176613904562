import { FirebaseAuth, FirebaseDB } from '@/firebase';
import { updateProfile } from 'firebase/auth';
import { addDoc, doc, updateDoc } from 'firebase/firestore';

export default class UserModel {
    constructor(user) {
        if ( user ) {
            const {
                displayName,
                email,
                phoneNumber,
                photoURL,
                providerId,
                uid,
            } = user;
            this.displayName = displayName;
            this.email = email;
            this.phoneNumber = phoneNumber;
            this.photoURL = photoURL;
            this.providerId = providerId;
            this.uid = uid;
        }
    }

    get userCollection() {
        return doc(FirebaseDB, 'users');
    }

    get userRef() {
        return doc(FirebaseDB, 'users', this.uid);
    }

    async update(fields) {
        await updateProfile(FirebaseAuth.currentUser, fields);
    }

    async createInstance() {
        await addDoc(this.userRef, { id: this.uid });
    }

    async setUserId(id) {
        await updateDoc(this.userRef, { id: id });
    }
}

