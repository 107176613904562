import { FactTypes, PeriodTypes, Sex } from '@/models/helpers';

export default {
    access: {
        myPlan: 'Мой тариф',
        free: 'Бесплатный',
        paid: 'Платный',
        paidDescription: 'У вас подключен тариф "Платный" до {0}',
        trees: {
            quantity: 'Количество деревьев',
        },
        people: {
            quantity: 'Количество персон в дереве',
        },
        photo: {
            quantity: 'Количество фотографий на пользователя',
        },
    },
    alerts: {
        error: 'Произошла ошибка. Попробуйте обновить страницу или повторить еще раз позже.',
        errors: {
            unknown: 'Произошла неизвестная ошибка. Попробуйте обновить страницу или повторить еще раз позже.',
            undefined: 'Произошла неизвестная ошибка. Попробуйте обновить страницу или повторить еще раз позже.',
            unauthorized: 'Похоже, вы еще не зарегистрированы',
            'storage/unauthorized': 'Возможно, вы выбрали неверный формат данных. Загружать можно лишь изображения JPG и PNG.',
            'auth/email-already-in-use': 'Адрес электронной почты уже используется',
            'auth/user-not-found': 'Пользователь не найден',
            'auth/invalid-email': 'Неверно заполнено поле e-mail',
            'auth/wrong-password': 'Неверный пароль',
            'auth/missing-email': 'Необходимо указать адрес электронной почты',
            'user-uid-already-exists': 'Пользователь с таким идентификатором уже существует',
            'auth/email-already-exists': 'Пользователь с таким адресом электронной почты уже существует',
            'user-email-already-exists': 'Пользователь с таким адресом электронной почты уже существует',
            emailWrongFormat: 'Неверный формат адреса электронной почты',
            emailRequired: 'Адрес электронной почты является обязательным полем',
            passwordNotMatch: 'Пароль и подтверждение пароля не совпадают',
            passwordRequired: 'Пароль является обязательным полем',
            passwordMinLength: 'Пароль должен содержать не менее 6 символов',
            privacyPolicyIsNotChecked: 'Необходимо принять условия пользовательского соглашения',
            uidRequired: 'Идентификатор пользователя является обязательным полем',
            uidWrongFormat: 'Неверный формат идентификатора пользователя',
            firstName: {
                required: 'Имя является обязательным полем',
            },
            lastName: {
                required: 'Фамилия является обязательным полем',
            },
            password: {
                minLength: 'Пароль должен содержать не менее 6 символов',
                required: 'Пароль является обязательным полем',
            },
        },
        person: {
            created: 'Профиль успешно создан',
            removed: 'Профиль человека успешно удален',
            updated: 'Профиль успешно обновлен',
        },
        photo: {
            created: 'Фотография успешно загружена',
            removed: 'Фотография успешно удалена',
            updated: 'Фотография успешно обновлена',
        },
        story: {
            created: 'История успешно создана',
            removed: 'История успешно удалена',
            updated: 'История успешно обновлена',
        },
        success: {
            restorePasswordEmailSent: 'Письмо для восстановления пароля отправлено на указанный адрес электронной почты',
        },
        tree: {
            created: 'Дерево успешно создано',
            removed: 'Дерево успешно удалено',
            updated: 'Дерево успешно обновлено',
        },
        fact: {
            created: 'Факт успешно создан',
            removed: 'Факт успешно удален',
            updated: 'Факт успешно обновлен',
        },
    },
    addFirstNode: 'Начать',
    addPhoto: 'Добавить фотографию',
    close: 'Закрыть',
    contacts: {
        title: 'Наши контакты',
    },
    createTree: 'Создать дерево',
    datePicker: {
        nextMonthAriaLabel: 'Следующий месяц',
        prevMonthAriaLabel: 'Предыдущий месяц',
    },
    editTree: 'Редактировать дерево',
    email: 'Адрес электронной почты',
    errors: {
        confirmPassword: {
            minLength: 'Подтверждение пароля должно содержать не менее 6 символов',
            notMatch: 'Пароль и подтверждение пароля не совпадают',
            required: 'Подтверждение пароля является обязательным полем',
        },
        treeDescription: {
            required: 'Описание дерева является обязательным полем',
        },
        treeName: {
            required: 'Имя дерева является обязательным полем',
        },
    },
    explore: {
        dbs: 'Открытые базы данных',
        description: 'Добро пожаловать в раздел исследования! Здесь вы можете найти метрические книги, шаблоны заявлений и другие полезные материалы, которые помогут вам в поиске информации о вашей семье.',
        marathon: 'Марафон',
        metricBooks: {
            description: 'Здесь будет описание этого раздела',
        },
        metrics: 'Метрические книги',
        search: 'Поиск по открытым БД',
        title: 'Исследование',
        links: 'Полезные ссылки',
    },
    facts: {
        label: 'Факты',
        add: 'Добавить факт',
        country: 'Страна',
        date: 'Дата',
        delete: 'Удалить факт',
        description: 'Описание',
        edit: 'Редактировать факт',
        members: 'Участники',
        place: 'Место',
        title: 'Название',
        type: 'Тип',
        types: {
            [FactTypes.birth]: 'Рождение',
            [FactTypes.burial]: 'Похороны',
            [FactTypes.childBirth]: 'Рождение ребенка',
            [FactTypes.childDeath]: 'Смерть ребенка',
            [FactTypes.christening]: 'Крещение',
            [FactTypes.custom]: 'Другое',
            [FactTypes.death]: 'Смерть',
            [FactTypes.divorce]: 'Развод',
            [FactTypes.education]: 'Образование',
            [FactTypes.employment]: 'Работа',
            [FactTypes.immigration]: 'Иммиграция',
            [FactTypes.marriage]: 'Свадьба',
            [FactTypes.militaryAward]: 'Военная награда',
            [FactTypes.militaryService]: 'Военная служба',
            [FactTypes.placeOfResidence]: 'Место жительства',
            [FactTypes.militaryRank]: 'Воинское звание',
            [FactTypes.adopted]: 'Усыновление',
            [FactTypes.adultChristening]: 'Взрослое крещение',
            [FactTypes.barMitzvah]: 'Бар мицва',
            [FactTypes.basMitzvah]: 'Бат мицва',
            [FactTypes.cremation]: 'Кремация',
            [FactTypes.graduated]: 'Выпуск',
        },
        factLabels: {
            [FactTypes.birth]: {
                [Sex.female]: 'родилась',
                [Sex.male]: 'родился',
            },
            [FactTypes.death]: {
                [Sex.female]: 'умерла',
                [Sex.male]: 'умер',
            },
            [FactTypes.childBirth]: {
                [Sex.female]: 'родился ребенок',
                [Sex.male]: 'родился ребенок',
            },
            [FactTypes.childDeath]: {
                [Sex.female]: 'умер ребенок',
                [Sex.male]: 'умер ребенок',
            },
            [FactTypes.christening]: {
                [Sex.female]: 'крещена',
                [Sex.male]: 'крещен',
            },
            [FactTypes.burial]: {
                [Sex.female]: 'похоронена',
                [Sex.male]: 'похоронен',
            },
            [FactTypes.divorce]: {
                [Sex.female]: 'развелась',
                [Sex.male]: 'развелся',
            },
            [FactTypes.marriage]: {
                [Sex.female]: 'вышла замуж',
                [Sex.male]: 'женился',
            },
            [FactTypes.militaryAward]: {
                [Sex.female]: 'награждена по военной службе',
                [Sex.male]: 'награжден по военной службе',
            },
            [FactTypes.militaryService]: {
                [Sex.female]: 'служила',
                [Sex.male]: 'служил',
            },
            [FactTypes.placeOfResidence]: {
                [Sex.female]: 'проживала',
                [Sex.male]: 'проживал',
            },
            [FactTypes.militaryRank]: {
                [Sex.female]: 'присвоено звание',
                [Sex.male]: 'присвоено звание',
            },
            [FactTypes.adopted]: {
                [Sex.female]: 'удочерена',
                [Sex.male]: 'усыновлен',
            },
            [FactTypes.adultChristening]: {
                [Sex.female]: 'крещена во взрослом возрасте',
                [Sex.male]: 'крещен во взрослом возрасте',
            },
            [FactTypes.barMitzvah]: {
                [Sex.male]: 'Бар Мицва',
            },
            [FactTypes.basMitzvah]: {
                [Sex.female]: 'Бат Мицва',
            },
            [FactTypes.cremation]: {
                [Sex.female]: 'кремирована',
                [Sex.male]: 'кремирован',
            },
            [FactTypes.education]: {
                [Sex.female]: 'обучалась',
                [Sex.male]: 'обучался',
            },
            [FactTypes.employment]: {
                [Sex.female]: 'работала',
                [Sex.male]: 'работал',
            },
            [FactTypes.graduated]: {
                [Sex.female]: 'окончила учебу',
                [Sex.male]: 'окончил учебу',
            },
            [FactTypes.immigration]: {
                [Sex.female]: 'переехала',
                [Sex.male]: 'переехал',
            },
        },
    },
    firstName: 'Имя',
    forms: {
        actions: 'Действия',
        cancel: 'Отмена',
        confirm: 'Подтвердить',
        confirmationType: 'Для потверждения введите название <b>{0}</b> в поле ниже',
        continue: 'Продолжить',
        description: 'Описание',
        edit: 'Редактировать',
        id: 'ID',
        isPublic: 'Сделать дерево публичным',
        name: 'Название',
        navigateToStory: 'Перейти к истории',
        photoDate: 'Когда была сделана фотография?',
        remove: 'Удалить',
        removeConfirmation: 'Подтвердить удаление {0}',
        removeConfirmationText: 'Это действие нельзя будет отменить. Вы уверены, что хотите удалить <b>{0}</b>?',
        removeEntities: {
            story: 'истории',
            tree: 'дерева',
        },
        save: 'Сохранить',
        saveChanges: 'Сохранить изменения',
        treeId: 'Уникальный ID вашего дерева',
        upload: 'Загрузить',
    },
    gedcom: {
        checkTheDataToImport: 'Проверьте данные для импорта',
        chooseYourself: 'Выберите себя',
        doNotClose: 'Не закрывайте эту страницу, пока импорт не будет завершен и вы не подтвердите результаты',
        import: 'Импортировать GEDCOM файл',
        startImport: 'Начать импорт',
    },
    lastName: 'Фамилия',
    login: 'Войти',
    logout: 'Выйти',
    marathon: {
        description: 'Привет! Меня зовут Даша. Я создала этот марафон, чтобы помочь вам начать исследование своего рода. В течение 2 месяцев, а может быть и больше, я буду снимать и выкладывать видео-ролики, которые помогут вам собрать информацию о своей семье. В конце марафона вы получите готовое дерево, которое можно будет поделиться с родственниками.',
        title: 'Марафон',
        toBeContinued: 'Продолжение следует...',
        introduction: 'Введение',
        'open-dbs': 'Открытые источники',
        metrics: 'Метрические книги',
        jewish: 'Еврейский поиск',
    },
    menu: {
        books: 'Книга',
        explore: 'Исследование',
        marathon: 'Марафон',
        myTrees: 'Мои деревья',
        photos: 'Фотографии',
        stories: 'Истории',
        trees: 'Деревья',
        importTree: 'Импортировать дерево .gedcom',
    },
    noDataText: 'Нет данных',
    noPeopleInTheTree: 'В этом дереве пока нет людей',
    noPeopleInTheTreeDescription: 'Добавьте людей в дерево, чтобы начать исследование',
    noPhotos: 'У вас пока нет фотографий',
    noPhotosDescription: 'Сохраните ваш семейный фотоальбом вместе с историей',
    noTrees: 'У вас пока нет деревьев',
    password: 'Пароль',
    people: {
        label: 'Люди',
    },
    periodType: {
        [PeriodTypes.exactly]: 'Точно',
        [PeriodTypes.about]: 'Около',
        [PeriodTypes.before]: 'До',
        [PeriodTypes.after]: 'После',
        [PeriodTypes.between]: 'Между',
        [PeriodTypes.period]: 'Период',
    },
    person: {
        addRelative: 'Добавить родственника',
        alive: 'Жив',
        addAvatar: 'Добавить аватар',
        birthCountry: 'Страна рождения',
        birthDate: 'Дата рождения',
        birthPlace: 'Место рождения',
        changeAvatar: 'Изменить аватар',
        changeConnections: 'Изменить связи',
        changesToBeApplied: 'Изменения, которые также будут применены после подтверждения',
        children: 'Дети',
        confirmChangeConnections: 'Подтвердить изменения связей',
        connectionPerson: 'Связать с человеком',
        connections: {
            toPersonWillBeAppliedChildren: 'Дети <b>{0}</b>',
            toPersonWillBeAppliedFather: 'Отец <b>{0}</b>',
            toPersonWillBeAppliedMother: 'Мать <b>{0}</b>',
            toPersonWillBeAppliedPartners: 'Партнеры <b>{0}</b>',
        },
        connectionType: 'Связь',
        deathCountry: 'Страна смерти',
        deathDate: 'Дата смерти',
        deathPlace: 'Место смерти',
        email: 'Email',
        emailInvalid: 'Неверный email',
        father: 'Отец',
        female: 'Женщина',
        firstName: 'Имя',
        fullName: 'Полное имя',
        lastName: 'Фамилия',
        locationIsNotSet: 'Место не указано',
        maidenName: 'Девичья фамилия',
        male: 'Мужчина',
        middleName: 'Отчество',
        mother: 'Мать',
        noChanges: 'Нет изменений',
        partners: 'Партнеры',
        periodIsNotSet: 'Нет даты',
        pleaseCarefullyChangeConnections: 'Пожалуйста, внимательно проверьте связи, которые вы хотите изменить',
        storyLine: 'История жизни',
        years: 'лет',
    },
    personalInformation: 'Личная информация',
    photos: {
        addPhoto: 'Добавить фотографию',
        connectToStory: 'Прикрепить к истории',
        detectBox: 'Выделите область на фотографии и выберите из списка человека',
        goToPhotoPage: 'Перейти на страницу фотографии',
        labelPerson: 'Отметить человека на фотографии',
        name: 'Название',
        noLabels: 'Пока нет отметок',
        noName: 'Без названия',
        onPhoto: 'На фотографии',
        storyName: 'Название истории',
        treeName: 'Дерево',
    },
    plans: {
        title: 'Тарифы',
        free: 'Бесплатный',
        paid: 'Платный',
        pricePerMonth: 'Цена (за 1 мес)',
        pricePerYear: 'Цена (за 1 год)',
        treesQuantity: 'Количество деревьев',
        peopleQuantity: 'Персон в дереве',
        photoQuantity: 'Фотографий',
    },
    policy: 'Политика конфиденциальности',
    policyToCheck: 'Политику конфиденциальности',
    privacyPolicyCheck: 'Я принимаю',
    profile: 'Профиль',
    requestRestore: 'Запросить восстановление',
    restorePassword: 'Восстановить пароль',
    search: 'Поиск',
    searchNoResults: 'Ничего не найдено',
    searchResults: 'Результаты поиска',
    searchResultsItem: {
        person: 'Человек',
        saveToPerson: 'Прикрепить к человеку',
        tree: 'Дерево',
    },
    signInWithGoogle: 'Войти с помощью Google',
    signUp: 'Зарегистрироваться',
    slogan: 'Сохрани историю своей семьи!',
    soon: 'Скоро',
    stories: {
        label: 'Истории',
        title: 'Истории',
        noStories: 'Для этого дерева пока нет историй',
        name: 'Название истории',
        treeName: 'Дерево',
        treeNameDescription: 'Выберите для какого из деревьев вы хотите просмотреть истории',
        addStory: 'Добавить историю',
        noTitle: 'История без названия',
        period: 'Период',
        tellYourStory: 'Расскажите свою историю...',
        memberIds: 'Участники истории',
        photoIds: 'Фотографии',
        noPhotos: 'Нет фотографий',
        noMembers: 'Нет участников',
    },
    subSlogan: 'Построй семейное древо, исследуй историю своего рода',
    switchToPaid: 'Перейти на платный тариф',
    terms: 'Пользовательское соглашение',
    tree: {
        addDaughter: 'Добавить дочь',
        addFather: 'Добавить отца',
        addHusband: 'Добавить супруга',
        addMother: 'Добавить маму',
        addSon: 'Добавить сына',
        addWife: 'Добавить супругу',
        connection: 'Связь',
        details: 'Детали',
        edit: 'Редактировать',
        export: {
            pdf: 'Экспортировать в PDF',
            png: 'Экспортировать в PNG',
            svg: 'Экспортировать в SVG',
            csv: 'Экспортировать в CSV',
            json: 'Экспортировать в JSON',
        },
        firstPersonCreationForm: {
            continue: 'Продолжить',
            step1: 'Добавьте себя',
            step2: 'Добавьте свою маму',
            step3: 'Добавьте своего отца',
        },
        quantity: 'В вашем дереве сейчас <b>{0}</b> человек',
        remove: 'Удалить',
        removePerson: 'Удалить человека',
        removePersonDescription: 'Вы уверены, что хотите удалить этого человека из дерева?',
        searchPlaceholder: 'Поиск',
        selectTree: 'Выберите дерево',
    },
    uid: 'Уникальный ID пользователя',
    uiLanguage: 'Язык интерфейса',
};
