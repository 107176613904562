<template>
  <v-card width="100%" class="pa-7 mx-auto mt-6" elevation="10">
    <v-container>
      <v-card-text>
        <h2 class="mb-4">{{ $t('restorePassword') }}</h2>
        <v-alert dense outlined type="error" v-if="error">{{ $t(`alerts.errors.${ error }`) }}</v-alert>
        <form>
          <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              :label="$t('email')"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
          ></v-text-field>
        </form>
      </v-card-text>
      <v-card-actions class="home_form_actions">
        <v-btn block color="primary" class="" @click="restore">{{ $t('requestRestore') }}</v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
  import { FirebaseAuth } from '@/firebase';
  import toast from '@/services/helpers/toast';
  import { required, email } from 'vuelidate/lib/validators';
  import { sendPasswordResetEmail } from 'firebase/auth';

  export default {
    name: 'restore-password',
    data() {
      return {
        email: '',
        error: null,
      };
    },
    validations: {
      email: {
        required,
        email,
      },
    },
    computed: {
      emailErrors() {
        const errors = [];
        if ( !this.$v.email.$dirty ) {
          return errors;
        }
        !this.$v.email.email && errors.push(this.$t('alerts.errors.emailWrongFormat'));
        !this.$v.email.required && errors.push(this.$t('alerts.errors.emailRequired'));
        return errors;
      },
    },
    methods: {
      restore() {
        sendPasswordResetEmail(FirebaseAuth, this.email)
            .then(() => {
              this.$router.push({ name: 'login' });
              toast('success', 'alerts.success.restorePasswordEmailSent');
            })
            .catch((error) => {
              this.error = error.code;
            });
      },
    },
  };
</script>
