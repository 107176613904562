import { FirebaseAuth } from '@/firebase';
import StoryModel from '@/models/story.model';
import EntityService from '@/services/entity.service';
import toast from '@/services/helpers/toast';
import PersonService from '@/services/person.service';
import PhotoService from '@/services/photo.service';

export default class StoryService extends EntityService {
    _entityModel = StoryModel;

    constructor({
        userId = FirebaseAuth.currentUser && FirebaseAuth.currentUser.uid,
        treeId,
    }) {
        super(userId);
        this._entity = `trees/${ treeId }/stories`;
        this._personService = new PersonService({
            userId,
            treeId,
        });
        this._photoService = new PhotoService();
    }

    async getStories() {
        const stories = await this.getAll();
        return stories;
    }

    async getStory(id) {
        const story = await this.getDoc(id);
        return story ? new this._entityModel(story) : null;
    }

    async createStory(data, notification = true) {
        try {
            await this.setDoc(data);
            const createdStory = await this.getStory(data.id);
            await this.synchronizeWithPerson(undefined, createdStory);
            if ( notification ) {
                toast('success', 'alerts.story.created');
            }
            return createdStory;
        } catch ( e ) {
            console.log(e);
            toast('error', `alerts.errors.${ e.code }`);

        }
    }

    async removeStory(id) {
        try {
            const story = await this.getStory(id);
            await this.removeDoc(id);
            await this.synchronizeWithPerson(story);
            await this.synchronizeWithPhoto(story);
            toast('success', 'alerts.story.removed');
            return story;
        } catch ( e ) {
            toast('error', `alerts.errors.${ e.code }`);

        }
    }

    async updateStory(data) {
        try {
            const initialStory = await this.getStory(data.id);
            await this.updateDoc(data.id, data);
            const story = await this.getStory(data.id);
            await this.synchronizeWithPerson(initialStory, story);
            toast('success', 'alerts.story.updated');
            return story;
        } catch ( e ) {
            console.log(e);
            toast('error', `alerts.errors.${ e.code }`);

        }
    }

    async synchronizeWithPerson(initialStory, story) {
        // member was in the story and now is not
        if ( initialStory ) {
            await Promise.all(initialStory.memberIds.filter(id => !story || !story.memberIds.includes(id))
                .map(async memberIdToRemove => {
                    const person = await this._personService.getPerson(memberIdToRemove);
                    await this._personService.updatePerson(memberIdToRemove,
                        { storiesIds: person.storiesIds.filter(id => id !== initialStory.id) }, false);
                }));
        }

        if ( story ) {
            // member was not in the story and now is
            await Promise.all(story.memberIds.filter(id => !initialStory || !initialStory.memberIds.includes(id))
                .map(async memberIdToAdd => {
                    const person = await this._personService.getPerson(memberIdToAdd);
                    await this._personService.updatePerson(memberIdToAdd,
                        { storiesIds: [ ...person.storiesIds, story.id ] }, false);
                }));
        }
    }

    async synchronizeWithPhoto(initialStory) {
        // if story was removed
        await Promise.all(initialStory.photoIds.map(async photoId => {
            const photo = await this._photoService.getPhoto(photoId);
            return this._photoService.setPhoto({
                ...photo,
                story: {
                    treeId: null,
                    storyId: null,
                },
            });
        }));
    }
}
