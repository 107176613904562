<template>
  <v-container>
    <v-card>
      <v-card-text class="pa-6">
        <h3>{{ $t('search') }}</h3>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="search.surname" :label="$t('person.lastName')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="search.name" :label="$t('person.firstName')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="search.middleName" :label="$t('person.middleName')"></v-text-field>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn color="primary" :disabled="noRequest" @click="startSearch">{{ $t('search') }}</v-btn>
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="searchStarted">
      <v-card-text class="mt-7 pa-6" v-if="searchInProgress">
        <div class="search-in-progress">
          <v-progress-circular :size="60"
                               color="primary"
                               indeterminate
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-card-text class="pa-6 mt-7" v-else>
        <h3>{{ $t('searchResults') }}</h3>
        <v-list three-line v-if="results">
          <v-list-item-group v-if="results.length">
            <v-list-item v-for="(result, index) in results" :key="`result-${index}`">
              <v-list-item-avatar>
                <img class="object-fit-cover" :src="avatars[result.source]" :alt="result.source">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center justify-space-between">
                  <div>{{ result.title }}</div>
                </v-list-item-title>
                <v-list-item-subtitle v-text="result.description"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <a :href="result.link" target="_blank">
                  <v-btn icon>
                    <v-icon>open_in_new</v-icon>
                  </v-btn>
                </a>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           v-on="on"
                           icon>
                      <v-icon>bookmark</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ $t('searchResultsItem.saveToPerson') }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                                v-model="saveToPersonForm.treeId"
                                :auto-select-first="true"
                                :items="trees"
                                :label="$t('searchResultsItem.tree') + '*'"
                                :loading="!trees.length"
                                item-text="name"
                                item-value="id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <persons-select :disabled="!saveToPersonForm.treeId"
                                            :label="$t('searchResultsItem.person') + '*'"
                                            v-model="saveToPersonForm.personId"
                                            :tree-id="saveToPersonForm.treeId"
                                            :multiple="false"></persons-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          @click="dialog = false"
                      >
                        {{ $t('forms.cancel') }}
                      </v-btn>
                      <v-btn
                          color="primary"
                          @click="onSaveToPersonForm(result)"
                      >
                        {{ $t('forms.save') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-item v-else>
            <v-list-item-content>
              <v-list-item-title>{{ $t('searchNoResults') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import PersonsSelect from '@/components/persons-select';
  import Integrations from '@/modules/explore/integrations';
  import apiService from '@/services/api.service';
  import PersonService from '@/services/person.service';
  import TreeService from '@/services/tree.service';

  export default {
    name: 'search-page',
    components: { PersonsSelect },
    data() {
      return {
        dialog: false,
        Integrations,
        search: {
          surname: '',
          name: '',
          middleName: '',
        },
        results: [],
        trees: [],
        searchStarted: false,
        searchInProgress: false,
        saveToPersonForm: {
          treeId: null,
          personId: null,
        },
        avatars: {
          [Integrations.BB]: require('@/assets/images/bessmertny-barak.jpeg'),
          [Integrations.OpenWikiRu]: require('@/assets/images/olw-ru.png'),
          [Integrations.OpenWikiUa]: require('@/assets/images/olw-ua.png'),
          [Integrations.OpenWikiBy]: require('@/assets/images/olw-by.png'),
          [Integrations.OpenWikiGe]: require('@/assets/images/olw-ge.png'),
          [Integrations.PN]: require('@/assets/images/pamyat-naroda.jpeg'),
        },
      };
    },
    mounted() {
      const userId = this.$store.getters['user'].uid;
      const treeService = new TreeService(userId);
      treeService.getTrees()
          .then((data) => {
            this.trees = data;
          });
    },
    computed: {
      noRequest() {
        return !this.search.surname && !this.search.name && !this.search.middleName;
      },
    },
    methods: {
      async onSaveToPersonForm(result) {
        try {
          const personService = new PersonService({ treeId: this.saveToPersonForm.treeId });
          const person = await personService.getPerson(this.saveToPersonForm.personId);
          await personService.updatePerson(this.saveToPersonForm.personId, { sources: [ ...person.sources, result ] });
        } catch ( e ) {
          console.error(e);
        } finally {
          this.dialog = false;
        }
      },
      async startSearch() {
        this.searchStarted = true;
        this.searchInProgress = true;
        const query = `${ this.search.surname } ${ this.search.name } ${ this.search.middleName }`;
        try {
          this.results = await apiService.search({
            fullName: query,
            lastName: this.search.surname,
            firstName: this.search.name,
            middleName: this.search.middleName,
          });
        } catch ( e ) {
          console.error(e);
        } finally {
          this.searchInProgress = false;
        }
      },
      openConnectDialog() {
      },
    },
  };
</script>

<style>
.search-in-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.object-fit-cover {
  object-fit: cover;
}
</style>
