<template>
  <v-container>
    <h1>{{ $t('stories.title') }}</h1>
    <v-row class="mt-12">
      <v-col cols="3">
        <v-card
            elevation="12"
        >
          <v-navigation-drawer
              floating
              permanent
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ $t('stories.treeName') }}
                </v-list-item-title>
                <!--                <v-list-item-subtitle>-->
                <!--                  {{ $t('stories.treeNameDescription') }}-->
                <!--                </v-list-item-subtitle>-->
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list v-if="!isTreesLoading && trees.length"
                    dense
            >
              <v-list-item
                  v-for="tree in trees"
                  :key="tree.id"
                  link
                  @click="onTreeSelected(tree.id)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ tree.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <template v-if="!isTreesLoading && !trees.length">
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('noTrees') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="justify-center">
                  <v-list-item-content>
                    <tree-form-dialog :tree-list="trees" @save="fetchTrees"></tree-form-dialog>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-navigation-drawer>
        </v-card>
      </v-col>
      <v-col cols="9">
        <!--    -->
        <!--    <v-autocomplete-->
        <!--        :loading="!trees.length"-->
        <!--        v-model="selectedTreeId"-->
        <!--        :items="trees"-->
        <!--        item-text="name"-->
        <!--        item-value="id"-->
        <!--        :auto-select-first="true"-->
        <!--        :label="$t('stories.treeName')"-->
        <!--        @input="onTreeSelected"-->
        <!--    ></v-autocomplete>-->

        <div v-if="isLoading" class="ml-auto mr-auto text-align-center mt-3" width="100px">
          <v-progress-circular :size="40"
                               color="#ff9200"
                               indeterminate
          ></v-progress-circular>
        </div>
        <div v-else-if="selectedTreeId">
          <v-row v-if="!stories.length">
            <v-col cols="6" class="no-trees-wrapper">
              <h1>{{ $t('stories.noStories') }}</h1>
              <p>
                <small>Добавляйте истории своей семьи, чтобы сохранить их навсегда.</small>
              </p>
              <router-link :to="{name: 'story-new', params: {treeId: selectedTreeId}}">
                <v-btn color="primary">
                  {{ $t('stories.addStory') }}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
          <template v-else>
            <div class="text-right">
              <router-link :to="{name: 'story-new', params: {treeId: selectedTreeId}}">
                <v-btn color="primary">{{ $t('stories.addStory') }}</v-btn>
              </router-link>
            </div>
            <v-list two-line>
              <v-list-item-group v-if="stories.length" multiple>
                <v-list-item v-for="story in stories" :key="story.id">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center justify-space-between">
                      <router-link :to="{name: 'story-page',params: {treeId: selectedTreeId, storyId: story.id}}">
                        <div>{{ story.title || $t('stories.noTitle') }}</div>
                      </router-link>
                      <div>
                        <router-link :to="{name: 'story-page', params: {treeId: selectedTreeId, storyId: story.id}}">
                          <v-btn text icon>
                            <v-icon>article</v-icon>
                          </v-btn>
                        </router-link>
                        <router-link :to="{name: 'story-form', params: {treeId: selectedTreeId, storyId: story.id}}">
                          <v-btn text icon>
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </router-link>

                        <remove-confirmation-dialog entity="story" :name="story.title" :important="true"
                                                    @confirmed="deleteStory(story.id)"></remove-confirmation-dialog>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import RemoveConfirmationDialog from '@/components/remove-confirmation-dialog';
  import StoryService from '@/services/story.service';
  import TreeService from '@/services/tree.service';
  import TreeFormDialog from '@/modules/tree/tree-form-dialog.vue';

  export default {
    components: {
      RemoveConfirmationDialog,
      TreeFormDialog,
    },
    data() {
      return {
        isLoading: false,
        isTreesLoading: false,
        treeService: new TreeService(),
        trees: [],
        selectedTreeId: null,
        storyService: null,
        stories: [],
      };
    },
    created() {
      this.fetchTrees();
    },
    methods: {
      fetchTrees() {
        this.isTreesLoading = true;
        this.treeService.getTrees()
            .then((trees) => {
              this.trees = trees;
              this.isTreesLoading = false;
            });
      },
      fetchStories() {
        this.isLoading = true;
        const userId = this.$route.params.globalUserId;
        this.storyService = new StoryService({
          userId,
          treeId: this.selectedTreeId,
        });
        this.storyService.getStories()
            .then((stories) => {
              this.stories = stories;
              this.isLoading = false;
            });
      },
      onTreeSelected(id) {
        this.selectedTreeId = id;
        this.fetchStories();
      },
      deleteStory(id) {
        this.storyService.removeStory(id)
            .then(() => {
              this.fetchStories();
            });
      },
    },
  };
</script>
