import store from '@/store';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyDpCZLsKItEGz8744THBwrXOFPFJZLFK0w',
    authDomain: 'app.7gens.ru',
    projectId: 'gens-7-project',
    storageBucket: 'gens-7-project.appspot.com',
    messagingSenderId: '25208472846',
    appId: '1:25208472846:web:54165c5e9b309c115a435f',
    measurementId: 'G-TTQ8P26H9K',
};

export const FirebaseApp = initializeApp(firebaseConfig);
export const FirebaseAuth = getAuth(FirebaseApp);
FirebaseAuth.languageCode = 'ru';
export const FirebaseDB = getFirestore(FirebaseApp);
export const FirebaseStorage = getStorage(FirebaseApp);

export function getCurrentUser() {
    return new Promise((resolve, reject) => {
        const unsubscribe = FirebaseAuth.onAuthStateChanged(async (user) => {
            unsubscribe();
            await store.dispatch('setUser', user);
            await store.dispatch('toggleLoading', false);
            resolve(user);
        }, reject);
    });
}
