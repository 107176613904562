<template>
  <div>
    <v-navigation-drawer v-if="person" v-model="isSelected" @input="onClose" fixed temporary right
                         class="person-details">
      <template v-slot:prepend>
        <div class="person-details_close-button">
          <v-icon @click="close">close</v-icon>
        </div>
        <div class="person-details_header">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="96"
                        v-bind="attrs"
                        v-on="on">
                <img class="person-details_avatar" :src="person.avatarUrl" :alt="person.fullName"/>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <!-- todo fix the box for avatar -->
                  <photo-add-modal :person-id="personId" :is-avatar="true"
                                   @upload-finished="getPerson">
                    <template v-slot:toggle-button="{on, attrs}">
                      <a v-bind="attrs"
                         v-on="on">{{ $t('person.changeAvatar') }}</a>
                    </template>
                  </photo-add-modal>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="person-details_name">{{ person.fullName }}</div>
        </div>
      </template>

      <div class="person-details_photos">
        <div class="person-details_photos_header">{{ photos.length }} фотографий</div>
        <div class="person-details_photos_row">
          <a class="cursor-pointer"
             v-for="(image, index) in photos" :key="`image-${index}`">
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <img :src="image?.downloadUrl"
                     :alt="image?.title"
                     v-bind="attrs"
                     v-on="on"/>
              </template>

              <v-card>
                <v-card-title>
                  {{ image?.name }}
                </v-card-title>

                <v-card-text class="pa-3">
                  <img :style="{
                          width: '100%',
                          height: 'auto',
                  }"
                       :src="image?.downloadUrl"
                       :alt="`image-${index}`">
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                  >
                    <router-link :to="{name: 'photo-page', params: {photoId: image?.id}}">
                      {{ $t('photos.goToPhotoPage') }}
                    </router-link>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </a>
        </div>
        <div class="person-details_action text-muted">
          <photo-add-modal :preset-photo="presetPhoto" @upload-finished="getPerson">
            <template v-slot:toggle-button="{on, attrs}">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  class="person-details_action_button"
              >
                <v-icon
                    left
                    color="#15DC7C"
                >
                  add_circle
                </v-icon>
                <span>{{ $t('photos.addPhoto') }}</span>
              </v-btn>
            </template>
          </photo-add-modal>
        </div>
      </div>
      <div class="person-details_facts">
        <h3 class="person-details_facts_title">
          <span>{{ $t('person.storyLine') }}</span>
          <v-btn icon color="green" class="" @click="openFactForm()">
            <v-icon>add</v-icon>
          </v-btn>
        </h3>

        <fact-form-dialog v-if="isFactFormOpen"
                          :person="person"
                          :dialog="isFactFormOpen"
                          :value="factToUpdate"
                          :edit-mode="!!factToUpdate"
                          @fact-created="onFactFormCompleted"
                          @fact-updated="onFactFormCompleted"
                          @close="isFactFormOpen = false"></fact-form-dialog>
        <template v-for="(fact, index) in facts">
          <v-row class="person-details_fact" v-if="fact" :key="`fact-${index}`">
            <v-col cols="5" class="person-details_fact_date pa-2">
              {{ formatDate(fact.period) || $t('person.periodIsNotSet') }}
              <p><small v-if="fact.period">{{ calculateAge(fact.period) }}</small></p>
            </v-col>
            <v-col cols="7" class="pa-2">
              <span class="person-details_fact_label" v-if="fact.isStory">
                {{ fact.title }}
              </span>
              <span class="person-details_fact_label" v-else>
              {{
                  fact.type !== FactTypes.custom ? $t(`facts.factLabels.${ fact.type }.${ person.gender }`) : fact.title
                }}</span>
            </v-col>
            <v-col col="12" class="person-details_fact_description pa-2" v-if="fact.description">
              <a v-if="fact.link && fact.link.name === 'person'" :href="`${ fact.link.params.id }`">{{
                  fact.description
                }}</a>
              <span v-else>{{ fact.description }}</span>
              <div class="mt-1" v-if="fact.memberIds.length > 1 && people">
                <small>Вместе с:</small>
                <a class="d-block"
                   v-for="memberId in fact.memberIds.filter(id => id !== person.id)"
                   :key="fact.id +'-' + memberId"
                   :href="`${ memberId }`">{{ people[memberId].fullName }}</a>
              </div>
            </v-col>
            <v-col cols="12" class="person-details_fact_geo pa-2" v-if="fact.country || fact.place">
              <v-icon color="primary" class="mr-2">location_on</v-icon>
              <span>{{ countries[fact.country] }}{{ fact.place && `, ${ fact.place }` }}</span>
            </v-col>
            <v-col cols="12" class="person-details_fact_geo" v-else>
              <v-icon color="primary" class="mr-2">not_listed_location</v-icon>
              <span>{{ $t('person.locationIsNotSet') }}</span>
            </v-col>
            <v-col cols="12" class="text-right pa-0" v-if="!fact.isLifeFact && !fact.isStory">
              <v-btn x-small color="green" text @click="editFact(fact)">{{ $t('forms.edit') }}</v-btn>
              <v-btn x-small color="red" text @click="removeFact(fact.id)">{{ $t('forms.remove') }}</v-btn>
            </v-col>
            <v-col cols="12" class="text-right pa-0" v-if="fact.isStory">
              <v-btn x-small color="blue" text @click="navigateToStory(fact)">{{ $t('forms.navigateToStory') }}</v-btn>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import FactModel from '@/models/fact.model';
  import PhotoModel from '@/models/photo.model';
  import FactFormDialog from '@/modules/person/components/fact-form-dialog';
  import FactService from '@/services/fact.service';
  import PersonService from '@/services/person.service';
  import PhotoService from '@/services/photo.service';
  import PhotoAddModal from '@/modules/photos/components/photo-add-modal.vue';
  import { countries, FactTypes, onlyUnique, prepareDate } from '@/models/helpers';
  import StoryService from '@/services/story.service';
  import moment from 'moment';

  export default {
    name: 'PersonDetails',
    components: {
      FactFormDialog,
      PhotoAddModal,
    },
    data() {
      return {
        person: null,
        personId: null,
        photos: [],
        facts: [],
        stories: [],
        personService: null,
        people: null,
        factService: null,
        storyService: null,
        photoService: new PhotoService(),
        countries,
        isSelected: true,
        isFactFormOpen: false,
        isAvatarFormOpen: false,
        presetPhoto: null,
        FactTypes,
        factToUpdate: null,
      };
    },
    methods: {
      formatDate(date) {
        return prepareDate(date);
      },
      onClose() {
        if ( !this.isSelected ) {
          this.$router.push({
            name: 'tree',
            params: { treeId: this.$route.params.treeId },
          });
        }
      },
      close() {
        this.isSelected = false;
        this.onClose();
      },
      navigateToStory(story) {
        this.$router.push({
          name: 'story-page',
          params: {
            treeId: this.$route.params.treeId,
            storyId: story.id,
          },
        });
      },
      openFactForm(fact) {
        this.isFactFormOpen = true;

        if ( fact ) {
          this.factToUpdate = fact;
        }
      },
      onFactFormCompleted() {
        this.isFactFormOpen = false;
      },
      async fetchFacts() {
        const facts = await Promise.all(this.person.factIds.map(async (id) => await this.factService.getFact(id)));
        const stories = await Promise.all(this.person.storiesIds.map(async (id) => await this.storyService.getStory(id)));
        this.facts = await this.getSortedAndCombinedFacts(facts, stories);
      },
      async fetchPeople() {
        if ( !this.personService ) {
          return;
        }
        const people = await this.personService.getPeopleById();
        this.people = people;
      },
      openAvatarForm() {
        this.isAvatarFormOpen = true;
      },
      closeAvatarForm() {
        this.isAvatarFormOpen = false;
        this.getPerson();
      },
      async getPerson() {
        await this.personService.getSnapshot(this.personId, async (value) => {
          this.person = value;

          this.presetPhoto = new PhotoModel({
            boxes: [ {
              top: 0,
              left: 0,
              width: 100,
              height: 100,
              personId: this.person.id,
              treeId: this.$route.params.treeId,
            } ],
          });

          this.photos = await Promise.all(this.person.photoIds.map(async (id) => await this.photoService.getPhoto(id)));

          await this.fetchFacts();
          await this.fetchPeople();

          await this.$nextTick();
        });
      },
      editFact(fact) {
        this.openFactForm(fact);
      },
      async removeFact(id) {
        await this.factService.removeFact(id);
      },
      async getSortedAndCombinedFacts(facts, stories) {
        const children = await Promise.all(this.person.childrenIds.map(async (id) => await this.personService.getPerson(id)));
        const childrenFacts = children.length && children
            .reduce((acc, child) => {
              const facts = [ new FactModel({
                type: FactTypes.childBirth,
                description: `${ child.fullName }`,
                link: {
                  name: 'person',
                  params: { id: child.id },
                },
                period: child.birthDate,
                country: child.birthCountry,
                place: child.birthPlace,
              }) ];
              if ( !child.isAlive ) {
                facts.push(new FactModel({
                  link: {
                    name: 'person',
                    params: { id: child.id },
                  },
                  type: FactTypes.childDeath,
                  period: child.deathDate,
                  country: child.deathCountry,
                  place: child.deathPlace,
                }));
              }
              return [ ...acc, ...facts ];
            }, []) || [];

        const lifeFacts = [ this.birthFact, ...childrenFacts ];

        if ( !this.person.isAlive ) {
          lifeFacts.push(this.deathFact);
        }

        lifeFacts.filter(onlyUnique).map(_ => _.isLifeFact = true);

        return [ ...facts, ...lifeFacts, ...stories ]
            .sort((a, b) => {
              const dateA = a.period?.date;
              const dateB = b.period?.date;
              if ( moment(dateA)
                  .isBefore(dateB) ) {
                return 1;
              }
              if ( moment(dateA)
                  .isAfter(dateB) ) {
                return -1;
              }
              return 0;
            });
      },
      calculateAge(factPeriod) {
        const date = factPeriod?.date || factPeriod;
        const deathDate = this.person?.deathDate?.date;

        // do not count age if the it's after person's death
        if ( moment(date)
            .isAfter(deathDate) ) {
          return '';
        }

        const age = moment(date)
            .diff(this.person?.birthDate?.date, 'years');
        const checkDate = date instanceof Object ? !!date.date : !!date;
        return checkDate && this.person?.birthDate?.date && age > 0 ? moment(date)
            .from(this.person?.birthDate?.date, 'years') : '';
      },
    },
    computed: {
      birthFact() {
        return new FactModel({
          type: FactTypes.birth,
          period: this.person.birthDate,
          country: this.person.birthCountry,
          place: this.person.birthPlace,
        });
      },
      deathFact() {
        return new FactModel({
          type: FactTypes.death,
          period: this.person.deathDate,
          country: this.person.birthCountry,
          place: this.person.birthPlace,
        });
      },
    },
    async mounted() {
      const userId = this.$route.params.globalUserId;
      const treeId = this.$route.params.treeId;
      this.personId = this.$route.params.personId;
      this.personService = new PersonService({
        userId,
        treeId,
      });
      this.factService = new FactService({
        userId,
        treeId,
      });
      this.storyService = new StoryService({
        userId,
        treeId,
      });
      await this.getPerson();
    },
  };
</script>

<style>
.person-details {
  margin: 90px 30px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  width: 320px !important;
  height: 90% !important;
}

.person-details_header {
  padding: 20px;
  /*display: flex;*/
  text-align: center;
  /*align-items: center;*/
}

.person-details_avatar {
  object-fit: cover;
}

.person-details_action_button {

}

.person-details_close-button {
  position: absolute;
  right: 2px;
  top: 2px;
}

.person-details_photos {
  background-color: #EAEAEA;
  height: 110px;
  padding: 20px;
}

.person-details_photos img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin: 5px;
}

.person-details_photos_row {
  height: 40px;
  overflow: hidden;
}

.person-details_facts {
  height: calc(100vh - 310px);
  min-height: 300px;
  overflow: scroll;
}

.person-details_facts_title {
  display: flex;
  justify-content: space-between;
}

.person-details_facts > div {
  padding: 20px;
}

.person-details_facts h3 {
  padding: 20px;
  font-weight: 400;
}

.person-details_photos_header,
.person-details_fact_date {
  font-weight: bold;
  font-size: 12px;
}

.person-details_fact {
  border-top: 1px solid #EAEAEA;
}

.person-details_fact div {
  line-height: 20px;
  vertical-align: middle;
}

.person-details_fact_label {
  font-weight: 300;
  padding-left: 5px;
}

.person-details_fact_description {
}

.person-details_fact_geo {
  font-size: 12px;
  color: #C2C2C2;
  vertical-align: middle;
}

</style>

