<template>
  <div>
    <p>
      Я собираю полезные ссылки по регионам на этой страничке. Если вы хотите поделиться ссылкой, напишите мне в <a
        href="https://instagram.com/daxxac">директ</a>, в <a href="https://instagram.com/daxxac_dm">телеграм</a>, или на
      <a href="mailto:dasha@7gens.me">почту</a>.
    </p>
    <p class="text--secondary">База только в процессе наполнения.</p>

    <v-expansion-panels class="mt-2" v-model="panel" multiple>
      <v-expansion-panel v-for="{title, links} in links">
        <v-expansion-panel-header>{{ title }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-if="links" class="metrics-links">
            <div v-for="({name, url}, index) in links" :key="`link-${index}`">
              <div>
                  <a :href="url">{{ name || url }}</a>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  export default {
    name: 'links-page',
    data() {
      return {
        panel: [],
        links: [ {
          title: 'Томская губерния',
          links: [ {
            name: 'Перепись 1917 года (Алтайский край)',
            url: 'https://altarchives.ru/census1917',
          }, {
            name: 'Томская губернская сельскохозяйственная перепись 1901 г.',
            url: 'http://1901.vorotyntsev.name/Home',
          }, {
            name: 'Список оцифрованных метрических книг Томской губернии (с номером пленки)',
            url: 'https://forum.vgd.ru/606/104124/0.htm',
          }, {
            name: 'Электронный каталог Государственного архива Томской области',
            url: 'https://archtomsk.tomica.ru/',
          }, {
            name: 'Приходы Новосибирской области',
            url: 'https://maps.nso.ru/CoGIS/%C0%F0%F5%E8%E2%20%CD%EE%E2%EE%F1%E8%E1%E8%F0%F1%EA%EE%E9%20%EE%E1%EB%E0%F1%F2%E8',
          }],
        } ],
      };
    },
  };
</script>
