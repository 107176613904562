<template>
  <v-container>
    <div v-if="isLoading" class="ml-auto mr-auto text-align-center mt-3" width="100px">
      <v-progress-circular :size="40"
                           color="#ff9200"
                           indeterminate
      ></v-progress-circular>
    </div>
    <div v-else-if="story">
      <v-row>
        <!--        <v-col cols="12">-->
        <!--          <v-select-->
        <!--              v-model="story.treeId"-->
        <!--              :items="trees"-->
        <!--              item-text="name"-->
        <!--              item-value="id"-->
        <!--              label="Tree"-->
        <!--          ></v-select>-->
        <!--        </v-col>-->
        <template v-if="story.treeId">
          <v-col cols="12">
            <v-text-field
                v-model="story.title"
                counter
                maxlength="50"
                :label="$t('stories.name')"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <date v-model="story.period"
                  :label="$t('stories.period')"
            ></date>
          </v-col>
          <v-col cols="12">
            <persons-select v-model="story.memberIds"
                            :label="$t('stories.memberIds')"
                            :treeId="story.treeId"></persons-select>
          </v-col>
          <v-col cols="12"
                 sm="6">
            <v-text-field
                :label="$t('facts.place')"
                counter
                maxlength="150"
                v-model="story.place"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-select
                v-model="story.country"
                :items="countriesList"
                :label="$t('facts.country')"
            ></v-select>
          </v-col>
          <v-divider></v-divider>
          <div class="story_photos mt-4 mb-12">
            <div class="story_photos_header">{{ story.photoIds.length }} фотографий</div>
            <div class="story_photos_row">
              <a v-for="(image, index) in photos"
                 :key="`image-${index}`" class="cursor-pointer">
                <v-dialog width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <img :alt="image?.title"
                         :src="image?.downloadUrl"
                         v-bind="attrs"
                         v-on="on"/>
                  </template>

                  <v-card>
                    <v-card-title>
                      {{ image?.name }}
                    </v-card-title>

                    <v-card-text class="pa-3">
                      <img :alt="`image-${index}`"
                           :src="image?.downloadUrl"
                           :style="{
                          width: '100%',
                          height: 'auto',
                  }">
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          text
                      >
                        <router-link :to="{name: 'photo-page', params: {photoId: image?.id}}" target="_blank">
                          {{ $t('photos.goToPhotoPage') }}
                        </router-link>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </a>
            </div>
            <div class="story_action text-muted">
              <photo-add-modal :preset-photo="presetPhoto" @upload-finished="onUploadFinished">
                <template v-slot:toggle-button="{on, attrs}">
                  <v-btn
                      class="story_action_button"
                      text
                      v-bind="attrs"
                      x-small
                      v-on="on"
                  >
                    <v-icon
                        color="#15DC7C"
                        left
                    >
                      add_circle
                    </v-icon>
                    <span>{{ $t('photos.addPhoto') }}</span>
                  </v-btn>
                </template>
              </photo-add-modal>
            </div>
          </div>
        </template>
      </v-row>
      <template v-if="story.treeId">
        <ckeditor id="editor" v-model="story.text" :config="editorConfig" :editor="editor"></ckeditor>

        <v-row class="justify-end mt-12">
          <v-btn color="primary" @click="saveStory">{{ $t('forms.save') }}</v-btn>
          <v-btn class="ml-3" @click="cancel">
            {{ $t('forms.cancel') }}
          </v-btn>
        </v-row>
      </template>
    </div>
  </v-container>
</template>

<script>
  import { countries } from '@/models/helpers';
  import PhotoModel from '@/models/photo.model';
  import StoryModel from '@/models/story.model';
  import toast from '@/services/helpers/toast';
  import PersonService from '@/services/person.service';
  import PhotoService from '@/services/photo.service';
  import StoryService from '@/services/story.service';
  import TreeService from '@/services/tree.service';
  import Editor from 'ckeditor5-7gens/build/ckeditor';
  import Date from '@/components/date';
  import PersonsSelect from '@/components/persons-select.vue';
  import PhotoAddModal from '@/modules/photos/components/photo-add-modal.vue';

  export default {
    name: 'story-form',
    components: {
      Date,
      PersonsSelect,
      PhotoAddModal,
    },
    data() {
      return {
        isLoading: false,
        treeService: new TreeService(),
        storyService: null,
        photoService: new PhotoService(),
        personService: null,
        trees: [],
        photos: [],
        story: null,
        editor: Editor,
        editorConfig: {
          toolbar: [
            'undo', 'redo',
            '|', 'bold', 'italic', 'strikethrough', 'superscript', 'code',
            '|', 'blockQuote', 'code',
            '|', 'bulletedList', 'numberedList', 'todoList',
          ],
          placeholder: this.$t('stories.tellYourStory'),
        },
        preset: new PhotoModel(),
      };
    },
    computed: {
      editMode() {
        return this.$route.name === 'story-form';
      },
      presetPhoto() {
        return {
          ...this.preset,
          story: {
            storyId: this.story.id,
            treeId: this.story.treeId,
          },
        };
      },
      countriesList() {
        return Object.entries(countries)
            .map(([ key, value ]) => ({
              text: value,
              value: key,
            }));
      },
    },
    async created() {
      if ( this.$route.name === 'story-new' ) {
        this.story = new StoryModel({ treeId: this.$route.params.treeId });
      }
      await this.fetchData();
    },
    methods: {
      async fetchData() {
        this.isLoading = true;
        try {
          this.getService();
          this.fetchTrees();
          if ( this.editMode ) {
            await this.fetchStory();
            await this.fetchPeople();
            await this.fetchPhotos();
          }
        } catch ( e ) {
          toast('error', `alerts.errors.${ e.code }`);
          console.log(e);
        } finally {
          this.isLoading = false;
        }
      },
      async fetchPeople() {
        this.members = await Promise.all(this.story.memberIds.map(id => this.personService.getPerson(id)));
      },
      async fetchPhotos() {
        this.photos = await Promise.all(this.story.photoIds.map(id => this.photoService.getPhoto(id)));
      },
      async fetchStory() {
        this.story = await this.storyService.getStory(this.$route.params.storyId);
      },
      fetchTrees() {
        this.isLoading = true;
        this.treeService.getTrees()
            .then((trees) => {
              this.trees = trees;
              this.isLoading = false;
            });
      },
      async saveStory() {
        try {
          this.getService();
          if ( !this.storyService ) {
            return; // todo show error
          }

          const method = this.editMode ? 'updateStory' : 'createStory';
          await this.storyService[method](this.story.getPayload());
          await this.$router.push({
            name: 'story-page',
            params: {
              treeId: this.story.treeId,
              storyId: this.story.id,
            },
          });
        } catch ( e ) {
          console.log(e);
        }
      },
      onUploadFinished(photoId) {
        this.story.photoIds.push(photoId);
        this.getStory();
      },
      async getStory() {
        this.photos = await Promise.all(this.story.photoIds.map(async (id) => await this.photoService.getPhoto(id)));
      },
      getService() {
        const userId = this.$route.params.globalUserId;
        const treeId = this.story && this.story.treeId || this.$route.params.treeId;
        console.log(treeId, this.story && this.story.treeId , this.$route.params);

        this.storyService = new StoryService({
          userId,
          treeId,
        });
        this.personService = new PersonService({
          userId,
          treeId,
        });
      },
      cancel() {
        this.$router.push({
          name: 'stories',
        });
      },
    },
  };
</script>

<style scoped>
#editor {
  min-height: 500px;
}

.ck-editor {
  color: var(--primary-color) !important;
}

.story_photos {
  background-color: #EAEAEA;
  height: 110px;
  padding: 16px;
  width: 100%;
}

.story_photos img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin: 5px;
}

.story_photos_row {
  height: 40px;
  overflow: hidden;
}
</style>
