<template>
  <v-dialog
      :value="dialog"
      persistent
      max-width="800"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ $t(`person.changeConnections`) }}
      </v-card-title>

      <v-card-text>
        <div v-if="!person" class="loading-state-wrapper">
          <v-progress-circular :size="60"
                               color="amber"
                               indeterminate
          ></v-progress-circular>
        </div>
        <v-stepper v-else v-model="stepper">
          <v-stepper-header>
            <v-stepper-step
                :complete="stepper > 1"
                step="1"
            >
              {{ $t(`person.changeConnections`) }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="stepper > 2"
                step="2"
            >
              {{ $t(`person.confirmChangeConnections`) }}
            </v-stepper-step>

            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                  class="mb-12"
              >
                <v-row>
                  <v-col cols="12">
                    <people-autocomplete-input v-model="person.motherId" :chips="false"
                                               :filter="p => p.gender === 'female'"
                                               :label="$t('person.mother')"
                                               :multiple="false"></people-autocomplete-input>
                    <people-autocomplete-input v-model="person.fatherId" :chips="false"
                                               :filter="p => p.gender === 'male'"
                                               :label="$t('person.father')"
                                               :multiple="false"></people-autocomplete-input>
                    <people-autocomplete-input v-model="person.childrenIds"
                                               :label="$t('person.children')"></people-autocomplete-input>
                    <people-autocomplete-input v-model="person.partnerIds"
                                               :label="$t('person.partners')"></people-autocomplete-input>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                  color="primary"
                  @click="nextStep"
              >
                {{ $t('forms.continue') }}
              </v-btn>

              <v-btn text @click="$emit('close')">
                {{ $t('forms.cancel') }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div>
                <div><p>{{ $t('person.changesToBeApplied') }}:</p>
                  <small class="red--text">{{ $t('person.pleaseCarefullyChangeConnections') }}!</small>
                  <p v-if="!changes.length">{{ $t('person.noChanges') }}</p>
                  <v-list-item v-for="change of changes" :key="change.id">
                    <v-list-item-content v-if="change.motherId">
                      <v-list-item-title
                          v-html="$t('person.connections.toPersonWillBeAppliedMother', [ people[change.id] && people[change.id].fullName ])"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ people[change.motherId] && people[change.motherId].fullName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-if="change.fatherId">
                      <v-list-item-title
                          v-html="$t('person.connections.toPersonWillBeAppliedFather', [ people[change.id] && people[change.id].fullName ])"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ people[change.fatherId] && people[change.fatherId].fullName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-if="change.childrenIds">
                      <v-list-item-title
                          v-html="$t('person.connections.toPersonWillBeAppliedChildren', [ people[change.id] && people[change.id].fullName ])">
                      </v-list-item-title>
                      <v-list-item-subtitle v-for="childId of change.childrenIds" :key="childId">
                        {{ people[childId] && people[childId].fullName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-if="change.partnerIds">
                      <v-list-item-title
                          v-html="$t('person.connections.toPersonWillBeAppliedPartners', [ people[change.id] && people[change.id].fullName ])">
                      </v-list-item-title>
                      <v-list-item-subtitle v-for="partnerId of change.partnerIds" :key="partnerId">
                        {{ people[partnerId] && people[partnerId].fullName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </div>
              </div>

              <v-btn
                  color="primary"
                  @click="saveChanges"
              >
                {{ $t('forms.save') }}
              </v-btn>

              <v-btn text @click="$emit('close')">
                {{ $t('forms.cancel') }}
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>


      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import PersonModel from '@/models/person.model';
  import toast from '@/services/helpers/toast';
  import PersonService from '@/services/person.service';
  import PeopleAutocompleteInput from '@/modules/person/components/people-autocomplete.vue';

  export default {
    name: 'connection-form',
    components: { PeopleAutocompleteInput },

    props: {
      dialog: {
        type: Boolean,
        default: null,
      },
      personId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        service: null,
        initialPerson: null,
        person: null,
        isLoading: false,
        stepper: 1,
        changes: [],
        people: [],
      };
    },
    mounted() {
      this.fetchPerson();
    },
    methods: {
      async fetchPerson() {
        try {
          this.service = new PersonService({
            treeId: this.$route.params.treeId,
            userId: this.$route.params.globalUserId,
          });
          this.isLoading = true;
          const person = await this.service.getPerson(this.personId);
          this.person = person;
          this.initialPerson = new PersonModel(person);

          const peopleArray = await this.service.getPeople();
          this.people = peopleArray.reduce((acc, cur) => ({
            ...acc,
            [cur.id]: cur,
          }), {});
        } catch ( e ) {
                      toast('error', `alerts.errors.${e.code}`);

          console.log(e);
        } finally {
          this.isLoading = false;
        }
      },
      async saveChanges() {
        try {
          this.isLoading = true;
          if ( this.changes.length ) {
            await this.service.updatePerson(this.person.id, this.person.getPayload());
            await this.service.saveSyncChanges(this.changes);
          }
        } catch ( e ) {
                      toast('error', `alerts.errors.${e.code}`);

          console.log(e);
        } finally {
          this.isLoading = false;
          this.$emit('close');
          this.$emit('save');
        }
      },
      async nextStep() {
        this.changes = await this.service.getSyncChanges(this.person, this.initialPerson);
        this.stepper++;
      },
    },
  };
</script>
