import { FactTypes, Sex } from '@/models/helpers';

export default {
    access: {
        myPlan: 'My plan',
        free: 'Free',
        paid: 'Paid',
        paidDescription: 'You have paid subscription till {0}',
        trees: {
            quantity: 'Trees quantity',
        },
        people: {
            quantity: 'People per tree quantity',
        },
        photo: {
            quantity: 'Photo per user quantity',
        },
    },
    addFirstNode: 'Start',
    addPhoto: 'Add a photo',
    alerts: {
        error: 'An error has occurred. Please refresh the page or try again later.',
        errors: {
            unauthorized: 'It seems, you are not signed up yet',
            'storage/unauthorized': 'You may have chosen the wrong data format. Only JPG and PNG images can be uploaded.',
            'auth/email-already-in-use': 'The email address is already in use',
            'auth/invalid-email': 'Invalid e-mail',
            'auth/user-not-found': 'User not found',
            'auth/wrong-password': 'Invalid password',
            'auth/missing-email': 'Email address is a required field',
            emailWrongFormat: 'The email address must be a valid email address',
            emailRequired: 'Email address is a required field',
            passwordNotMatch: 'Password and password confirmation don\'t match',
            passwordRequired: 'Password is a required field',
            passwordMinLength: 'Password must be at least 6 characters',
            privacyPolicyIsNotChecked: 'You must agree to the privacy policy',
            uidRequired: 'User ID is a required field',
            uidWrongFormat: 'User ID must be a valid string',
            firstName: {
                required: 'First name is a required field',
            },
            lastName: {
                required: 'Last name is a required field',
            },
        },
        fact: {
            created: 'The fact was successfully created',
            removed: 'The fact was successfully deleted',
            updated: 'The fact has been successfully updated',
        },
        person: {
            created: 'Successfully created profile',
            removed: 'A person\'s profile has been successfully deleted',
            updated: 'Profile was successfully updated',
        },
        photo: {
            created: 'Photo uploaded successfully',
            removed: 'The photo was successfully deleted',
            updated: 'Photo has been successfully updated',
        },
        story: {
            created: 'The story was successfully created',
            removed: 'The story was successfully deleted',
            updated: 'The story has been successfully updated',
        },
        tree: {
            created: 'The tree was successfully created',
            removed: 'The tree was successfully deleted',
            updated: 'The tree was successfully updated',
        },
    },
    close: 'Close',
    contacts: {
        title: 'Our contacts',
    },
    createTree: 'Create a tree',
    datePicker: {
        nextMonthAriaLabel: 'Next Month',
        prevMonthAriaLabel: 'Previous Month',
    },
    editTree: 'Edit tree',
    email: 'Email Address',
    errors: {
        'auth/email-already-in-use': 'Email address already in use',
        'auth/user-not-found': 'User not found',
        'auth/wrong-password': 'Password is invalid',
        confirmPassword: {
            minLength: 'Password confirmation must be at least 6 characters long',
            notMatch: 'Password and password confirmation don\'t match',
            required: 'Password confirmation is a required field',
        },
        email: {
            email: 'The email address must be a valid email address',
            required: 'Email address is a required field',
        },
        firstName: {
            required: 'First name is a required field',
        },
        lastName: {
            required: 'Last name is a required field',
        },
        password: {
            minLength: 'Password must be at least 6 characters',
            required: 'Password is a required field',
        },
        treeDescription: {
            required: 'The tree description is a required field',
        },
        treeName: {
            required: 'The tree name is a required field',
        },
    },
    explore: {
        dbs: 'Open databases',
        description: 'Welcome to the research section! Here you can find metric books, application templates, and other useful resources to help you find information about your family.',
        marathon: 'Marathon',
        metricBooks: {
            description: 'This section will be described here',
        },
        metrics: 'Metric books',
        search: 'Search in open databases',
        title: 'Research',
    },
    facts: {
        add: 'Add a fact',
        country: 'Country',
        date: 'date',
        delete: 'Delete a fact',
        description: 'Description',
        edit: 'Edit fact',
        members: 'Participants',
        place: 'spot',
        title: 'Title',
        type: 'Type',
        types: {
            [FactTypes.birth]: 'Birth',
            [FactTypes.burial]: 'Burial',
            [FactTypes.childBirth]: 'Child birth',
            [FactTypes.childDeath]: 'Child death',
            [FactTypes.christening]: 'Christening',
            [FactTypes.custom]: 'Custom',
            [FactTypes.death]: 'Death',
            [FactTypes.divorce]: 'Divorce',
            [FactTypes.education]: 'Education',
            [FactTypes.employment]: 'Employment',
            [FactTypes.immigration]: 'Immigration',
            [FactTypes.marriage]: 'Marriage',
            [FactTypes.militaryAward]: 'Military award',
            [FactTypes.militaryService]: 'Military service',
            [FactTypes.placeOfResidence]: 'Place of residence',
            [FactTypes.militaryRank]: 'Military rank',
            [FactTypes.adopted]: 'Adopted',
            [FactTypes.adultChristening]: 'Adult christening',
            [FactTypes.barMitzvah]: 'Bar mitzvah',
            [FactTypes.basMitzvah]: 'Bas mitzvah',
            [FactTypes.cremation]: 'Cremation',
            [FactTypes.graduated]: 'Graduated',
        },
        factLabels: {
            [FactTypes.birth]: {
                [Sex.female]: 'was born',
                [Sex.male]: 'was born',
            },
            [FactTypes.death]: {
                [Sex.female]: 'died',
                [Sex.male]: 'died',
            },
            [FactTypes.childBirth]: {
                [Sex.female]: 'gave birth a child',
                [Sex.male]: 'became a father',
            },
            [FactTypes.childDeath]: {
                [Sex.female]: 'child died',
                [Sex.male]: 'child died',
            },
            [FactTypes.christening]: {
                [Sex.female]: 'was christened',
                [Sex.male]: 'was christened',
            },
            [FactTypes.burial]: {
                [Sex.female]: 'was buried',
                [Sex.male]: 'was buried',
            },
            [FactTypes.divorce]: {
                [Sex.female]: 'divorced',
                [Sex.male]: 'divorced',
            },
            [FactTypes.marriage]: {
                [Sex.female]: 'get married',
                [Sex.male]: 'get married',
            },
            [FactTypes.militaryAward]: {
                [Sex.female]: 'was awarded for military service',
                [Sex.male]: 'was awarded for military service',
            },
            [FactTypes.militaryService]: {
                [Sex.female]: 'served',
                [Sex.male]: 'served',
            },
            [FactTypes.placeOfResidence]: {
                [Sex.female]: 'lived',
                [Sex.male]: 'lived',
            },
            [FactTypes.militaryRank]: {
                [Sex.female]: 'was awarded the title',
                [Sex.male]: 'was awarded the title',
            },
            [FactTypes.adopted]: {
                [Sex.female]: 'was adopted',
                [Sex.male]: 'was adopted',
            },
            [FactTypes.adultChristening]: {
                [Sex.female]: 'was christened in adulthood',
                [Sex.male]: 'was christened in adulthood',
            },
            [FactTypes.barMitzvah]: {
                [Sex.male]: 'Bar Mitzvah',
            },
            [FactTypes.basMitzvah]: {
                [Sex.female]: 'Bas Mitzvah',
            },
            [FactTypes.cremation]: {
                [Sex.female]: 'was cremated',
                [Sex.male]: 'was cremated',
            },
            [FactTypes.education]: {
                [Sex.female]: 'studied',
                [Sex.male]: 'studied',
            },
            [FactTypes.employment]: {
                [Sex.female]: 'worked',
                [Sex.male]: 'worked',
            },
            [FactTypes.graduated]: {
                [Sex.female]: 'graduated',
                [Sex.male]: 'graduated',
            },
            [FactTypes.immigration]: {
                [Sex.female]: 'moved',
                [Sex.male]: 'moved',
            },
        },
    },
    firstName: 'Name',
    forms: {
        actions: 'Actions',
        cancel: 'Cancel',
        confirm: 'Confirm',
        confirmationType: 'To confirm, enter the name <b>{0}</b> in the field below',
        description: 'Description',
        edit: 'Edit',
        id: 'ID',
        isPublic: 'Make the tree public',
        name: 'Title',
        navigateToStory: 'Go to the story',
        photoDate: 'When was the photo taken?',
        remove: 'Remove',
        removeConfirmation: 'Confirm deletion {0}',
        removeConfirmationText: 'This action cannot be undone. Are you sure you want to delete <b>{0}</b>?',
        removeEntities: {
            story: 'Stories',
            tree: 'of the tree',
        },
        save: 'Save',
        saveChanges: 'Save',
        treeId: 'Unique ID of your tree',
        upload: 'Download',
    },
    lastName: 'Last surname',
    login: 'Login',
    logout: 'Sign out',
    marathon: {
        description: 'Hi there! My name is Dasha. I\'ve created this marathon to help you start exploring your kind of thing. For 2 months, maybe more, I\'ll be shooting and posting videos that will help you gather information about your family. At the end of the marathon, you will receive a ready-made tree that you can share with relatives.',
        introduction: 'Introduction',
        title: 'Marathon',
        toBeContinued: 'To be continued...',
        'open-dbs': 'Open sources',
    },
    menu: {
        books: 'Book',
        explore: 'Research',
        marathon: 'Marathon',
        myTrees: 'My trees',
        photos: 'Photos',
        stories: 'Stories',
        trees: 'Trees',
    },
    noDataText: 'No data',
    noPeopleInTheTree: 'There are no people in this tree yet',
    noPeopleInTheTreeDescription: 'Add people to the tree to start exploring',
    noPhotos: 'You don\'t have any photos yet',
    noPhotosDescription: 'Save your family photo album along with the story',
    noTrees: 'You don\'t have trees yet',
    password: 'Password',
    person: {
        addAvatar: 'Add Avatar',
        addRelative: 'Add a relative',
        alive: 'Alive',
        birthCountry: 'Country of birth',
        birthDate: 'Date of birth',
        birthPlace: 'Birth place',
        changeAvatar: 'Change avatar',
        changeConnections: 'Edit connections',
        changesToBeApplied: 'Changes that will also be applied after confirmation',
        children: 'Children',
        confirmChangeConnections: 'Confirm connection changes',
        connectionPerson: 'Connect with a person',
        connectionType: 'Connectivity',
        connections: {
            toPersonWillBeAppliedChildren: 'Children <b>{0}</b>',
            toPersonWillBeAppliedFather: 'Father <b>{0}</b>',
            toPersonWillBeAppliedMother: 'Mother <b>{0}</b>',
            toPersonWillBeAppliedPartners: 'Partners <b>{0}</b>',
        },
        deathCountry: 'Country of Death',
        deathDate: 'Death Date',
        deathPlace: 'Place of death',
        email: 'Email',
        emailInvalid: 'Email',
        father: 'Father',
        female: 'Woman',
        firstName: 'Name',
        lastName: 'Surname',
        locationIsNotSet: 'The place is not specified',
        maidenName: 'Maiden name',
        male: 'Male',
        middleName: 'Middle name',
        mother: 'Mother',
        noChanges: 'No changes',
        partners: 'Partners',
        periodIsNotSet: 'No date',
        pleaseCarefullyChangeConnections: 'Please check carefully the links you want to change',
        storyLine: 'Life story',
        years: 'years',
    },
    personalInformation: 'Personal information',
    photos: {
        addPhoto: 'Add a photo',
        connectToStory: 'Attach to the story',
        detectBox: 'Select an area in the photo and select a person from the list',
        goToPhotoPage: 'Go to the photo page',
        labelPerson: 'Tag a person in a photo',
        name: 'Title',
        noLabels: 'No tags yet',
        noName: 'Untitled',
        onPhoto: 'On the photo',
        storyName: 'Story title',
        treeName: 'Tree',
    },
    policy: 'Privacy Policy',
    policyToCheck: 'Privacy Policy',
    privacyPolicyCheck: 'I agree with the',
    profile: 'Profile',
    requestRestore: 'Request password recovery',
    restorePassword: 'Restore password',
    search: 'Search',
    searchNoResults: 'No results found',
    searchResults: 'Search results',
    searchResultsItem: {
        person: 'Person',
        saveToPerson: 'Save to person',
        tree: 'Tree',
    },
    signInWithGoogle: 'Sign in with Google',
    signUp: 'Sign up',
    slogan: 'Save your family history!',
    soon: 'Coming soon',
    stories: {
        addStory: 'Add a story',
        memberIds: 'Participants in the story',
        name: 'Story title',
        noMembers: 'No members',
        noPhotos: 'No pictures',
        noStories: 'There are no stories for this tree yet',
        noTitle: 'An untitled story',
        period: 'Period',
        photoIds: 'Photos',
        tellYourStory: 'Tell us your story...',
        title: 'Stories',
        treeName: 'Tree',
        treeNameDescription: 'Choose the tree for which you want to view stories',
    },
    subSlogan: 'Build a family tree, explore your family\'s history',
    terms: 'Public offering',
    tree: {
        addDaughter: 'Add daughter',
        addFather: 'Add father',
        addHusband: 'Add husband',
        addMother: 'Add mom',
        addSon: 'Add son',
        addWife: 'Add wife',
        connection: 'Connection',
        details: 'Details',
        edit: 'Edit',
        export: {
            csv: 'Export to CSV',
            json: 'Export to JSON',
            pdf: 'Export to PDF',
            png: 'Export to PNG',
            svg: 'Export to SVG',
        },
        firstPersonCreationForm: {
            continue: 'Continue',
            step1: 'Add yourself',
            step2: 'Add your mom',
            step3: 'Add your dad',
        },
        quantity: 'There are <b>{0}</b> people in your tree now',
        remove: 'Remove',
        removePerson: 'Delete a person',
        removePersonDescription: 'Are you sure you want to remove this person from the tree?',
        searchPlaceholder: 'Search',
        selectTree: 'Choose a tree',
    },
    uid: 'Unique ID',
    uiLanguage: 'Language',
};
