import { FirebaseAuth, FirebaseDB } from '@/firebase';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';

export default class EntityService {
    _entity = null;

    constructor(userId) {
        this._userId = userId;
        this._userRef = doc(FirebaseDB, 'users', userId || FirebaseAuth.currentUser && FirebaseAuth.currentUser.uid);
    }

    async getAllSnapshot(onSnapshotCallback = console.log, filter = undefined, orderBy = undefined, limit = undefined) {
        const preparedFilter = filter ? where(filter.field, filter.operator, filter.value) : undefined;
        console.log(preparedFilter, orderBy, limit); // todo
        const q = query(collection(this._userRef, this._entity));
        let result = null;
        return onSnapshot(q, (res) => {
            result = this.prepareAllData(res.docs);
            onSnapshotCallback(result);
        });
    }

    async getSnapshot(id, callback) {
        const docRef = doc(this._userRef, this._entity, id);
        return onSnapshot(docRef, (doc) => {
            return callback(this.prepareData(doc));
        });

    }

    prepareAllData(docs) {
        return (docs || []).map(doc => this._entityModel ? new this._entityModel(doc.data()) : doc.data());
    }

    prepareData(doc) {
        return doc && this._entityModel ? new this._entityModel(doc.data()) : doc.data();
    }

    async getAll(filter = undefined, orderBy = undefined, limit = undefined) {
        const preparedFilter = filter ? where(filter.field, filter.operator, filter.value) : undefined;
        console.log(preparedFilter, orderBy, limit); // todo
        const q = query(collection(this._userRef, this._entity));
        const { docs } = await getDocs(q);
        return this.prepareAllData(docs);
    }

    async addDoc(data) {
        const colRef = collection(this._userRef, this._entity);
        const docRef = await addDoc(colRef, data);
        return docRef;
    }

    async setDoc(data) {
        const docRef = doc(this._userRef, this._entity, data.id);
        const res = await setDoc(docRef, data);
        return res;
    }

    async getDoc(id) {
        const response = await getDoc(doc(this._userRef, this._entity, id));
        if ( response.exists() ) {
            return this._entityModel ? new this._entityModel(response.data()) : response.data();
        }
    }

    async removeDoc(id) {
        await deleteDoc(doc(this._userRef, this._entity, id));
    }

    async updateDoc(id, data) {
        try {
            const docRef = doc(this._userRef, this._entity, id);
            await updateDoc(docRef, data);
        } catch ( e ) {
            console.log(e);
        }
    }
}
