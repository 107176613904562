import { FirebaseAuth } from '@/firebase';
import { Sex } from '@/models/helpers';
import PersonModel from '@/models/person.model';
import EntityService from '@/services/entity.service';
import toast from '@/services/helpers/toast';
import PlaceService from '@/services/place.service';

const checkSameArrays = (array1, array2) => array1.sort()
    .join(',') === array2.sort()
    .join(',');

const toAddChange = (array1, array2) => array1.filter(_ => !array2.includes(_));
const toRemoveChange = (array1, array2) => array2.filter(_ => !array1.includes(_));

export default class PersonService extends EntityService {
    _entityModel = PersonModel;

    constructor({
        userId = FirebaseAuth.currentUser && FirebaseAuth.currentUser.uid,
        treeId,
    }) {
        super(userId);
        this._entity = `trees/${ treeId }/people`;
        this.placeService = new PlaceService();

    }

    async getPeople() {
        const people = await this.getAll();
        return people;
    }

    async getPerson(id) {
        const person = await this.getDoc(id);
        return person;
    }

    async createPerson(data, notification = true) {
        try {
            const person = await this.setDoc(data);
            if ( notification ) {
                toast('success', 'alerts.person.created');
            }
            // await this.updatePlaces(data);
            return person;
        } catch ( e ) {
            console.log(e);
            toast('error', 'alerts.error');
        }
    }

    async updatePerson(id, data, notification = true) {
        try {
            const person = await this.updateDoc(id, data);
            if ( notification ) {
                toast('success', 'alerts.person.updated');
            }
            // await this.updatePlaces(data);
            return person;
        } catch ( e ) {
            console.log(e);
            toast('error', 'alerts.error');
        }
    }

    async setPerson(data, notification = true) {
        try {
            const person = await this.setDoc(data);
            if ( notification ) {
                toast('success', 'alerts.person.updated');
            }
            return person;
        } catch ( e ) {
            console.log(e);
            toast('error', 'alerts.error');
        }
    }

    async updatePlaces(person) {
        await Promise.all([ person.birthPlace, person.deathPlace ]
            .filter(place => !!place)
            .forEach(place => {
                return this.placeService.createPlace({ name: place });
            }));
    }

    async removePerson(person) {
        try {
            await this.removeDoc(person.id);
            toast('success', 'alerts.person.removed');
            await this.checkAndRemoveConnections(person);
        } catch ( e ) {
            console.log(e);
            toast('error', 'alerts.error');
        }
    }

    async checkAndRemoveConnections(person) {
        if ( person.motherId ) {
            const mother = await this.getPerson(person.motherId);
            await this.removeConnection(mother, 'childrenIds', person.id);
        }

        if ( person.fatherId ) {
            const father = await this.getPerson(person.fatherId);
            await this.removeConnection(father, 'childrenIds', person.id);
        }

        if ( person.partnerIds && person.partnerIds.length ) {
            await Promise.all(person.partnerIds.map(async partnerId => {
                const partner = await this.getPerson(partnerId);
                await this.removeConnection(partner, 'partnerIds', person.id);
            }));
        }

        if ( person.childrenIds && person.childrenIds.length ) {
            await Promise.all(person.childrenIds.map(async childId => {
                const child = await this.getPerson(childId);
                await this.removeConnection(child, person.gender === Sex.male ? 'fatherId' : 'motherId', person.id);
            }));
        }
    }

    async removeConnection(connectedPerson, entity, personId) {
        let result = null;
        if ( [ 'childrenIds', 'partnerIds' ].includes(entity) ) {
            result = connectedPerson[entity].filter(id => id !== personId);
        }
        await this.updatePerson(connectedPerson.id, { [entity]: result });
    }

    async getPeopleById() {
        const people = await this.getAll();
        return people.reduce((acc, person) => {
            acc[person.id] = new this._entityModel(person);
            return acc;
        }, {});
    }

    async saveSyncChanges(changes) {
        await Promise.all(changes.map(change => {
            return this.updatePerson(change.id, change);
        }));
    }

    async getSyncChanges(person, initialPerson) {
        try {
            let changes = [];
            const people = await this.getPeopleById();
            if ( !checkSameArrays(person.childrenIds, initialPerson.childrenIds) ) {
                const fieldName = person.gender === Sex.male ? 'fatherId' : 'motherId';
                const childrenToAdd = toAddChange(person.childrenIds, initialPerson.childrenIds)
                    .map(childId => ({
                        id: childId,
                        [fieldName]: person.id,
                    }));
                const childrenToRemove = toRemoveChange(person.childrenIds, initialPerson.childrenIds)
                    .map(childId => ({
                        id: childId,
                        [fieldName]: null,
                    }));
                changes.push(...childrenToAdd, ...childrenToRemove);
            }
            if ( !checkSameArrays(person.partnerIds, initialPerson.partnerIds) ) {
                const partnerToAdd = toAddChange(person.partnerIds, initialPerson.partnerIds)
                    .filter(partnerId => {
                        const partner = people[partnerId];
                        return !partner.partnerIds.includes(person.id);
                    })
                    .map(partnerId => {
                        const partner = people[partnerId];
                        return {
                            id: partnerId,
                            partnerIds: [ ...partner.partnerIds, person.id ],
                        };
                    });
                const partnerToRemove = toRemoveChange(person.partnerIds, initialPerson.partnerIds)
                    .map(partnerId => ({
                        id: partnerId,
                        partnerIds: people[partnerId].partnerIds.filter(_ => _.id !== person.id),
                    }));
                changes.push(...partnerToAdd, ...partnerToRemove);
            }
            if ( person.motherId !== initialPerson.motherId ) {
                const currentMother = people[person.motherId];
                const motherToAdd = {
                    id: person.motherId,
                    childrenIds: [ ...currentMother.childrenIds, person.id ],
                };
                if ( initialPerson.motherId ) {
                    const previousMother = people[initialPerson.motherId];
                    const motherToRemove = previousMother && {
                        id: initialPerson.motherId,
                        childrenIds: previousMother.childrenIds.filter(_ => _.id !== person.id),
                    };
                    changes.push(motherToRemove);
                }
                changes.push(motherToAdd);
            }
            if ( person.fatherId !== initialPerson.fatherId ) {
                const currentFather = people[person.fatherId];
                const fatherToAdd = {
                    id: person.fatherId,
                    childrenIds: [ ...currentFather.childrenIds, person.id ],
                };
                changes.push(fatherToAdd);

                if ( initialPerson.fatherId ) {
                    const previousFather = people[initialPerson.fatherId];
                    const fatherToRemove = previousFather && {
                        id: initialPerson.fatherId,
                        childrenIds: previousFather.childrenIds.filter(_ => _.id !== person.id),
                    };
                    changes.push(fatherToRemove);
                }
            }
            return changes;
        } catch ( e ) {
            console.log(e);
        }
    }
}
