<template>
  <v-card v-if="value">
    <slot name="title">
      <v-card-title>
        <span class="text-h5">{{ $t('person.addRelative') }}</span>
      </v-card-title>
    </slot>
    <v-card-text>
      <div class="person-form_settings">
        <v-btn-toggle
            class="person-form_toggle-button"
            :value="value.gender"
            @change="onInput('gender', $event)"
        >
          <v-btn value="male">
            {{ $t('person.male') }}
          </v-btn>

          <v-btn value="female">
            {{ $t('person.female') }}
          </v-btn>
        </v-btn-toggle>
        <v-checkbox
            v-if="!isFirstPersonCreation"
            color="black"
            :input-value="value.isAlive"
            :label="$t('person.alive')"
            @change="onInput('isAlive', !!$event)"
        >
        </v-checkbox>
      </div>
      <v-row>
        <v-col cols="12"
               sm="6">
          <photo-add-modal :person-id="value.id" :is-avatar="true">
            <template v-slot:toggle-button="{on, attrs}">
              <v-btn text v-bind="attrs"
                     v-on="on">{{ value.avatar ? $t('person.changeAvatar') : $t('person.addAvatar') }}
              </v-btn>
            </template>
          </photo-add-modal>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              :label="$t('person.lastName')"
              :value="value.lastName"
              counter
              maxlength="100"
              :required="isFirstPersonCreation"
              @input="onInput('lastName', $event)"
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              :label="$t('person.firstName')"
              :value="value.firstName"
              counter
              maxlength="100"
              :required="isFirstPersonCreation"
              @input="onInput('firstName', $event)"
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              :label="$t('person.middleName')"
              :value="value.middleName"
              counter
              maxlength="100"
              :required="isFirstPersonCreation"
              @input="onInput('middleName', $event)"
          ></v-text-field>
        </v-col>
        <v-col cols="12"
               sm="6"
               v-if="isWoman">
          <v-text-field
              :label="$t('person.maidenName')"
              :value="value.maidenName"
              counter
              maxlength="100"
              @input="onInput('maidenName', $event)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <!--todo: add auto complete-->
          <v-text-field
              :label="$t('person.birthPlace')"
              :value="value.birthPlace"
              counter
              maxlength="150"
              :required="isFirstPersonCreation"
              @input="onInput('birthPlace', $event)"
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-select
              :value="value.birthCountry"
              :items="countriesList"
              :label="$t('person.birthCountry')"
              :required="isFirstPersonCreation"
              @input="onInput('birthCountry', $event)"
          ></v-select>
        </v-col>
        <date-picker
            :label="$t('person.birthDate')"
            :value="value.birthDate"
            :required="isFirstPersonCreation"
            @input="onInput('birthDate', $event)"></date-picker>
      </v-row>
      <template v-if="!value.isAlive">
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <!--todo: add auto complete-->
            <v-text-field
                :label="$t('person.deathPlace')"
                :value="value.deathPlace"
                counter
                maxlength="150"
                @input="onInput('deathPlace', $event)"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-select
                :value="value.deathCountry"
                :items="countriesList"
                :label="$t('person.deathCountry')"
                @input="onInput('deathCountry', $event)"
            ></v-select>
          </v-col>
          <date-picker
              :label="$t('person.deathDate')"
              :value="value.deathDate"
              @input="onInput('deathDate', $event)"></date-picker>
        </v-row>
      </template>
<!--      <v-row v-if="value.isAlive">-->
<!--        <v-col cols="12">-->
<!--          <v-text-field-->
<!--              :value="value.email"-->
<!--              :error-messages="$v.value.email.$invalid? [$t('person.emailInvalid')] : []"-->
<!--              :label="$t('person.email')"-->
<!--              @input="onInput('email', $event)"-->
<!--          ></v-text-field>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-row v-if="!isFirstPersonCreation && !disabledConnections">
        <v-col cols="12">
          <v-autocomplete
              :value="value[connectionType]"
              :items="persons"
              item-text="fullName"
              item-value="id"
              :label="$t('person.connectionPerson')"
              @input="onInput('connectionType', $event)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-select
              :value="connectionType"
              :items="ConnectionTypeOptions"
              :label="$t('person.connectionType')"
              @input="onInput('connectionType', $event)"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <slot name="actions"></slot>
  </v-card>
</template>

<script>
  import PersonModel from '@/models/person.model';
  import { countries, Sex, PeriodTypes, ConnectionTypeOptions } from '@/models/helpers';
  import { email } from 'vuelidate/lib/validators';
  import DatePicker from './components/date-picker';
  import PhotoAddModal from '@/modules/photos/components/photo-add-modal.vue';

  export default {
    name: 'PersonForm',
    components: {
      DatePicker,
      PhotoAddModal,
    },
    props: {
      value: {
        type: PersonModel,
        required: true,
      },
      relativePerson: {
        type: PersonModel,
      },
      connectionType: {
        type: String,
      },
      isFirstPersonCreation: {
        type: Boolean,
        default: false,
      },
      disabledConnections: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isWoman() {
        return this.value.gender === Sex.female;
      },
      countriesList() {
        return Object.entries(countries)
            .map(([ key, value ]) => ({
              text: value,
              value: key,
            }));
      },
    },
    validations: {
      value: {
        email: { email },
      },
    },
    data() {
      return {
        countries,
        ConnectionTypeOptions,
        PeriodTypes,
        dialog: false,
        newPerson: new PersonModel(),
        persons: [],
        rules: [
          value => !value || value.size < 3000000 || 'Avatar size should be less than 3 MB!',
        ],
      };
    },
    methods: {
      onInput(key, value) {
        this.$emit('input', new PersonModel({
          ...this.value,
          [key]: value,
        }));
      },
    },
  };
</script>

<style>
.person-form_button {
  margin-top: 10px;
}

.person-form_settings {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.person-form_toggle-button {
  margin-bottom: 20px;
}
</style>

