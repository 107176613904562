/**
 * @typedef {Object} Config
 * @property {string} APPLICATION_TITLE
 * @property {string} APPLICATION_MODE
 * @property {string}
 */

/**
* @returns {Config}
*/
export const buildConfig = () => {
    const config = {
        APPLICATION_TITLE: '7GENS',
        APPLICATION_MODE: 'production',
        APPLICATION_DOMAIN: 'app.7gens.ru',
    };
    if (config.DEBUG) {
        console.log('DEBUG config: ', config);
    }
    return config;
};

const config = buildConfig();

export default config;
