import TreeModel from '@/models/tree.model';
import apiService from '@/services/api.service';
import EntityService from '@/services/entity.service';
import FactService from '@/services/fact.service';
import toast from '@/services/helpers/toast';
import PersonService from '@/services/person.service';
import StoryService from '@/services/story.service';

export default class TreeService extends EntityService {
    _entity = 'trees';
    _entityModel = TreeModel;

    constructor(userId) {
        super(userId);
    }

    async getTrees() {
        const trees = await this.getAll();
        return trees;
    }

    async getTree(id) {
        const tree = await this.getDoc(id);
        return tree;
    }

    async getTreeWithPeople(id) {
        const tree = await this.getTree(id);
        const personService = new PersonService({
            treeId: id,
            userId: this._userId,
        });
        const people = await personService.getPeople();
        return new TreeModel({
            ...tree,
            people,
        });
    }

    async createTree(data, editMode) {
        try {
            const tree = await this.setDoc(data);
            toast('success', editMode ? 'alerts.tree.updated' : 'alerts.tree.created');
            return tree;
        } catch ( error ) {
            toast('error', `alerts.errors.${ error.code }`);

            console.log(error);
        }
    }

    async deleteTree(id) {
        try {
            // remove sub-collections first
            await apiService.removeTree(id);

            const tree = await this.removeDoc(id);
            toast('success', 'alerts.tree.removed');
            return tree;
        } catch ( error ) {
            toast('error', `alerts.errors.${ error.code }`);

            console.log(error);
        }
    }

    async updateFullfilledTree({
        treeId,
        people,
        stories,
        facts,
    }) {
        try {
            const peopleService = new PersonService({ treeId });
            const storiesService = new StoryService({ treeId });
            const factsService = new FactService({ treeId });

            await Promise.all(people.map(async person => await peopleService.createPerson(person.getPayload(), false)));
            await Promise.all(stories.map(async story => await storiesService.createStory({
                ...story,
                treeId,
            }, false)));
            await Promise.all(facts.map(async fact => await factsService.createFact(fact, false)));
        } catch ( e ) {
            console.log(e);
        }
    }
}
