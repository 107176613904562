<template>
  <div>
    <div>
      <h3>Военный поиск:</h3>
      <template v-for="(item, index) in warSearch">
        <v-list-item :key="item.title" @click="navigateTo(item)">
          <template v-slot:default>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>

              <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.description"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>

        <v-divider
            v-if="index < warSearch.length - 1"
            :key="'war-' + index"
        ></v-divider>
      </template>

    </div>
    <div class="mt-4">
      <h3>Поиск репрессированных:</h3>
      <template v-for="(item, index) in repressions">
        <v-list-item :key="item.title"  @click="navigateTo(item)">
          <template v-slot:default>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>

              <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.description"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text v-text="item.navigate"></v-list-item-action-text>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-divider
            v-if="index < repressions.length - 1"
            :key="'repressions-' + index"
        ></v-divider>
      </template>

    </div>
    <div class="mt-4">
      <h3>Оцифрованные документы:</h3>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="(item, index) in documents">
            <v-list-item :key="item.title"  @click="navigateTo(item)">
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>

                  <v-list-item-subtitle
                      class="text--primary"
                      v-text="item.description"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle v-text="item.howToUse"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text v-text="item.navigate"></v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
                v-if="index < documents.length - 1"
                :key="'docs-' + index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'open-databases',
    data() {
      return {
        selected: null,
        repressions: [
          {
            title: 'Открытый список',
            description: 'Самая полная база данных жертв политических репрессий в СССР (1917—1991 гг.)',
            link: 'https://ru.openlist.wiki/%D0%9E%D1%82%D0%BA%D1%80%D1%8B%D1%82%D1%8B%D0%B9_%D1%81%D0%BF%D0%B8%D1%81%D0%BE%D0%BA:%D0%97%D0%B0%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F_%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0',
          }, {
            title: 'Бессмертный барак',
            description: 'Единая база данных жертв политических репрессий. Полный список расстрелянных, репрессированных, высланных и депортированных в СССР.',
            link: 'https://bessmertnybarak.ru/',
          }, {
            title: 'Мемориал',
            description: 'Базы данных жертв политического террора',
            link: 'https://www.memo.ru/ru-ru/collections/databases/',
          }, {
            title: 'Национальный банк репрессированных (Украина)',
            description: 'Национальный банк данных жертв политических репрессий советской эпохи в Украине',
            link: 'http://www.reabit.org.ua/nbr/',
          },
        ],
        warSearch: [
          {
            title: 'Память народа',
            description: 'База данных о воинах Великой Отечественной войны',
            link: 'https://pamyat-naroda.ru/',
          }, {
            title: 'Дорога памяти',
            link: 'https://foto.pamyat-naroda.ru/',
            description: 'Постоянно обновляемый новыми сведениями и фотографиями участников Великой Отечественной войны ресурс',
          }, {
            title: 'Подвиг народа',
            link: 'https://podvignaroda.ru/',
            description: 'Информационный ресурс открытого доступа, наполняемый всеми имеющимися в военных архивах документами о ходе и итогах основных боевых операций, подвигах и наградах всех воинов Великой Отечественной',
          }, {
            title: 'Герои великой войны',
            link: 'https://gwar.mil.ru/heroes/',
            description: 'Информационный портал «Памяти героев Великой войны 1914-1918 гг.»',
          } ],
        documents: [
          {
            title: 'ГенеоДиск',
            description: 'База, собранная пользователем ВГД форума Admiral21',
            link: 'https://drive.google.com/drive/folders/1DAu9eDlTRe0nnGEUmgmtGgwL8Rnv-zBM?usp=sharing',
            howToUse: '',
          },
          {
            title: 'geno-dbase.ru',
            description: 'База, собранная пользователем ВГД форума Mihailo12',
            link: 'https://www.geno-dbase.ru/storage',
            howToUse: '',
            items: [ {
              title: 'Россия, Астрахань',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Astrahan/',
            }, {
              title: 'Россия, Крым',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Crimea/',
            }, {
              title: 'Россия, Димитровград',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Dmitrovograd/',
            }, {
              title: 'Россия, Казань',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Kazan/',
            }, {
              title: 'Россия, Карелия',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Karelia/',
            }, {
              title: 'Россия, Таврическая Губерния (Отсортировано и разбито по уездам и приходам. Запущен простой интерфейс просмотра и скачивания)',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Tavria/',
            }, {
              title: 'Россия, Нижний Новгород',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Nignii_Novgorod/',
            }, {
              title: 'Россия, Псков',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Pskov/',
            }, {
              title: 'Россия, Самара',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Samara/',
            }, {
              title: 'Россия, Саратов',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Saratov/',
            }, {
              title: 'Россия, СПБ',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/SPB/',
            }, {
              title: 'Россия, Сызрань',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Sizran/',
            }, {
              title: 'Россия, Тверь',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Tver/',
              description: {
                title: 'Указатель по Твери',
                link: 'https://forum.vgd.ru/post/3111/122741/p3938512.htm#pp3938512',
              },
            }, {
              title: 'Россия, Тольятти',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Toliatti/',
            }, {
              title: 'Россия, Тобольск',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Tobolsk/',
            }, {
              title: 'Россия, Томск (Отсортировано и распределено по фондам и делам. Запущен простой интерфейс просмотра и скачивания)',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Tomsk/',
            }, {
              title: 'Россия, Тула',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Tula/',
            }, {
              title: 'Россия, Удмуртия',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Udmurtia/',
            }, {
              title: 'Россия, Ульяновск',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Ulianovsk/',
            }, {
              title: 'Россия, Уфа',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire/Ufa/',
            }, {
              title: 'Украина, Днепропетровск',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Dnepropetrovsk/',
            }, {
              title: 'Украина, Донецк',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Donetsk/',
            }, {
              title: 'Украина, Херсон',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Herson/',
            }, {
              title: 'Украина, Ивано-Франковск',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Ivano-Francovsk/',
            }, {
              title: 'Украина, Киевская область',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Kiev-Oblast/',
            }, {
              title: 'Украина, Сумы',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Summi/',
            }, {
              title: 'Украина, Закарпатье',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Zakarpatie/',
            }, {
              title: 'Украина, Житомир',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Zhitomer/',
            }, {
              title: 'Украина, Одесса',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukrainene/Odessa/',
            }, {
              title: 'Украина, Николаев',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Nikolaev/',
            }, {
              title: 'Украина, Запорожье',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Zaporogie/',
            }, {
              title: 'Украина, Львов',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Ukraine/Lvov/',
            }, {
              title: 'Украина, Чернигов',
              link: 'ftp://91.225.197.22',
            }, {
              title: 'Грузия',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Georgia/',
            }, {
              title: 'Молдова',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Moldova/',
            }, {
              title: 'Польша',
              link: 'https://www.geno-dbase.ru/storage/Russian_Empire_2/Poland/',
            }, {
              title: 'Беларусь',
              link: 'https:,//www.geno-dbase.ru/storage/Russian_Empire_2/Belarus/',
            } ],
          },
          {
            title: 'Наши предки',
            description: 'Сайт волонтеров, которые занимаются систематизацией оцифрованных материалов.',
            link: ' https://nashipredki.com/familysearch',
          },
          {
            title: 'FamilySearch',
            description: 'Сайт, на котором можно найти оцифрованные материалы.',
            link: 'https://www.familysearch.org/search/catalog',
          },
        ],
      };
    },
    methods: {
      navigateTo(item) {
        window.open(item.link, '_blank');
      }
    }
  };
</script>
