import moment from 'moment';

export const countries = require('@/assets/countries.json');

export const PeriodTypes = {
    exactly: 'exactly',
    about: 'about',
    before: 'before',
    after: 'after',
    between: 'between',
    period: 'period',
};

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

const format = (d, format) => moment(d)
    .isValid() ? moment(d, format)
    .format('ll') : d;

export const prepareDate = (date) => {
    if ( date ) {
        switch ( date.type ) {
            case PeriodTypes.exactly: {
                return format(date.date);
            }
            case PeriodTypes.about: {
                return `Примерно ${ format(date.date) }`;
            }
            case PeriodTypes.before: {
                return `До ${ format(date.date.end) }`;
            }
            case PeriodTypes.after: {
                return `После ${ format(date.date.start) }`;
            }
            case PeriodTypes.between: {
                return `Между ${ format(date.datePeriod.start) } и ${ format(date.datePeriod.end) }`;
            }
            case PeriodTypes.period: {
                return `С ${ format(date.datePeriod.start) } по ${ format(date.datePeriod.end) }`;
            }
            default: {
                return date.date && format(date.date) || format(date);
            }
        }
    }
};

export const FactTypes = {
    adopted: 'adopted',
    adultChristening: 'adultChristening',
    barMitzvah: 'barMitzvah',
    basMitzvah: 'basMitzvah',
    birth: 'birth',
    burial: 'burial',
    childBirth: 'childBirth',
    childDeath: 'childDeath',
    christening: 'christening',
    cremation: 'cremation',
    custom: 'custom',
    death: 'death',
    divorce: 'divorce',
    education: 'education',
    employment: 'employment',
    graduated: 'graduated',
    immigration: 'immigration',
    marriage: 'marriage',
    militaryAward: 'militaryAward',
    militaryRank: 'militaryRank',
    militaryService: 'militaryService',
    placeOfResidence: 'placeOfResidence',
};

export const FactTypeOptions = [
    FactTypes.custom,
    FactTypes.adopted,
    FactTypes.adultChristening,
    FactTypes.barMitzvah,
    FactTypes.basMitzvah,
    FactTypes.burial,
    FactTypes.christening,
    FactTypes.cremation,
    FactTypes.divorce,
    FactTypes.education,
    FactTypes.employment,
    FactTypes.graduated,
    FactTypes.immigration,
    FactTypes.marriage,
    FactTypes.militaryAward,
    FactTypes.militaryRank,
    FactTypes.militaryService,
    FactTypes.placeOfResidence,
];

export const Sex = {
    female: 'female',
    male: 'male',
};

export const ConnectionTypes = {
    father: 'father',
    mother: 'mother',
    sibling: 'sibling',
    partner: 'partner',
};

export const ConnectionTypeOptions = [ {
    text: 'Отец',
    value: ConnectionTypes.father,
}, {
    text: 'Мать',
    value: ConnectionTypes.mother,
}, {
    text: 'Брат или сестра',
    value: ConnectionTypes.sibling,
}, {
    text: 'Супруг / супруга',
    value: ConnectionTypes.partner,
} ];
