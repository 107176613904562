export default class TreeModel {
    constructor({
        id = null,
        people = [],
        name = null,
        description = null,
        image = null,
        isPublic = false,
        authorizedToReadUsers = [],
        authorizedToWriteUsers = [],
    } = {}) {
        this.id = id;
        this.people = people;
        this.name = name;
        this.description = description;
        this.image = image;
        this.authorizedToReadUsers = authorizedToReadUsers;
        this.authorizedToWriteUsers = authorizedToWriteUsers;
        this.isPublic = isPublic;
    }

    getPayload() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            people: this.people,
            image: this.image,
            authorizedToReadUsers: this.authorizedToReadUsers,
            authorizedToWriteUsers: this.authorizedToWriteUsers,
            isPublic: this.isPublic,
        };
    }
}

