<template>
  <v-container class="photo-page">
    <div v-if="isLoading" class="loading-state-wrapper">
      <v-progress-circular :size="60"
                           color="amber"
                           indeterminate
      ></v-progress-circular>
    </div>
    <template v-else>
      <div class="photo-page_name mb-3">
        <template v-if="!editorMode">
          <div class="photo-page_name_line"><h2>{{ photo.name || $t('photos.noName') }}</h2>
            <span class="text-description ml-1"><v-icon @click="toggleEditMode">edit</v-icon></span></div>
          <h4 class="text-description">{{ photo.date }}</h4>
        </template>
        <template v-else>
          <v-text-field
              v-model="photo.name"
              counter
              maxlength="50"
              :label="$t('photos.name')"
          ></v-text-field>
          <v-text-field
              v-model="photo.description"
              counter
              maxlength="450"
              :label="$t('forms.description')"
          ></v-text-field>
          <date v-model="photo.date"
                :label="$t('forms.photoDate')"
          ></date>
        </template>
      </div>
      <v-row v-if="photo">
        <v-col :style="{maxHeight: '600px'}" class="align-content-end" cols="12" sm="8">
          <div id="image-wrapper" ref="image-wrapper" :style="{backgroundImage: `url(${photo.downloadUrl})`}"
               class="photo-page_image-wrapper"
               @mousedown="startDrawingBox" @mousemove="changeBox" @mouseup="stopDrawingBox">
            <Box v-if="drawingBox.active || drawingBox.awaitingLabel"
                 :awaiting-label="drawingBox.awaitingLabel"
                 :height="drawingBox.height"
                 :left="drawingBox.left"
                 :top="drawingBox.top"
                 :width="drawingBox.width"/>
            <Box v-for="(box, i) in photo.boxes"
                 :key="i"
                 :active="i === activeBoxIndex"
                 :height="box.height"
                 :highlighted="box.highlighted"
                 :left="box.left"
                 :person-id="box.personId"
                 :persons="persons"
                 :top="box.top"
                 :tree-id="box.treeId"
                 :width="box.width"
                 @remove="removeBox(i)"
                 @select="makeBoxActive(i)"/>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <p>{{ $t('photos.detectBox') }}</p>
          <v-autocomplete
              v-model="drawingBox.treeId"
              :auto-select-first="true"
              :disabled="!drawingBox.awaitingLabel"
              :items="trees"
              :label="$t('photos.treeName')"
              :loading="!trees.length"
              item-text="name"
              item-value="id"
              @input="onTreeSelected"
          ></v-autocomplete>
          <v-autocomplete v-model="drawingBox.personId"
                          :disabled="!drawingBox.treeId"
                          :items="persons[drawingBox.treeId]"
                          :label="$t('photos.labelPerson')"
                          :loading="personsAreLoading"
                          item-text="fullName"
                          item-value="id"
                          @input="addBox"
          ></v-autocomplete>
          <div>
            <h3 class="mb-2">{{ $t('photos.onPhoto') }}:</h3>
            <template v-if="photo.boxes.length && persons">
              <template v-for="(box, i) in photo.boxes">
                <a v-if="persons[box.treeId]"
                   :key="i"
                   @mouseleave="highlightBox(box, i, false)"
                   @mouseover="highlightBox(box, i, true)"
                >
                  <p>
                    <box-label :person-id="box.personId" :persons="persons" :tree-id="box.treeId"></box-label>
                  </p>
                </a>
              </template>
            </template>
            <template v-else>
              {{ $t('photos.noLabels') }}
            </template>

            <div class="mt-8">
              <h3 class="mb-2">{{ $t('photos.connectToStory') }}:</h3>
              <v-autocomplete
                  v-model="photo.story.treeId"
                  :auto-select-first="true"
                  :items="trees"
                  :label="$t('photos.treeName')"
                  :loading="!trees.length"
                  item-text="name"
                  item-value="id"
                  @input="onStoryTreeSelected"
              ></v-autocomplete>
              <v-autocomplete
                  v-model="photo.story.storyId"
                  :auto-select-first="true"
                  :disabled="!photo.story.treeId"
                  :items="stories"
                  :label="$t('photos.storyName')"
                  :loading="storiesAreLoading"
                  item-text="title"
                  item-value="id"
              ></v-autocomplete>
            </div>

            <v-divider></v-divider>
            <v-btn block class="mt-3 mb-3" color="primary" @click="saveChanges">{{ $t('forms.saveChanges') }}</v-btn>
            <div class="text-right">
              <v-btn color="red" text x-small @click="removePhoto">{{ $t('forms.remove') }}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
  import StoryModel from '@/models/story.model';
  import toast from '@/services/helpers/toast';
  import PersonService from '@/services/person.service';
  import PhotoService from '@/services/photo.service';
  import StoryService from '@/services/story.service';
  import TreeService from '@/services/tree.service';
  import Box from './components/detection-box.vue';
  import BoxLabel from './components/box-label.vue';
  import { pick } from 'lodash';
  import Date from '../../components/date';

  const getCursorLeft = (e, width) => {
    return e.pageX - width;
  };

  const getCursorTop = (e, height) => {
    return e.pageY - height;
  };

  export default {
    name: 'PhotoPage',
    components: {
      Date,
      Box,
      BoxLabel,
    },
    data() {
      return {
        service: new PhotoService(),
        treeService: new TreeService(),
        photo: null,
        updatedPhoto: null,
        trees: [],
        drawingBox: {
          active: false,
          awaitingLabel: false,
          top: 0,
          left: 0,
          height: 0,
          width: 0,
        },
        activeBoxIndex: null,
        persons: {},
        editorMode: false,
        isLoading: false,
        personsAreLoading: false,
        storiesAreLoading: false,
        stories: [],
        story: new StoryModel(),
      };
    },
    async created() {
      await this.init();
    },
    methods: {
      async init() {
        this.isLoading = true;
        const id = this.$route.params.photoId;
        this.service.getPhoto(id)
            .then(async (data) => {
              if ( data ) {
                this.photo = data;
                this.reloadPhoto();
                this.isLoading = false;

                if ( this.photo.story.storyId ) {
                  this.story = await new StoryService({ treeId: this.photo.story.treeId }).getStory(this.photo.story.storyId);
                }

                for ( const box of this.photo.boxes ) {
                  if ( !this.persons[box.treeId] ) {
                    try {
                      const personService = new PersonService({
                        treeId: box.treeId,
                      });
                      this.persons[box.treeId] = await personService.getPeople();
                    } catch ( e ) {
                                  toast('error', `alerts.errors.${e.code}`);

                      console.log(e);
                    }
                  }
                }

                if (this.story && this.story.treeId) {
                  await this.onStoryTreeSelected(this.story.treeId);
                }
              } else {
                await this.$router.push({ name: 'photo' });
              }
            })
            .catch(console.log);
        await this.treeService.getTrees()
            .then((data) => {
              this.trees = data;
            });
      },
      async onTreeSelected(treeId) {
        this.personsAreLoading = true;
        try {
          const personService = new PersonService({ treeId });
          this.persons[treeId] = await personService.getPeople();
        } catch ( e ) {
                      toast('error', `alerts.errors.${e.code}`);

          console.log(e);

        } finally {
          this.personsAreLoading = false;
        }
      },
      async onStoryTreeSelected(treeId) {
        try {
          this.storiesAreLoading = true;
          const storyService = new StoryService({ treeId });
          this.stories = await storyService.getStories();
        } catch ( e ) {
                      toast('error', `alerts.errors.${e.code}`);

          console.log(e);
        } finally {
          this.storiesAreLoading = false;
        }
      },
      reloadPhoto() {
        const img = new Image();

        const self = this;
        img.onload = function () {
          self.setWrapperSize(this.height, this.width);
        };
        img.src = this.photo.downloadUrl;
      },
      setWrapperSize(height) {
        if ( this.$refs['image-wrapper'] ) {
          this.$refs['image-wrapper'].style.height = `${ height }px`;
        }
      },
      startDrawingBox(e) {
        this.drawingBox = {
          width: 0,
          height: 0,
          top: getCursorTop(e, this.$refs['image-wrapper'].offsetTop),
          left: getCursorLeft(e, this.$refs['image-wrapper'].offsetLeft),
          active: true,
        };
      },
      changeBox(e) {
        if ( this.drawingBox.active ) {
          const maxWidth = this.$refs['image-wrapper'].offsetWidth - this.drawingBox.left;
          const boxWidth = getCursorLeft(e, this.$refs['image-wrapper'].offsetLeft) - this.drawingBox.left;

          const maxHeight = this.$refs['image-wrapper'].offsetHeight - this.drawingBox.top;
          const boxHeight = getCursorTop(e, this.$refs['image-wrapper'].offsetTop) - this.drawingBox.top;
          this.drawingBox = {
            ...this.drawingBox,
            width: boxWidth > maxWidth ? maxWidth : boxWidth,
            height: boxHeight > maxHeight ? maxHeight : boxHeight,
          };
        }
      },
      stopDrawingBox() {
        if ( this.drawingBox.active && (this.drawingBox.width < 20 || this.drawingBox.height < 20) ) {
          this.resetDrawingBox();
        } else {
          this.drawingBox.active = false;
          this.drawingBox.awaitingLabel = true;
        }
      },
      makeBoxActive(i) {
        this.activeBoxIndex = i;
      },
      highlightBox(box, i, value) {
        const boxes = [ ...this.photo.boxes ];
        boxes[i] = {
          ...box,
          highlighted: value,
        };
        this.photo.boxes = boxes;
      },
      addBox() {
        this.photo.boxes.push({ ...pick(this.drawingBox, [ 'personId', 'width', 'height', 'top', 'left', 'treeId' ]) });
        this.resetDrawingBox();
      },
      removeBox(i) {
        this.photo.boxes = this.photo.boxes.filter((elem, index) => {
          return index !== i;
        });
        this.activeBoxIndex = null;
      },
      resetDrawingBox() {
        this.drawingBox = {
          active: false,
          awaitingLabel: false,
          top: 0,
          left: 0,
          height: 0,
          width: 0,
          personId: null,
        };
      },
      toggleEditMode() {
        this.editorMode = !this.editorMode;
      },
      async saveChanges() {
        this.isLoading = true;
        await this.service.setPhoto(this.photo.getPayload())
            .then(async () => {
              await this.init();
              this.isLoading = false;
              this.editorMode = false;
            });
      },
      async removePhoto() {
        this.isLoading = true;
        await this.service.removePhoto(this.photo.id)
            .then(() => {
              this.$router.push({ name: 'photo' });
              this.isLoading = false;
              this.editorMode = false;
            });
      },
    },
  };
</script>

<style>
#image-wrapper {
  background-repeat: no-repeat;
  position: relative;
  max-height: 100%;
  background-size: contain;
}

.photo-page_name_line {
  display: inline-flex;
  align-items: center;
}
</style>
