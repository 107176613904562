const { parse } = require('parse-gedcom');
const MainTags = {
    Person: 'INDI',
};

const GedcomTags = {
    [MainTags.Person]: {
        Adoption: 'ADOP', // Creation of a legally approved child-parent relationship that does not exist biologically
        AdultChristening: 'CHRA', // Baptism or naming events for an adult.
        Avatar: 'FILE', // in OBJE
        Baptism: 'BAPM', // Baptism, performed in infancy or later
        BarMitzvah: 'BARM', // The ceremonial event held when a Jewish boy reaches age 13.
        BasMitzvah: 'BASM', // he ceremonial event held when a Jewish girl reaches age 13, also known as “Bat Mitzvah.”
        Birth: 'BIRT',
        Blessing: 'BLESS', // Bestowing divine care or intercession. Sometimes given in connection with a naming ceremony.
        Burial: 'BURI',
        Christening: 'CHR', // Baptism or naming events for a child.
        Confirmation: 'CONF', // Conferring full church membership.
        Cremation: 'CREM', // Disposal of the remains of a person’s body by fire
        Date: 'DATE',
        Death: 'DEAT', // Mortal life terminates
        DivorceDate: 'DIV',
        Email: 'EMAIL',
        Event: 'EVEN',
        Famc: 'FAMC', // family as child
        Fams: 'FAMS', // family as spouse
        Gender: 'SEX',
        Given: 'GIVN', // in NAME
        Graduated: 'GRAD',
        MarName: '_MARNM',
        Marriage: 'MARR',
        Name: 'NAME',
        Note: 'NOTE',
        Occupation: 'OCCU',
        Other: 'OBJE',
        Person: 'INDI',
        Place: 'PLAC',
        Residence: 'RESI',
        Rin: 'RIN',
        Surname: 'SURN', // in NAME
        Type: 'TYPE',
        Id: '_UID', // MarriagePlace: 'MARR',
    },
};

const GedcomProps = {
    Tree: 'tree',
    Id: 'pointer',
};

const SexToGender = {
    M: 'male',
    F: 'female',
};

function loadFileAsText(file) {
    return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = (evt) => {
            resolve((evt.target).result);
        };
        reader.readAsText(file);
    });
}

function parsePerson(internalId, data) {
    const stories = [];
    const id = data.find(el => el.tag === GedcomTags[MainTags.Person].Id).data;
    const reduced = data.reduce((acc, el) => {
        if ( [ GedcomTags[MainTags.Person].Note ].includes(el.tag) ) {
            const tree = el.tree.map(storyEl => storyEl.data)
                .join('');
            stories.push({
                id: self.crypto.randomUUID(),
                text: `${ el.data }${ tree }`,
                memberIds: [ id ],
            });
        }

        if ( [ GedcomTags[MainTags.Person].Date, GedcomTags[MainTags.Person].Place ].includes(el.tag) ) {
            return acc;
        }

        // if (el.data) {
        // if array
        if ( el.tree.length ) {
            el.tree
                .filter(item => [ GedcomTags[MainTags.Person].Place, GedcomTags[MainTags.Person].Date ].includes(item.tag))
                .map(item => {
                    if ( acc[item.tag] ) {
                        acc[item.tag] = [ ...(acc[item.tag] instanceof Array ? acc[item.tag] : [ acc[item.tag] ]), item.data ];
                    } else {
                        acc[item.tag] = item.data;
                    }
                });
        }

        // if place and date
        if ( [ GedcomTags[MainTags.Person].Event ].includes(el.tag) ) {
            acc[el.tag] = acc[el.tag] || [];
            const element = {};
            el.tree.forEach(el2 => {
                element[el2.tag] = el2.data;
            });
            acc[el.tag].push(element);
            return acc;
        }
        if ( [ GedcomTags[MainTags.Person].Name, GedcomTags[MainTags.Person].Birth, GedcomTags[MainTags.Person].Marriage, GedcomTags[MainTags.Person].Death ].includes(el.tag) ) {
            acc[el.tag] = {};
            el.tree.forEach(el2 => {
                acc[el.tag][el2.tag] = el2.data;
            });
            return acc;
        }

        // if already contains
        if ( acc[el.tag] ) {
            acc[el.tag] = [ ...(acc[el.tag] instanceof Array ? acc[el.tag] : [ acc[el.tag] ]), el.data ];
        } else {
            acc[el.tag] = el.data;
        }
        return acc;
    }, {});

    const gender = SexToGender[reduced[GedcomTags[MainTags.Person].Gender]];
    const isFemale = gender === 'female';
    const name = reduced[GedcomTags[MainTags.Person].Name];
    const lastName = isFemale ? (name[GedcomTags[MainTags.Person].MarName]) : name[GedcomTags[MainTags.Person].Surname];
    const maidenName = isFemale && name[GedcomTags[MainTags.Person].Surname] || null;
    const firstName = name[GedcomTags[MainTags.Person].Given];
    const person = {
        id,
        uid: internalId,
        lastName,
        maidenName,
        firstName,
        gender,
        fams: reduced[GedcomTags[MainTags.Person].Fams],
        famc: reduced[GedcomTags[MainTags.Person].Famc],
        residence: reduced[GedcomTags[MainTags.Person].Residence],
        rin: reduced[GedcomTags[MainTags.Person].Rin],
        isAlive: !(!!reduced[GedcomTags[MainTags.Person].Death] && !!reduced[GedcomTags[MainTags.Person].Death][GedcomTags[MainTags.Person].Date]),
        birthDate: {
            type: 'exactly',
            date: reduced[GedcomTags[MainTags.Person].Birth] && reduced[GedcomTags[MainTags.Person].Birth][GedcomTags[MainTags.Person].Date],
        },
        birthPlace: reduced[GedcomTags[MainTags.Person].Birth] && reduced[GedcomTags[MainTags.Person].Birth][GedcomTags[MainTags.Person].Place],
        deathDate: {
            type: 'exactly',
            date: reduced[GedcomTags[MainTags.Person].Death] && reduced[GedcomTags[MainTags.Person].Death][GedcomTags[MainTags.Person].Date],
        },
        deathPlace: reduced[GedcomTags[MainTags.Person].Death] && reduced[GedcomTags[MainTags.Person].Death][GedcomTags[MainTags.Person].Place],
        marriageDate: {
            type: 'exactly',
            date: reduced[GedcomTags[MainTags.Person].Marriage] && reduced[GedcomTags[MainTags.Person].Marriage][GedcomTags[MainTags.Person].Date],
        },
        marriagePlace: reduced[GedcomTags[MainTags.Person].Marriage] && reduced[GedcomTags[MainTags.Person].Marriage][GedcomTags[MainTags.Person].Place],
        email: reduced[GedcomTags[MainTags.Person].Email],
        avatar: reduced[GedcomTags[MainTags.Person].Avatar],
        facts: (reduced[GedcomTags[MainTags.Person].Event] || []).map(el => ({
            id: self.crypto.randomUUID(),
            memberIds: [ id ],
            period: {
                type: 'exactly',
                date: el[GedcomTags[MainTags.Person].Date] || '',
            },
            place: el[GedcomTags[MainTags.Person].Place] || '',
            title: el[GedcomTags[MainTags.Person].Type] || '',
        })),
    };

    return {
        person,
        stories,
        facts: person.facts,
    };
}

const parseGedcom = async (file) => {
    const gedcom = await loadFileAsText(file);
    const data = parse(gedcom);

    const personObjects = {};

    const people = data
        .filter(el => el.tag === MainTags.Person)
        .map(el => {
            const tree = el[GedcomProps.Tree];
            const id = el[GedcomProps.Id];
            if ( tree ) {
                const {
                    person,
                    stories,
                    facts,
                } = parsePerson(id, tree);
                const personModel = {
                    ...person,
                };

                personObjects[personModel.id] = personModel;
                return {
                    personModel,
                    stories,
                    facts,
                };
            }
        });

    const result = people.map(person => {
        const checkIfParent = (fams, famc) => {
            return fams ? (fams === famc || fams.includes(famc)) : null;
        };

        const checkIfPartner = (fams1, fams2) => {
            return fams1 && fams2 && (fams1 instanceof Array
                ? fams1.some(fam => fams2 instanceof Array ? fams2.includes(fam) : fams2 === fam)
                : (fams2 instanceof Array ? fams2.some(fam => fams1 instanceof Array ? fams1.includes(fam) : fams1 === fam) : fams1 === fams2));
        };

        const checkIfExists = id => !!personObjects[id];

        const partnerIds = person.personModel.fams
            ? people.filter(({ personModel }) => personModel.id !== person.personModel.id
                && checkIfExists(personModel.id)
                && checkIfPartner(person.personModel.fams, personModel.fams))
                .map(({ personModel }) => personModel.id) : [];
        const mother = person.personModel.famc ? people.find(({ personModel }) => personModel.id !== person.personModel.id && personModel.gender === 'female' && checkIfParent(personModel.fams, person.personModel.famc)) : null;
        const father = person.personModel.famc ? people.find(({ personModel }) => personModel.id !== person.personModel.id && personModel.gender === 'male' && checkIfParent(personModel.fams, person.personModel.famc)) : null;
        return {
            ...person,
            personModel: {
                ...person.personModel,
                partnerIds,
                childrenIds: people
                    .filter(({ personModel }) => personModel.id !== person.personModel.id && checkIfExists(personModel.id) && checkIfParent(person.personModel.fams, personModel.famc))
                    .map(({ personModel }) => personModel.id),
                motherId: mother && mother.personModel.id,
                fatherId: father && father.personModel.id,
                factIds: person.facts.map(f => f.id),
                storiesIds: person.stories.map(s => s.id),
            },
        };
    });
    return result;
};

module.exports = parseGedcom;
