<template>
  <span>
    {{ computedLabel }}
  </span>
</template>

<script>
  export default {
    name: 'BoxLabel',
    props: {
      persons: {
        type: Object,
        required: true,
      },
      treeId: {
        type: String,
        required: true,
      },
      personId: {
        type: String,
        required: true,
      },
    },
    computed: {
      computedLabel() {
        const person = this.persons[this.treeId]?.find(person => person.id === this.personId);
        return person?.fullName;
      },
    },
  };
</script>
