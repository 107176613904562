<template>
  <v-dialog
      :value="dialog"
      persistent
      max-width="800"
  >
    <person-form :value="person"
                 :disabled-connections="true"
                 @input="onInput">
      <template v-slot:actions>
    <div class="pa-3">
      <v-spacer></v-spacer>

      <v-btn
          color="darken-1"
          text
          class="mr-3"
          @click="$emit('close')"
      >
        {{ $t('forms.cancel') }}
      </v-btn>

      <v-btn
          color="primary"
          @click="$emit('save')"
      >
        {{ $t('forms.save') }}
      </v-btn>
    </div>
      </template>
    </person-form>
  </v-dialog>
</template>

<script>
  import PersonModel from '@/models/person.model';
  import PersonForm from '@/modules/person/person-form';

  export default {
    name: 'create-person-dialog',
    components: { PersonForm },
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
      person: {
        type: PersonModel,
        default: null,
      },
    },
    methods: {
      onInput(value) {
        this.$emit('update-creating-person', value)
      },
    },
  };
</script>
